import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FamiliesModifyComponent } from '../../families/families-modify/families-modify.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFamilyModifyGuard {
  constructor(private readonly guardService: GuardService) {}

  canDeactivate(component: FamiliesModifyComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
