import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetCreateFamilyValueAction, SetFamilyUpdateSuccessValuesAction } from '../store/families/families-action';
import { ReefRESTService } from '@seco/core';
import {
  buildChainsFamilyFromCreateOrUpdateResponse,
  buildHotelChainsFamilyListFromSearchResponse,
  buildHotelChainsFamilyFromDeleteResponse
} from './handler/family-hotel-chains-response-handler';
import {
  HotelChainsCreateRequest,
  HotelChainsSearchRequest,
  HotelChainsDeleteRequest,
  HotelChainsUpdateRequest
} from '../families/model/family-request';
import { HotelChainsFamilyCreateUpdateResponse } from '../families/model/family-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FamilyUi, initialFamilyUi } from '../families/model/family-ui';
import { Router } from '@angular/router';
import { TYPE_SUCCESS } from './model/pos';
import { RouteURL } from '../model/route-url';

@Injectable({
  providedIn: 'root'
})
export class FamilyHotelChainsService {
  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly reefRestService: ReefRESTService
  ) {}

  createHotelChainsFamily(request: HotelChainsCreateRequest): Observable<FamilyUi> {
    return this.reefRestService
      .post('/family/hotel/chains', request)
      .pipe(map((response) => this.buildResultForHotelChainsFamilyCreation(response)));
  }

  searchHotelChainsFamilies(request: HotelChainsSearchRequest): Observable<FamilyUi[]> {
    return this.reefRestService
      .post('/family/hotel/chains/list', request)
      .pipe(map((response) => buildHotelChainsFamilyListFromSearchResponse(response)));
  }

  deleteHotelChainsFamily(request: HotelChainsDeleteRequest): Observable<FamilyUi> {
    const familyId = request.hotelChainsFamily.id;
    const requestForDelete = this.buildBackendRequestForDelete(request);
    return this.reefRestService
      .delete(`/family/hotel/chains/${familyId}`, requestForDelete)
      .pipe(map((response) => buildHotelChainsFamilyFromDeleteResponse(response, request)));
  }

  updateHotelChainsFamily(request: HotelChainsUpdateRequest): Observable<FamilyUi> {
    const familyId = request.hotelChainsFamily.id;
    return this.reefRestService
      .put(`/family/hotel/chains/${familyId}`, request)
      .pipe(map((response) => this.buildResultForHotelChainsFamilyUpdate(response)));
  }

  buildBackendRequestForDelete(request: HotelChainsDeleteRequest): HotelChainsDeleteRequest {
    return {
      version: request.version,
      hotelChainsFamily: {
        version: request.hotelChainsFamily.version
      }
    };
  }

  buildResultForHotelChainsFamilyCreation(response: HotelChainsFamilyCreateUpdateResponse): FamilyUi {
    const family = buildChainsFamilyFromCreateOrUpdateResponse(response);
    if (family.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetCreateFamilyValueAction({ familyUi: initialFamilyUi }));
    }
    return family;
  }

  private buildResultForHotelChainsFamilyUpdate(result: HotelChainsFamilyCreateUpdateResponse): FamilyUi {
    const family = buildChainsFamilyFromCreateOrUpdateResponse(result);
    if (family.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForHotelChainsFamilyModify(family);
    }
    return family;
  }

  private triggerSuccessActionForHotelChainsFamilyModify(familyHotelChainUi: FamilyUi) {
    this.store.dispatch(new SetFamilyUpdateSuccessValuesAction({ familyUi: familyHotelChainUi }));
    this.router.navigate([RouteURL.familyHotelDisplay]);
  }
}
