import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HotelsSearchComponent } from '../../hotels/hotels-search/hotels-search.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateHotelRuleSearchGuard {
  canDeactivate(component: HotelsSearchComponent): Observable<boolean> | boolean {
    component.storeUnsavedHotelSearch();
    return true;
  }
}
