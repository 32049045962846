import { Component, OnInit, ViewChild } from '@angular/core';
import {
  HotelChainsSearchRequest,
  HotelChainsDeleteRequest,
  HotelPropertyCodesSearchRequest,
  HotelPropertyCodesDeleteRequest,
  convertCriteriaUIToHotelChainsFamilySearchCriteria,
  convertCriteriaUIToHotelPropertyCodesFamilySearchCriteria,
  FamilyUi,
  FamilySearchCriteriaUI,
  HOTEL_PROP_CODES_TYPE,
  HOTEL_CHAINS_TYPE
} from '../model';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/core/navigation.service';
import {
  SearchFamilyHotelChainsAction,
  SearchFamilyHotelPropertyCodesAction,
  ActivateFamilyDisplayAction,
  DeleteFamilyDisplayNotificationAction,
  ClearFamiliesAction,
  DeleteFamilySearchNotificationAction,
  DeleteFamilyHotelChainsAction,
  DeleteFamilyHotelPropertyCodesAction,
  StartFamilyModificationAction,
  SelectFamiliesAction,
  ClearFamiliesSelectionAction,
  ClearLookupHotelPropertyCodesFamiliesAction,
  ClearLookupHotelChainsFamiliesAction,
  DeleteFamilyLookupNotificationAction
} from '../../store/families/families-action';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import {
  selectFamilySearchSending,
  selectFamilies,
  selectFamilySearchNotification,
  selectDeleteSending,
  selectSelectedFamilyNames,
  selectLookupHotelChainsFamilies,
  selectLookupHotelPropertyCodesFamilies,
  selectFamilyLookupNotification
} from '../../store/families/families-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { FamiliesSearchCriteriaComponent } from './families-search-criteria/families-search-criteria.component';
import { UserDetailsService } from '../../service/user-details.service';
import { UppComponent, UppComponentNames } from '../../service/model';
import {
  DisplayableField,
  EntryType,
  SearchResult
} from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import { LookupOptions } from '../../core/util/lookup-options';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import { HotelServiceElements } from '../../hotels/model/hotel-service-elements';
import { PERMISSION_MANAGE_FAMILY } from '../../service/user-permissions.service';
import { RouteURL } from '../../model/route-url';

@Component({
  selector: 'ama-ng-upp-families-search',
  templateUrl: './families-search.component.html'
})
export class FamiliesSearchComponent implements OnInit {
  @ViewChild(FamiliesSearchCriteriaComponent)
  searchCriteriaComponent!: FamiliesSearchCriteriaComponent;

  familySearchSending$: Observable<boolean | undefined>;
  familyDeleteSending$: Observable<boolean | undefined>;
  selectedFamilyNames$: Observable<Set<string>>;
  families$: Observable<FamilyUi[]> | undefined;
  notificationSearch$: Observable<UppNotification | undefined>;
  notificationLookup$: Observable<UppNotification | undefined>;
  mainMessages: NotificationMessages;
  familyHotelType!: string;

  selectedFamilyNames!: Set<string>;
  uppComponent = UppComponent.FAMILIES_SEARCH;

  lookup = false;
  lookupValues: string[] | undefined;
  lookupOptions: LookupOptions | undefined;

  managePermission = PERMISSION_MANAGE_FAMILY;

  displayableFields: DisplayableField[] = [
    {
      value: 'description',
      label: 'Description'
    }
  ];

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.familySearchSending$ = this.store.pipe(select(selectFamilySearchSending));
    this.familyDeleteSending$ = this.store.pipe(select(selectDeleteSending));
    this.notificationSearch$ = this.store.pipe(select(selectFamilySearchNotification));
    this.notificationLookup$ = this.store.pipe(select(selectFamilyLookupNotification));
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
    this.selectedFamilyNames$ = this.store.pipe(select(selectSelectedFamilyNames));
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.families.search.navigationTitle:Hotel Family Search`);
    this.navigationService.activateFamilies();
    this.navigationService.enableNavigation();

    this.selectedFamilyNames$.subscribe(
      (selectedFamilyNamesSearchResults) => (this.selectedFamilyNames = selectedFamilyNamesSearchResults)
    );

    this.store.pipe(select(selectNavigation)).subscribe((lookupParam) => {
      if (lookupParam) {
        this.lookupOptions = lookupParam;
        this.lookupValues = lookupParam.data;
        if (lookupParam.fieldName) {
          this.familyHotelType =
            lookupParam.fieldName === HotelServiceElements.HOTEL_PROPERTY_CODES_FAMILIES
              ? HOTEL_PROP_CODES_TYPE
              : HOTEL_CHAINS_TYPE;
        }
      }
    });

    this.lookup = !!this.lookupOptions?.lookup;

    if (this.lookup) {
      this.families$ = this.store.pipe(
        select(
          this.isHotelPropertiesCodesFamily() ? selectLookupHotelPropertyCodesFamilies : selectLookupHotelChainsFamilies
        )
      );
      this.store.dispatch(new ClearFamiliesSelectionAction({}));
      this.store.dispatch(new DeleteFamilyLookupNotificationAction({}));
      this.navigationService.disableNavigation();
    } else {
      this.families$ = this.store.pipe(select(selectFamilies));
    }
  }

  searchFamilies(searchCriteria: FamilySearchCriteriaUI) {
    if (searchCriteria.hotelFamilyType && searchCriteria.hotelFamilyType === HOTEL_PROP_CODES_TYPE) {
      this.createSearchRequestForCodes(searchCriteria);
    } else {
      this.createSearchRequestForChains(searchCriteria);
    }
    this.familyHotelType = searchCriteria.hotelFamilyType;
  }

  createSearchRequestForChains(searchCriteria: FamilySearchCriteriaUI) {
    const request: HotelChainsSearchRequest = {
      version: '1.0',
      hotelChainsFamily: convertCriteriaUIToHotelChainsFamilySearchCriteria(searchCriteria)
    };
    request.hotelChainsFamily = this.userDetailsService.assignEntity(request.hotelChainsFamily);
    this.store.dispatch(new SearchFamilyHotelChainsAction({ request, lookup: this.lookup }));
  }

  createSearchRequestForCodes(searchCriteria: FamilySearchCriteriaUI) {
    const request: HotelPropertyCodesSearchRequest = {
      version: '1.0',
      hotelPropertyCodesFamily: convertCriteriaUIToHotelPropertyCodesFamilySearchCriteria(searchCriteria)
    };
    request.hotelPropertyCodesFamily = this.userDetailsService.assignEntity(request.hotelPropertyCodesFamily);
    this.store.dispatch(new SearchFamilyHotelPropertyCodesAction({ request, lookup: this.lookup }));
  }

  closeNotification() {
    if (this.lookup) {
      this.store.dispatch(new DeleteFamilyLookupNotificationAction({}));
    } else {
      this.store.dispatch(new DeleteFamilySearchNotificationAction({}));
    }
  }

  clearFamilies() {
    if (this.lookup) {
      this.store.dispatch(
        this.isHotelPropertiesCodesFamily()
          ? new ClearLookupHotelPropertyCodesFamiliesAction({})
          : new ClearLookupHotelChainsFamiliesAction({})
      );
      this.store.dispatch(new ClearFamiliesSelectionAction({}));
    } else {
      this.store.dispatch(new ClearFamiliesAction({}));
    }
  }

  showFamily(familyId: string) {
    this.store.dispatch(new ActivateFamilyDisplayAction({ id: familyId }));
    this.store.dispatch(new DeleteFamilyDisplayNotificationAction({}));
    this.router.navigate([RouteURL.familyHotelDisplay]);
  }

  modifyFamily(familyId: string) {
    this.store.dispatch(new StartFamilyModificationAction({ id: familyId }));
    this.router.navigate([RouteURL.familyHotelModify]);
  }

  deleteFamilyChains(request?: HotelChainsDeleteRequest) {
    this.store.dispatch(new DeleteFamilyHotelChainsAction({ request }));
  }

  deleteFamilyCode(request?: HotelPropertyCodesDeleteRequest) {
    this.store.dispatch(new DeleteFamilyHotelPropertyCodesAction({ request }));
  }

  storeUnsavedFamilySearch() {
    if (!this.lookup) {
      this.searchCriteriaComponent.storeUnsavedFamilySearch();
    }
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.POS;
  }

  getHotelChainsFamilyEntryType(): EntryType {
    return EntryType.HOTEL_CHAINS_FAMILY;
  }

  getHotelPropertyCodesFamilyEntryType(): EntryType {
    return EntryType.HOTEL_PROPERTY_CODES_FAMILY;
  }

  recordToSearchResult(family: FamilyUi): SearchResult {
    return {
      id: family.id,
      name: family.name,
      description: family.description,
      organization: family.organization,
      version: family.version
    };
  }

  isHotelPropertiesCodesFamily(): boolean {
    return this.familyHotelType === HOTEL_PROP_CODES_TYPE;
  }

  propertyCodesFamilyEntryToUpdateCreator(
    id: string,
    name: string,
    organization: string,
    version: string,
    ruleVersion: number
  ): HotelPropertyCodesDeleteRequest {
    return {
      version,
      hotelPropertyCodesFamily: {
        id,
        name,
        version: ruleVersion
      }
    };
  }

  propertyCodesFamilyEntryToUpdateNameResolver(entryToUpdate: HotelPropertyCodesDeleteRequest) {
    return entryToUpdate.hotelPropertyCodesFamily.name;
  }

  chainsFamilyEntryToUpdateCreator(
    id: string,
    name: string,
    organization: string,
    version: string,
    ruleVersion: number
  ): HotelChainsDeleteRequest {
    return {
      version,
      hotelChainsFamily: {
        id,
        name,
        version: ruleVersion
      }
    };
  }

  chainsFamilyEntryToUpdateNameResolver(entryToUpdate: HotelChainsDeleteRequest) {
    return entryToUpdate.hotelChainsFamily.name;
  }

  getNotification(): Observable<UppNotification | undefined> {
    if (this.lookup) {
      return this.notificationLookup$;
    }
    return this.notificationSearch$;
  }
}
