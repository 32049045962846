import { Component, OnInit, ViewChild } from '@angular/core';
import {
  JourneyUi,
  convertToJourneySearchCriteria,
  FlightRuleDeleteRequest,
  INCLUDE_ACTION,
  JourneySearchCriteriaUi,
  FlightRuleSearchRequest
} from '../model';

import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/core/navigation.service';
import {
  SearchFlightRuleAction,
  ActivateFlightRuleDisplayAction,
  DeleteFlightRuleDisplayNotificationAction,
  ClearFlightRulesAction,
  DeleteFlightRuleSearchNotificationAction,
  DeleteFlightRuleAction,
  StartFlightModificationAction,
  CopyFlightRuleAction,
  DeleteFlightRuleCreateNotificationAction
} from '../../store/flight/flights-action';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import {
  selectFlightRuleSearchSending,
  selectFlightRules,
  selectFlightRuleSearchNotification,
  selectFlightRuleDeleteSending
} from '../../store/flight/flights-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';
import { UppComponentNames } from '../../service/model';
import {
  DisplayableField,
  EntryType
} from '../../base/search/basic-search-result-table/basic-search-result-table.component';
import {
  PERMISSION_MANAGE_EDIFACT_RULE,
  PERMISSION_MANAGE_FLIGHT,
  PERMISSION_MANAGE_NDC_RULE
} from '../../service/user-permissions.service';
import { FlightSearchCriteriaComponent } from './flight-search-criteria/flight-search-criteria.component';
import { ContentType } from '../model/journey';
import { getPropertyByKey } from '../../util/utils';
import { MarketPair } from '../../model/market-pair';

@Component({
  selector: 'ama-ng-upp-flights-search',
  templateUrl: './flights-search.component.html'
})
export class FlightsSearchComponent implements OnInit {
  @ViewChild(FlightSearchCriteriaComponent)
  searchCriteriaComponent!: FlightSearchCriteriaComponent;

  flightRuleSearchSending$: Observable<boolean>;
  flightRuleDeleteSending$: Observable<boolean | undefined>;
  flightRules$: Observable<JourneyUi[]>;
  notification$: Observable<UppNotification | undefined>;

  mainMessages: NotificationMessages;

  managePermission = PERMISSION_MANAGE_FLIGHT;

  displayableFields: DisplayableField[] = [
    {
      value: 'pointOfSaleNames',
      label: 'Point of sale',
      isSortable: true
    },
    {
      value: 'firstMarketNames',
      label: 'First market'
    },
    {
      value: 'secondMarketNames',
      label: 'Second market'
    },
    {
      value: 'ruleStatusActive',
      label: 'Rule status',
      isSortable: true
    },
    {
      value: 'description',
      label: 'Description'
    }
  ];
  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.flightRuleSearchSending$ = this.store.pipe(select(selectFlightRuleSearchSending));
    this.flightRuleDeleteSending$ = this.store.pipe(select(selectFlightRuleDeleteSending));
    this.flightRules$ = this.store.pipe(select(selectFlightRules));
    this.notification$ = this.store.pipe(select(selectFlightRuleSearchNotification));
    this.mainMessages = {
      error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
      warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
      success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
    };
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.flights.search.navigationTitle:Flight Rule Search`);
    this.store.dispatch(new ClearFlightRulesAction({}));
    this.navigationService.activateFlights();
    this.navigationService.enableNavigation();

    this.displayableFields.push({
      value: 'airContentType',
      label: 'Air content type',
      isSortable: true
    });

    this.store.dispatch(new DeleteFlightRuleCreateNotificationAction({}));
  }

  searchFlightRules(searchCriteria: JourneySearchCriteriaUi) {
    const request = this.createFlightRulesSearchRequest(searchCriteria);
    this.store.dispatch(new SearchFlightRuleAction({ request }));
  }

  createFlightRulesSearchRequest(searchCriteria: JourneySearchCriteriaUi): FlightRuleSearchRequest {
    const request = {
      version: '1.0',
      searchJourney: convertToJourneySearchCriteria(searchCriteria)
    };
    request.searchJourney.rule = this.userDetailsService.assignEntity(request.searchJourney?.rule);
    return request;
  }

  closeNotification() {
    this.store.dispatch(new DeleteFlightRuleSearchNotificationAction({}));
  }

  clearFlightRules() {
    this.store.dispatch(new ClearFlightRulesAction({}));
    this.router.navigate(['flights/search']);
  }

  showFlightRule(id: string) {
    this.store.dispatch(new ActivateFlightRuleDisplayAction({ id: undefined }));
    this.store.dispatch(new DeleteFlightRuleDisplayNotificationAction({}));
    this.router.navigate(['flights/display/' + id]); // NOSONAR
  }

  modifyFlightRule(flightRuleId: string) {
    this.store.dispatch(new StartFlightModificationAction({ id: flightRuleId }));
    this.router.navigate(['flights/modify/']);
  }

  storeUnsavedFlightSearch() {
    this.searchCriteriaComponent.storeUnsavedFlightSearch();
  }

  copyFlightRule(flightRuleId: string) {
    this.store.dispatch(new CopyFlightRuleAction({ id: flightRuleId }));
    this.store.dispatch(new DeleteFlightRuleCreateNotificationAction({}));
    this.router.navigate(['flights/create/']);
  }

  deleteFlightRule(request: FlightRuleDeleteRequest) {
    this.store.dispatch(new DeleteFlightRuleAction({ request }));
  }

  getSourceComponent(): UppComponentNames {
    return UppComponentNames.FLIGHTS;
  }

  getEntryType(): EntryType {
    return EntryType.JOURNEY;
  }

  getFlightSearchRecordPermissions(record: any): string[] {
    const neededPermissions = [];

    // Old records do not have 'contentTypes' defined, so
    // they are treated as EDIFACT records.
    if (!record.applicability.contentTypes || record.applicability.contentTypes?.includes(ContentType.EDIFACT)) {
      neededPermissions.push(PERMISSION_MANAGE_EDIFACT_RULE);
    }

    if (record.applicability.contentTypes?.includes(ContentType.NDC)) {
      neededPermissions.push(PERMISSION_MANAGE_NDC_RULE);
    }

    return neededPermissions;
  }

  recordToSearchResult(flightRecord: any): any {
    const pointOfSaleNames = this.getPosOrMarketNames(flightRecord.applicability.pointOfSaleName);
    const searchResult = {
      id: flightRecord.rule.id,
      name: flightRecord.rule.name,
      description: flightRecord.rule.description ? flightRecord.rule.description : '',
      organization: flightRecord.rule.organization,
      version: flightRecord.rule.version,
      pointOfSaleNames,
      originNames: this.getPosOrMarketNames(flightRecord.applicability.originNames),
      destinationNames: this.getPosOrMarketNames(flightRecord.applicability.destinationNames),
      actionType: calculateActionType(flightRecord),
      ruleStatusActive: flightRecord.rule.active,
      firstMarketNames: getMarketNames(flightRecord.applicability.marketPairs, 'firstMarketName'),
      secondMarketNames: getMarketNames(flightRecord.applicability.marketPairs, 'secondMarketName')
    };

    searchResult['airContentType' as keyof typeof searchResult] =
      flightRecord.applicability.contentTypes?.join(',') ?? ContentType.EDIFACT;

    return searchResult;
  }

  getPosOrMarketNames(names: string[] | string): string {
    if (names === undefined || names.length === 0) {
      return '';
    }
    return names.toString();
  }

  entryToUpdateCreator(
    id: string,
    name: string,
    organization: string,
    version: string,
    ruleVersion: number
  ): FlightRuleDeleteRequest {
    return {
      version,
      journey: {
        rule: {
          id,
          name,
          version: ruleVersion
        }
      }
    };
  }

  entryToUpdateNameResolver(entryToUpdate: FlightRuleDeleteRequest) {
    return entryToUpdate.journey.rule.name;
  }
}

const calculateActionType = (journey: JourneyUi): string => {
  if (!journey.rule || journey.rule.action === undefined) {
    return '';
  }
  if (journey.rule.action === INCLUDE_ACTION) {
    return $localize`:@@upp.flights.search.table.include:Incl.`;
  }
  return $localize`:@@upp.flights.search.table.exclude:Excl.`;
};

const getMarketNames = (marketPairs: MarketPair[], property: string): string =>
  marketPairs
    ?.reduce((acc, marketPair) => {
      const marketProperty = getPropertyByKey(marketPair, property);
      if (marketProperty) {
        acc.push(marketProperty);
      }
      return acc;
    }, [] as any[])
    .join(', ');
