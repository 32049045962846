<form class="styled-form" [formGroup]="hotelDetailsForm" id="hotelDetailsForm">
  <div class="d-flex justify-content-end mb-5">
    <button
      i18n="@@upp.global.button.clear"
      *ngIf="!readonly"
      type="button"
      class="btn btn-outline-primary btn-sm"
      (click)="clearScreen()"
      data-test="clear-button"
    >
      Clear
    </button>
  </div>

  <div class="row justify-content-end">
    <div class="col-2">
      <div class="mb-5 form-check form-switch">
        <label class="form-check-label df-form-switch-name" for="activationToggle"
          >Activate rule
          <div class="df-form-switch-container">
            <input
              class="form-check-input df-toggle-primary"
              name="activationToggle"
              type="checkbox"
              id="activationToggle"
              formControlName="active"
              [readonly]="readonly"
              (change)="onActivationToggleChange($event)"
            />
            <span class="form-check-label">{{ active?.value ? 'On' : 'Off' }}</span>
          </div>
        </label>
      </div>
    </div>
    <div class="col-10"></div>
  </div>

  <div class="mb-5">
    <label for="name" class="form-label">
      <ng-container i18n="@@upp.hotels.details.nameLabel">Name</ng-container>
      <span class="mandatory ms-1">*</span>
    </label>
    <input
      type="text"
      class="form-control"
      id="name"
      formControlName="name"
      [readonly]="readonly"
      [ngClass]="{ 'is-invalid': nameControl?.invalid && nameControl?.touched }"
      placeholder="Hotel rule name"
      i18n-placeholder="@@upp.hotels.common.namePlaceholder"
      ngbTooltip="Enter the Hotel Rule name with letters, numbers or spaces (up to 30 characters)."
      i18n-ngbTooltip="@@upp.hotels.common.nameTooltip"
      oninput="let cursorPosition=this.selectionStart;
          this.value=this.value.toUpperCase();
          this.setSelectionRange(cursorPosition, cursorPosition);"
    />
    <ama-ng-upp-validation-error-message
      [control]="nameControl ?? undefined"
      [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES"
    ></ama-ng-upp-validation-error-message>
  </div>
  <div class="mb-5">
    <label i18n="@@upp.hotels.details.descriptionLabel" class="form-label" for="description">Description</label>
    <input
      type="text"
      class="form-control"
      id="description"
      formControlName="description"
      [ngClass]="{
        'is-invalid':
          !hotelDetailsForm.get(HOTEL_FORM_ELEMENTS.DESCRIPTION)?.valid &&
          hotelDetailsForm.get(HOTEL_FORM_ELEMENTS.DESCRIPTION)?.touched
      }"
      [readonly]="readonly"
      placeholder="Hotel rule description"
      i18n-placeholder="@@upp.hotels.details.descriptionPlaceholder"
      ngbTooltip="Describe the Hotel Rule (up to 128 characters)."
      i18n-ngbTooltip="@@upp.hotels.details.descriptionTooltip"
    />
    <ama-ng-upp-validation-error-message
      [control]="hotelDetailsForm.get(HOTEL_FORM_ELEMENTS.DESCRIPTION) ?? undefined"
      [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES"
    ></ama-ng-upp-validation-error-message>
  </div>

  <div formGroupName="hotelApplicability">
    <div class="mb-5">
      <label for="pointOfSaleNames" class="form-label">
        <ng-container i18n="@@upp.global.criteria.pointOfSale.label">Point of sale</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-lookup-select
        id="pointOfSaleNames"
        formControlName="pointOfSaleNames"
        [items]="availablePosNames"
        [multiple]="true"
        [lookupOptions]="readonly ? undefined : posLookupOptions"
        notFoundText="No POS found"
        i18n-notFoundText="@@upp.global.criteria.pos.notFoundText"
      >
      </ama-ng-upp-lookup-select>
      <ama-ng-upp-validation-error-message
        [control]="applicability.get(HOTEL_FORM_ELEMENTS.POINT_OF_SALE_NAMES) ?? undefined"
        [messageFunctions]="REQUIRED_MSG"
      >
      </ama-ng-upp-validation-error-message>
    </div>

    <div class="mb-5">
      <label for="destinationNames" class="form-label">
        <ng-container i18n="@@upp.global.criteria.market.label">Markets</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-lookup-select
        id="destinationNames"
        formControlName="destinationNames"
        [items]="availableMarketsNames"
        [multiple]="true"
        [lookupOptions]="readonly ? undefined : marketLookupOptions"
        notFoundText="No markets found"
        i18n-notFoundText="@@upp.global.criteria.market.notFoundText"
      >
      </ama-ng-upp-lookup-select>
      <ama-ng-upp-validation-error-message
        [control]="applicability.get(HOTEL_FORM_ELEMENTS.DESTINATION_NAMES) ?? undefined"
        [messageFunctions]="REQUIRED_MSG"
      >
      </ama-ng-upp-validation-error-message>
    </div>

    <div class="mb-5">
      <div role="radiogroup">
        <div class="row mb-3">
          <div class="col">
            <label for="hotelSourceType" class="label-criteria">
              <ng-container i18n="@@upp.global.criteria.sourceType.label">Hotel source type</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>

            <div class="form-check form-check-inline ms-2">
              <input
                class="form-check-input"
                type="radio"
                data-test="source-type-all"
                id="sourceTypeAll"
                value="All"
                name="sourceType"
                formControlName="sourceType"
                (change)="onSourceTypeChange($event)"
              />
              <label i18n="@@upp.global.labels.all" class="form-check-label" for="sourceTypeAll"> All content </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="sourceTypeGds"
                data-test="source-type-gds"
                value="GDS"
                name="sourceType"
                formControlName="sourceType"
                (change)="onSourceTypeChange($event)"
              />
              <label i18n="@@upp.global.labels.gds" class="form-check-label" for="sourceTypeGds"> GDS only </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="sourceTypeAggregator"
                data-test="source-type-aggregator"
                value="Aggregator"
                name="sourceType"
                formControlName="sourceType"
                (change)="onSourceTypeChange($event)"
              />
              <label i18n="@@upp.global.labels.aggregator" class="form-check-label" for="sourceTypeAggregator">
                Aggregator only
              </label>
            </div>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.SOURCE_TYPE) ?? undefined"
              [messageFunctions]="REQUIRED_MSG"
            ></ama-ng-upp-validation-error-message>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2">
      <ngb-alert
        dfInsertAlertIcon
        *ngIf="showWarningMessage"
        type="tip"
        [dismissible]="false"
        class="sticky-warning-msg"
        data-test="sticky-warning"
      >
        <ng-container i18n="@@upp.hotel.warning.message">
          If you don't select anything in Properties/Chains or Rates, all the options will be added to the rule.
        </ng-container>
      </ngb-alert>

      <label class="d-block mb-3">
        <ng-container i18n="@@upp.flights.details.ruleContentLabel">Rule content</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>

      <div class="mb-5">
        <div class="d-flex align-items-center">
          <ama-ng-upp-button-group [items]="ruleContentButtonGroupItems" (toggled)="onRuleContentToggle($event)">
          </ama-ng-upp-button-group>
        </div>

        <ama-ng-upp-validation-error-message
          [control]="hasRuleContent ?? undefined"
          [messageFunctions]="SECTION_VALIDATION_MESSAGES"
        ></ama-ng-upp-validation-error-message>
      </div>

      <div class="properties-content-container" *ngIf="ruleContentButtonGroupMap.properties?.active">
        <label class="d-block mb-3">
          <ng-container i18n="@@upp.hotel.details.propertyLabel">Property/Chains scope</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>

        <div class="mb-5">
          <div class="d-flex align-items-center">
            <ama-ng-upp-button-group
              [items]="propertiesScopeButtonGroupItems"
              (toggled)="onPropertiesScopeToggle($event)"
            >
            </ama-ng-upp-button-group>
          </div>

          <ama-ng-upp-validation-error-message
            [control]="hasPropertiesSelected!"
            [messageFunctions]="SECTION_VALIDATION_MESSAGES"
          ></ama-ng-upp-validation-error-message>

          @if (propertiesScopeButtonGroupMap.masterChains.active) {
          <div class="row mt-5">
            <label class="form-label" for="masterChains">
              <ng-container i18n="@@upp.global.criteria.masterChains.label">Master chain</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="masterChains"
              formControlName="masterChains"
              [addTag]="true"
              [multiple]="true"
              notFoundText="No hotel master chain found"
              i18n-notFoundText="@@upp.global.criteria.masterChains.notFoundText:No hotel master chain found"
              tooltip="Enter 2 character hotel master chain(s)"
              i18n-tooltip="@@upp.global.criteria.masterChains.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.MASTER_CHAINS) ?? undefined"
              [messageFunctions]="TWO_SYMBOLS_REQUIRED"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (propertiesScopeButtonGroupMap.chains.active) {
          <div class="row mt-5">
            <label class="form-label" for="chains">
              <ng-container i18n="@@upp.global.criteria.chains.label">Chain</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="chains"
              formControlName="chains"
              [addTag]="true"
              [multiple]="true"
              notFoundText="No hotel master chain found"
              i18n-notFoundText="@@upp.global.criteria.chains.notFoundText:No hotel chain found"
              tooltip="Enter 2 character hotel chain(s)"
              i18n-tooltip="@@upp.global.criteria.chains.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.CHAINS) ?? undefined"
              [messageFunctions]="TWO_SYMBOLS_REQUIRED"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (propertiesScopeButtonGroupMap.hotelChainsFamilies.active) {
          <div class="row mt-5">
            <label class="form-label" for="hotelChainsFamilies">
              <ng-container i18n="@@upp.global.criteria.hotelChainsFamilies.label">Chain family</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="hotelChainsFamilies"
              formControlName="hotelChainsFamilies"
              [multiple]="true"
              notFoundText="No hotel chain family found"
              [items]="availableHotelChainsFamilies"
              [lookupOptions]="canLookupFamily() ? familyLookupOptions : undefined"
              i18n-notFoundText="@@upp.global.criteria.hotelChainsFamily.notFoundText:No hotel chain family found"
              tooltip="Enter a hotel chain family name, max. 30 alpha numeric"
              i18n-tooltip="@@upp.global.criteria.hotelChainsFamily.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.HOTEL_CHAINS_FAMILIES) ?? undefined"
              [messageFunctions]="REQUIRED_MSG"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (propertiesScopeButtonGroupMap.propertyCodes.active) {
          <div class="row mt-5">
            <label class="form-label" for="propertyCodes">
              <ng-container i18n="@@upp.global.criteria.propertyCodes.label">Property code</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="propertyCodes"
              formControlName="propertyCodes"
              [multiple]="true"
              [addTag]="true"
              notFoundText="No hotel master chain found"
              i18n-notFoundText="@@upp.global.criteria.hotelPropertyCodes.notFoundText:No hotel property code found"
              tooltip="Enter 8 character hotel property code(s)"
              i18n-tooltip="@@upp.global.criteria.hotelPropertyCodes.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.PROPERTY_CODES) ?? undefined"
              [messageFunctions]="EIGHT_SYMBOLS"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (propertiesScopeButtonGroupMap.hotelPropertyCodesFamilies.active) {
          <div class="row mt-5">
            <label class="form-label" for="hotelPropertyCodesFamilies">
              <ng-container i18n="@@upp.global.criteria.hotelPropertyCodesFamilies.label"
                >Property code family</ng-container
              >
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="hotelPropertyCodesFamilies"
              formControlName="hotelPropertyCodesFamilies"
              [multiple]="true"
              notFoundText="No hotel property code family found"
              [items]="availableHotelPropertyCodesFamilies"
              [lookupOptions]="canLookupFamily() ? familyLookupOptions : undefined"
              i18n-notFoundText="
                @@upp.global.criteria.hotelPropertyCodesFamilies.notFoundText:No hotel property code found"
              tooltip="Enter a hotel property code family name, max. 30 alpha numeric"
              i18n-tooltip="@@upp.global.criteria.hotelPropertyCodesFamilies.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.HOTEL_PROPERTY_CODES_FAMILIES) ?? undefined"
              [messageFunctions]="REQUIRED_MSG"
            ></ama-ng-upp-validation-error-message>
          </div>
          }
        </div>
      </div>

      <div class="rates-content-container" *ngIf="ruleContentButtonGroupMap.rates?.active">
        <label class="d-block mb-3">
          <ng-container i18n="@@upp.hotel.details.ratesLabel">Rates scope</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <div class="mb-5">
          <div class="d-flex align-items-center">
            <ama-ng-upp-button-group [items]="ratesScopeButtonGroupItems" (toggled)="onRatesScopeToggle($event)">
            </ama-ng-upp-button-group>
          </div>

          <ama-ng-upp-validation-error-message
            [control]="hasRatesSelected!"
            [messageFunctions]="SECTION_VALIDATION_MESSAGES"
          ></ama-ng-upp-validation-error-message>

          @if (ratesScopeButtonGroupMap.rateFamilies.active) {
          <div class="row mt-5">
            <label class="form-label">
              <ng-container i18n="@@upp.global.criteria.rateFamilies.label">Rate family code</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="rateFamilies"
              formControlName="rateFamilies"
              [addTag]="true"
              [multiple]="true"
              notFoundText="No hotel rate family found"
              i18n-notFoundText="@@upp.global.criteria.rateFamilies.notFoundText:No hotel rate family found"
              tooltip="Enter 3 character rate family code(s)"
              i18n-tooltip="@@upp.global.criteria.rateFamilies.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.RATE_FAMILIES) ?? undefined"
              [messageFunctions]="THREE_SYMBOLS"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (ratesScopeButtonGroupMap.rateCodes.active) {
          <div class="row mt-5">
            <label class="form-label">
              <ng-container i18n="@@upp.global.criteria.rateCodes.label">Rate code</ng-container>
              <span class="mandatory ms-1">*</span>
            </label>
            <ama-ng-upp-lookup-select
              id="rateCodes"
              formControlName="rateCodes"
              [addTag]="true"
              [multiple]="true"
              notFoundText="No hotel rate code found"
              i18n-notFoundText="@@upp.global.criteria.rateCodes.notFoundText:No hotel rate code found"
              tooltip="Enter 3 character rate code(s)"
              i18n-tooltip="@@upp.global.criteria.rateCodes.tooltip"
            >
            </ama-ng-upp-lookup-select>
            <ama-ng-upp-validation-error-message
              [control]="applicability.get(HOTEL_FORM_ELEMENTS.RATE_CODES) ?? undefined"
              [messageFunctions]="THREE_SYMBOLS"
            ></ama-ng-upp-validation-error-message>
          </div>
          } @if (ratesScopeButtonGroupMap.commissionable.active) {
          <div class="row mt-5" role="radiogroup" aria-labelledby="commissionable">
            <div class="d-flex flex-wrap">
              <label class="label-criteria" for="commissionable">
                <ng-container i18n="@@upp.global.criteria.commissionableLabel.label">Commissionable</ng-container>
                <span class="mandatory ms-1">*</span>
              </label>
              <div class="form-check form-check-inline ms-2">
                <input
                  class="form-check-input"
                  type="radio"
                  id="commissionableYes"
                  value="yes"
                  name="commissionable"
                  formControlName="commissionable"
                />
                <label i18n="@@upp.global.labels.yes" class="form-check-label" for="commissionableYes"> Yes </label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="commissionableNo"
                  value="no"
                  name="commissionable"
                  formControlName="commissionable"
                />
                <label i18n="@@upp.global.labels.no" class="form-check-label" for="commissionableNo"> No </label>
              </div>
              <ama-ng-upp-validation-error-message
                [control]="applicability.get(HOTEL_FORM_ELEMENTS.COMMISSIONABLE) ?? undefined"
                [messageFunctions]="REQUIRED_MSG"
              ></ama-ng-upp-validation-error-message>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <label class="form-label" for="action"
        ><ng-container i18n="@@upp.hotels.details.actionLabel">Action</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <div class="d-flex flex-wrap align-items-center">
        <div class="me-2">
          <button
            i18n="@@upp.global.labels.exclude"
            type="button"
            id="exclude"
            [disabled]="readonly"
            class="btn btn-criteria btn-sm mb-md-0 me-4"
            [ngClass]="isExcludeSelected() ? 'btn-exclude' : 'btn-outline-exclude'"
            (click)="setExcludeSelected()"
          >
            Exclude
          </button>
          <button
            i18n="@@upp.global.labels.include"
            type="button"
            id="include"
            [disabled]="readonly"
            class="btn btn-criteria btn-sm mb-md-0 me-4"
            [ngClass]="isIncludeSelected() ? 'btn-include' : 'btn-outline-include'"
            (click)="setIncludeSelected()"
          >
            Force include
          </button>
          <button
            i18n="@@upp.global.labels.lightExcludeLabel"
            type="button"
            class="btn btn-criteria btn-sm mb-md-0 me-4"
            id="lightExclude"
            [disabled]="readonly"
            [ngClass]="isLightExcludeSelected() ? 'btn-light-exclude' : 'btn-outline-light-exclude'"
            (click)="setLightExcludeSelected()"
          >
            Light exclude
          </button>
          <ama-ng-upp-validation-error-message
            [control]="hotelDetailsForm.get(HOTEL_FORM_ELEMENTS.ACTION) ?? undefined"
            [messageFunctions]="SECTION_VALIDATION_MESSAGES"
          ></ama-ng-upp-validation-error-message>
        </div>
        <div>
          <input type="hidden" formControlName="action" />
        </div>
      </div>
    </div>
  </div>
</form>
