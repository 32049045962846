import {
  HotelChainsFamily,
  HotelChainsElements,
  HotelPropertyCodesFamily,
  HotelPropertyCodesElements,
  HotelChainsFamilySearchCriteria,
  HotelPropertyCodesFamilySearchCriteria
} from './family';
import { FamilyUi, FamilySearchCriteriaUI, HOTEL_CHAINS_TYPE, HOTEL_PROP_CODES_TYPE } from './family-ui';

export const convertFamilyUiToHotelChainsFamily = (familyUi: FamilyUi): HotelChainsFamily => {
  const hotelChainsFamily: HotelChainsFamily = {
    name: familyUi.name,
    elements: convertToHotelChainsElements(familyUi)
  };
  if (familyUi.organization) {
    hotelChainsFamily.organization = familyUi.organization;
  }
  if (familyUi.partitionId) {
    hotelChainsFamily.partitionId = familyUi.partitionId;
  }
  if (familyUi.id && familyUi.id.length > 0) {
    hotelChainsFamily.id = familyUi.id;
  }
  if (familyUi.version) {
    hotelChainsFamily.version = familyUi.version;
  }
  if (familyUi.description && familyUi.description.length > 0) {
    hotelChainsFamily.description = familyUi.description;
  }

  return hotelChainsFamily;
};

export const convertHotelChainsFamilyToFamilyUi = (hotelChainsFamily: HotelChainsFamily): FamilyUi => ({
  organization: hotelChainsFamily.organization,
  name: hotelChainsFamily.name,
  id: hotelChainsFamily.id,
  version: hotelChainsFamily.version,
  description: hotelChainsFamily.description,
  hotelFamilyType: HOTEL_CHAINS_TYPE,
  hotelChains: hotelChainsFamily.elements.chains,
  hotelPropertyCodes: []
});

const convertToHotelChainsElements = (familyUi: FamilyUi): HotelChainsElements => ({
  chains: familyUi.hotelChains
});

export const convertFamilyUiToHotelPropertyCodesFamily = (familyUi: FamilyUi): HotelPropertyCodesFamily => {
  const hotelPropertyCodesFamily: HotelPropertyCodesFamily = {
    name: familyUi.name,
    elements: convertToHotelPropertyCodesElements(familyUi)
  };

  if (familyUi.organization) {
    hotelPropertyCodesFamily.organization = familyUi.organization;
  }
  if (familyUi.partitionId) {
    hotelPropertyCodesFamily.partitionId = familyUi.partitionId;
  }
  if (familyUi.id && familyUi.id.length > 0) {
    hotelPropertyCodesFamily.id = familyUi.id;
  }
  if (familyUi.version) {
    hotelPropertyCodesFamily.version = familyUi.version;
  }
  if (familyUi.description && familyUi.description.length > 0) {
    hotelPropertyCodesFamily.description = familyUi.description;
  }

  return hotelPropertyCodesFamily;
};

export const convertHotelPropertyCodesFamilyToFamilyUi = (
  hotelPropertyCodesFamily: HotelPropertyCodesFamily
): FamilyUi => ({
  organization: hotelPropertyCodesFamily.organization,
  name: hotelPropertyCodesFamily.name,
  id: hotelPropertyCodesFamily.id,
  version: hotelPropertyCodesFamily.version,
  description: hotelPropertyCodesFamily.description,
  hotelFamilyType: HOTEL_PROP_CODES_TYPE,
  hotelChains: [],
  hotelPropertyCodes: hotelPropertyCodesFamily.elements.propertyCodes
});

const convertToHotelPropertyCodesElements = (familyUi: FamilyUi): HotelPropertyCodesElements => ({
  propertyCodes: familyUi.hotelPropertyCodes
});

export const convertCriteriaUIToHotelChainsFamilySearchCriteria = (
  searchCriteriaUi: FamilySearchCriteriaUI
): HotelChainsFamilySearchCriteria => {
  const searchCriteria: HotelChainsFamilySearchCriteria = {
    organization: searchCriteriaUi.organization
  };

  if (searchCriteriaUi.name !== undefined && searchCriteriaUi.name.length > 0) {
    searchCriteria.name = searchCriteriaUi.name;
  }

  if (searchCriteriaUi.hotelChains !== undefined && searchCriteriaUi.hotelChains.length > 0) {
    searchCriteria.chains = searchCriteriaUi.hotelChains;
  }

  return searchCriteria;
};

export const convertCriteriaUIToHotelPropertyCodesFamilySearchCriteria = (
  searchCriteriaUi: FamilySearchCriteriaUI
): HotelPropertyCodesFamilySearchCriteria => {
  const searchCriteria: HotelPropertyCodesFamilySearchCriteria = {
    organization: searchCriteriaUi.organization
  };

  if (searchCriteriaUi.name !== undefined && searchCriteriaUi.name.length > 0) {
    searchCriteria.name = searchCriteriaUi.name;
  }

  if (searchCriteriaUi.hotelPropertyCodes !== undefined && searchCriteriaUi.hotelPropertyCodes.length > 0) {
    searchCriteria.propertyCodes = searchCriteriaUi.hotelPropertyCodes;
  }

  return searchCriteria;
};
