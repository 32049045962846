import { HotelRuleCreateUpdateResponse, HotelRuleSearchResponse } from '../../hotels/model/hotel-response';
import { TYPE_ERROR, TYPE_SUCCESS } from '../model';
import { UppNotification, DEFAULT_SUCCESS } from '../../model/notification';
import { TYPE_WARNING } from '../model/pos/pos-market-record';
import { HotelRuleUi, convertToHotelRuleUi, HotelRuleDeleteRequest } from '../../hotels/model';
import { SUCCESS_STATUS, MISSING_RESULT_STATUS, UNKNOWN_RESULT_STATUS, buildErrorOrWarning } from './response-handler';
import { BaseResponse } from '../../base/model/base-response';

export const successDeleteHotelUi: HotelRuleUi = {
  statusType: TYPE_SUCCESS,
  id: '',
  organization: '',
  name: '',
  action: '',
  active: false,
  hotelApplicability: {},
  statusNotification: { success: [DEFAULT_SUCCESS] }
};

export const errorHotelUi: HotelRuleUi = {
  statusType: TYPE_ERROR,
  id: 'hotelRuleError',
  organization: '',
  name: '',
  action: '',
  active: false,
  hotelApplicability: {},
  statusNotification: { error: [MISSING_RESULT_STATUS] }
};

export const warningHotelUi: HotelRuleUi = {
  statusType: TYPE_WARNING,
  id: 'hotelRuleWarning',
  organization: '',
  name: '',
  action: '',
  active: false,
  hotelApplicability: {},
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] }
};

export const buildHotelFromCreateOrUpateResponse = (
  response: HotelRuleCreateUpdateResponse,
  hotelsV2: boolean | null
): HotelRuleUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return createSuccessUiForHotelCreate(response, true, hotelsV2);
  }
  return buildFailedHotelResponse(response);
};

export const buildHotelRuleListFromSearchResponse = (
  response: HotelRuleSearchResponse,
  hotelsV2: boolean | null
): HotelRuleUi[] => {
  if (response.status.state === SUCCESS_STATUS) {
    return createHotelUiList(response, hotelsV2);
  }
  return [buildFailedHotelResponse(response)];
};

export const buildHotelRuleFromDeleteResponse = (
  response: BaseResponse,
  request: HotelRuleDeleteRequest
): HotelRuleUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return {
      ...successDeleteHotelUi,
      id: request.rule.id
    };
  }
  return buildFailedHotelResponse(response);
};

const buildFailedHotelResponse = (response: BaseResponse): HotelRuleUi =>
  buildErrorOrWarning(warningHotelUi, errorHotelUi, response) as HotelRuleUi;

const createHotelUiList = (response: HotelRuleSearchResponse, hotelsV2: boolean | null): HotelRuleUi[] => {
  const hotelRules: HotelRuleUi[] = [];
  response.rules.forEach((rule) => hotelRules.push(convertToHotelRuleUi(rule, hotelsV2)));
  return hotelRules;
};

const createSuccessUiForHotelCreate = (
  response: HotelRuleCreateUpdateResponse,
  addLinkData: boolean,
  hotelsV2: boolean | null
): HotelRuleUi => {
  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!response.rule.id) {
    throw new Error('id is null or undefined.');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: response.rule.id,
        name: response.rule.name
      }
    ];
  }
  const hotelRule = convertToHotelRuleUi(response.rule, hotelsV2);
  hotelRule.statusType = TYPE_SUCCESS;
  hotelRule.statusNotification = notification;
  return hotelRule;
};
