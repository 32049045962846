import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FaresState, FARES_FEATURE } from './fares-state';

export const selectFares = createFeatureSelector<FaresState>(FARES_FEATURE);

export const selectFareRuleCreateValue = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.create.value
);

export const selectFareRuleCreateSending = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.create.sending
);

export const selectFareRuleCreateNotification = createSelector(selectFares, (state: FaresState) => {
  if (state.notification && state.notification.create) {
    return state.notification.create;
  }
  return undefined;
});

export const selectFareModifyActivated = createSelector(selectFares, (state: FaresState) => {
  if (state.fareRule.modify.active) {
    return state.fareRule.modify.active;
  }
  return false;
});

export const selectFareRuleDisplayActivated = createSelector(selectFares, (state: FaresState) => {
  if (state.fareRule.display.active) {
    return state.fareRule.display.active;
  }
  return false;
});

export const selectFareModifyValue = createSelector(selectFares, (state: FaresState) => state.fareRule.modify.value);

export const selectFareModifySending = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.modify.sending
);

export const selectFareModifyNotification = createSelector(selectFares, (state: FaresState) => {
  if (state.notification && state.notification.modify) {
    return state.notification.modify;
  }
  return undefined;
});

export const selectFareRuleDisplayValue = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.display.value
);

export const selectFareRuleDisplayNotification = createSelector(selectFares, (state: FaresState) => {
  if (state.notification && state.notification.display) {
    return state.notification.display;
  }
  return undefined;
});

export const selectFareRules = createSelector(selectFares, (state: FaresState) => state.fareRules);

export const selectFareRuleSearchSending = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.search.sending
);

export const selectFareRuleSearchNotification = createSelector(selectFares, (state: FaresState) => {
  if (state.notification && state.notification.search) {
    return state.notification.search;
  }
  return undefined;
});

export const selectFareRuleSearchValue = createSelector(
  selectFares,
  (state: FaresState) => state.fareRule.search.value
);

export const selectFareRuleDeleteSending = createSelector(selectFares, (state: FaresState) => {
  if (state && state.fareRule && state.fareRule.delete) {
    return state.fareRule.delete.sending;
  }
  return undefined;
});
