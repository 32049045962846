import { ALL_ACTION, RULE_STATUS_ALL, COMMISSIONABLE_ALL } from './hotel-rule-ui';

export interface HotelRuleSearchCriteria {
  organization?: string;
  partitionId?: string;
  name?: string;
  active?: boolean;
  pointOfSale?: {
    names: string[];
  };
  destination?: {
    names: string[];
  };
  action?: string;
  commissionable?: boolean;
}

export interface HotelRuleSearchCriteriaUi {
  organization: string;
  name?: string;
  ruleStatus: string;
  actionType: string;
  commissionable: string;
  applicability?: {
    pointOfSale: string[];
    destination: string[];
  };
}

export const initialHotelRuleSearchCriteria: HotelRuleSearchCriteriaUi = {
  organization: '',
  ruleStatus: RULE_STATUS_ALL,
  actionType: ALL_ACTION,
  commissionable: COMMISSIONABLE_ALL
};
