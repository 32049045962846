import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
  @Input() targetPartition!: string;

  cancelButton = $localize`:@@upp.global.button.Cancel:Cancel`;
  switchButton = $localize`:@@upp.global.button.Switch:Switch`;

  constructor(public activeModal: NgbActiveModal) {}
}
