import { Component, OnChanges, Input, ViewChild, TemplateRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { UppComponent, UppComponentNames, UppViewNames } from '../../service/model/upp-component';
import {
  HotelApplicabilityUi,
  COMMISSIONABLE_YES,
  EXCLUDE_ACTION,
  INCLUDE_ACTION,
  COMMISSIONABLE_NO,
  COMMISSIONABLE_ALL,
  initialHotelRuleUi,
  HotelRuleUi
} from '../model';
import { UserDetailsService } from '../../service/user-details.service';
import { HotelServiceElements, VALIDATORS } from '../model/hotel-service-elements';
import { NavigationService } from '../../service/core/navigation.service';
import { LookupOptions } from '../../core/util/lookup-options';
import { select, Store } from '@ngrx/store';
import { selectAllAvailablePosRecords } from '../../store/pos/pos-selector';
import { selectAllAvailableMarketsRecords } from '../../store/markets/markets-selector';
import {
  selectAvailableFamiliesHotelChains,
  selectAvailableFamiliesHotelPropertyCodes
} from '../../store/families/families-selector';
import { SelectableCriteria, SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import {
  PERMISSION_VIEW_FAMILY,
  PERMISSION_VIEW_MARKET,
  PERMISSION_VIEW_POS
} from '../../service/user-permissions.service';
import { UppValidatorService } from '../../service/upp-validator.service';
import { getCriteria } from '../../util/criteria-utils';
import { getPropertyByKey } from '../../util/utils';

export class HotelRuleAvailableCriteria {
  readonly POS = HotelServiceElements.POINT_OF_SALES;
  readonly MARKETS = HotelServiceElements.DESTINATIONS;
  readonly HOTEL_CHAINS_FAMILIES = HotelServiceElements.HOTEL_CHAINS_FAMILIES;
  readonly HOTEL_PROPERTY_CODES_FAMILIES = HotelServiceElements.HOTEL_PROPERTY_CODES_FAMILIES;
  readonly HOTEL_MASTER_CHAINS = HotelServiceElements.MASTER_CHAINS;
  readonly HOTEL_CHAINS = HotelServiceElements.CHAINS;
  readonly HOTEL_PROPERTY_CODES = HotelServiceElements.PROPERTY_CODES;
  readonly HOTEL_RATE_CODES = HotelServiceElements.RATE_CODES;
  readonly COMMISSIONABLE = HotelServiceElements.COMMISSIONABLE;
  readonly SOURCE_TYPES = HotelServiceElements.SOURCE_TYPES;
  readonly RATE_FAMILIES = HotelServiceElements.RATE_FAMILIES;
}

@Component({
  selector: 'ama-ng-upp-hotel-details',
  templateUrl: './hotels-details.component.html',
  styleUrls: ['./hotels-details.component.scss']
})
export class HotelsDetailsComponent implements OnChanges, AfterViewInit {
  @Input() hotelDetailsForm!: UntypedFormGroup;
  @Input() hotelDetails: HotelRuleUi = initialHotelRuleUi;
  @Input() readonly = false;
  @Input() parent!: UppComponent;

  @ViewChild('sourceTypesTemplate') sourceTypesTemplate!: TemplateRef<any>;
  @ViewChild('comissionableTemplate') comissionableTemplate!: TemplateRef<any>;

  readonly COMPONENT_NAME = UppComponentNames.HOTELS;

  specialCriteria: string[];
  criteriaGroupOne: string[];
  criteriaGroupTwo: string[];
  criteriaGroupThree: string[];
  criteriaGroupFour: string[];

  hotelCriteria: SelectableCriteria[];
  availableCriteria: HotelRuleAvailableCriteria;
  nameRegex = '^[A-Z0-9]{0,30}$';

  isExludeActive = false;
  isIncludeActive = false;

  initialHotelApplicability: HotelApplicabilityUi = {
    sourceTypesGds: false,
    sourceTypesAggregator: false,
    commissionable: COMMISSIONABLE_ALL,
    pointOfSaleNames: undefined,
    destinationNames: undefined,
    masterChains: undefined,
    chains: undefined,
    hotelChainsFamilies: undefined,
    propertyCodes: undefined,
    hotelPropertyCodesFamilies: undefined,
    rateCodes: undefined,
    rateFamilies: undefined
  };

  posLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.POS,
    sourceComponent: UppComponentNames.HOTELS,
    sourceView: UppViewNames.CREATE
  };

  marketLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.MARKETS,
    sourceComponent: UppComponentNames.HOTELS,
    sourceView: UppViewNames.CREATE
  };

  familyLookupOptions: LookupOptions = {
    destinationComponent: `${UppComponentNames.FAMILIES}/hotel`,
    sourceComponent: UppComponentNames.HOTELS,
    sourceView: UppViewNames.CREATE
  };

  lookupOptions?: LookupOptions;

  availablePosNames: string[] = [];
  availableMarketsNames: string[] = [];
  availableHotelChainsFamilies: string[] = [];
  availableHotelPropertyCodesFamilies: string[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly store: Store,
    private readonly validatorService: UppValidatorService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.validatorService.setComponentValidation(this.COMPONENT_NAME, VALIDATORS);
    this.availableCriteria = new HotelRuleAvailableCriteria();
    this.initializeSourcesForBadgesAutocompletes();
    this.hotelCriteria = this.initHotelCriteria();
    this.specialCriteria = [this.availableCriteria.COMMISSIONABLE, this.availableCriteria.SOURCE_TYPES];
    this.criteriaGroupOne = [this.availableCriteria.POS, this.availableCriteria.MARKETS];
    this.criteriaGroupTwo = [
      this.availableCriteria.HOTEL_CHAINS_FAMILIES,
      this.availableCriteria.HOTEL_PROPERTY_CODES_FAMILIES
    ];
    this.criteriaGroupThree = [
      this.availableCriteria.HOTEL_MASTER_CHAINS,
      this.availableCriteria.HOTEL_CHAINS,
      this.availableCriteria.HOTEL_PROPERTY_CODES,
      this.availableCriteria.SOURCE_TYPES
    ];
    this.criteriaGroupFour = [
      this.availableCriteria.RATE_FAMILIES,
      this.availableCriteria.HOTEL_RATE_CODES,
      this.availableCriteria.COMMISSIONABLE
    ];
  }

  ngOnChanges() {
    this.store.pipe(select(selectNavigation)).subscribe((lookupOptions) => {
      this.lookupOptions = lookupOptions;
    });
    this.initExcludeInclude(this.hotelDetails);
    if (this.lookupOptions !== undefined && this.lookupOptions.data) {
      this.initLookupData(this.lookupOptions);
      this.navigationService.clearLookupData();
    }
    this.initScreen();
    this.setupBadgeItems(this.hotelCriteria);
    this.initSelectableCriteriaButtonsAndElements(this.hotelDetails.hotelApplicability);
    if (this.hotelDetails.id) {
      this.posLookupOptions.sourceView = UppViewNames.MODIFY;
      this.marketLookupOptions.sourceView = UppViewNames.MODIFY;
      this.familyLookupOptions.sourceView = UppViewNames.MODIFY;
    }
    this.navigationService.enableNavigation();
  }

  ngAfterViewInit(): void {
    const sourceTypesCriteria = getCriteria(this.hotelCriteria, this.availableCriteria.SOURCE_TYPES);
    const comissionableCriteria = getCriteria(this.hotelCriteria, this.availableCriteria.COMMISSIONABLE);

    if (sourceTypesCriteria) {
      sourceTypesCriteria.customTemplate = this.sourceTypesTemplate;
    }

    if (comissionableCriteria) {
      comissionableCriteria.customTemplate = this.comissionableTemplate;
    }

    this.changeDetector.detectChanges();
  }

  initScreen() {
    this.createForm();
    this.hotelDetailsForm.patchValue(this.hotelDetails);
    this.updateCriteriaDisabledStatus(this.readonly);
  }

  initializeSourcesForBadgesAutocompletes() {
    this.store.pipe(select(selectAllAvailablePosRecords)).subscribe((result) => {
      if (result != null) {
        this.availablePosNames = result
          .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
          .filter((x) => x !== undefined);
      }
    });
    this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
      if (result != null) {
        this.availableMarketsNames = result
          .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
          .filter((x) => x !== undefined);
      }
    });
    this.store.pipe(select(selectAvailableFamiliesHotelChains)).subscribe((result) => {
      if (result != null) {
        this.availableHotelChainsFamilies = result.map((familyHotelChain) => familyHotelChain.name);
      }
    });
    this.store.pipe(select(selectAvailableFamiliesHotelPropertyCodes)).subscribe((result) => {
      if (result != null) {
        this.availableHotelPropertyCodesFamilies = result.map(
          (familyHotelPropertyCode) => familyHotelPropertyCode.name
        );
      }
    });
  }

  initLookupData(lookupOptions: LookupOptions) {
    this.hotelDetails = {
      ...this.hotelDetails,
      hotelApplicability: {
        ...this.hotelDetails.hotelApplicability
      }
    };

    const hotelApplicability = this.hotelDetails.hotelApplicability as any;

    if (lookupOptions.fieldName) {
      hotelApplicability[lookupOptions.fieldName] = lookupOptions.data;
    }
  }

  createForm() {
    if (this.hotelDetailsForm != null) {
      this.hotelDetailsForm.addControl(
        'organization',
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );
      this.hotelDetailsForm.addControl('id', this.formBuilder.control(''));
      this.hotelDetailsForm.addControl('version', this.formBuilder.control(''));
      this.hotelDetailsForm.addControl('active', this.formBuilder.control({ value: false, disabled: this.readonly }));
      this.hotelDetailsForm.addControl('action', this.formBuilder.control({ value: '', disabled: this.readonly }));
      this.hotelDetailsForm.addControl(
        'lightExclude',
        this.formBuilder.control({ value: false, disabled: this.readonly })
      );
      this.hotelDetailsForm.addControl(
        'name',
        this.formBuilder.control(
          { value: '', disabled: this.readonly },
          { validators: [Validators.maxLength(30), Validators.pattern(this.nameRegex)] }
        )
      );
      this.hotelDetailsForm.addControl('description', this.formBuilder.control('', Validators.maxLength(128)));
      this.hotelDetailsForm.addControl(
        'hotelApplicability',
        this.formBuilder.group({
          commissionable: this.formBuilder.control({ value: '', disabled: this.readonly }),
          sourceTypesGds: this.formBuilder.control({ value: false, disabled: this.readonly }),
          sourceTypesAggregator: this.formBuilder.control({ value: false, disabled: this.readonly })
        })
      );
    }
  }

  clearScreen() {
    this.hotelDetails = initialHotelRuleUi;

    this.hotelDetailsForm.get('name')?.setValue('');
    this.hotelDetailsForm.get('description')?.setValue('');
    this.hotelDetailsForm.get('active')?.setValue(true);
    this.hotelDetailsForm.get('action')?.setValue(EXCLUDE_ACTION);
    this.hotelDetailsForm.get('lightExclude')?.setValue(false);

    this.hotelDetailsForm.get('hotelApplicability')?.patchValue(this.initialHotelApplicability);

    this.isExludeActive = true;
    this.isIncludeActive = false;

    this.hotelCriteria = this.initHotelCriteria();
  }

  getNameValidationError(): string {
    if (this.hotelDetailsForm.get('name')?.valid || !this.hotelDetailsForm.get('name')?.errors) {
      return '';
    }
    if (this.hotelDetailsForm.get('name')?.errors?.maxlength) {
      const requiredLength = this.hotelDetailsForm.get('name')?.errors?.maxlength.requiredLength;
      return (
        $localize`:@@upp.validation.hotels.details.maximumCharacters:Maximum number of characters acceptable: ` +
        ' ' +
        requiredLength
      ); // NOSONAR
    }
    if (this.hotelDetailsForm.get('name')?.errors?.pattern) {
      return $localize`:@@upp.validation.hotels.details.typeOfCharacters:Only alphanumeric characters are acceptable`;
    }
    return '';
  }

  toggleCriteriaElements(name: string) {
    if (this.specialCriteria.includes(name)) {
      this.toggleOtherCriteriaFields(name);
    } else {
      this.hotelCriteria.forEach((criteria) => {
        if (criteria.name === name) {
          if (criteria.active) {
            // before going to disable we want to check the content of the given control:
            if (!this.checkContentToBeEmpty(this.hotelDetailsForm.get('hotelApplicability')?.get(name))) {
              return;
            }
          }
          criteria.active = !criteria.active;
        }
      });
    }
  }

  toggleOtherCriteriaFields(criteriaName: string) {
    this.hotelCriteria.forEach((criteria) => {
      if (criteria.name === criteriaName) {
        this.checkToUpdateCommissionableValue(criteriaName, criteria.active);
        if (criteria.active) {
          if (!this.checkOtherCritieriaElementsToBeFalsy(criteriaName)) {
            return;
          }
        }
        criteria.active = !criteria.active;
      }
    });
  }

  isCriteriaActive(name: string): boolean {
    let active = false;
    this.hotelCriteria.forEach((criteria) => {
      if (criteria.name === name) {
        active = criteria.active;
      }
    });
    return active;
  }

  setExcludeSelected() {
    this.isExludeActive = true;
    this.isIncludeActive = false;
    this.hotelDetailsForm.get('action')?.setValue('exclude');
  }

  setIncludeSelected() {
    this.isExludeActive = false;
    this.isIncludeActive = true;
    this.hotelDetailsForm.get('action')?.setValue('include');
  }

  isExcludeSelected(): boolean {
    return this.isExludeActive;
  }

  isIncludeSelected(): boolean {
    return this.isIncludeActive;
  }

  isRuleActivated() {
    return this.hotelDetailsForm.get('active')?.value === true;
  }

  getCriteriaSelectionText() {
    if (this.isIncludeActive === false && this.isExludeActive === false) {
      return '';
    }
    if (this.isExcludeSelected()) {
      return $localize`:@@upp.hotels.create.textExcludeCriteria:You are about to define the criteria for an exclusion`;
    }
    return $localize`:@@upp.hotels.create.textIncludeCriteria:You are about to define the criteria for an inclusion`;
  }

  getCriteriasForNames(names: string[]): SelectableCriteria[] {
    return this.hotelCriteria.filter((criteria) => names.includes(criteria.name));
  }

  updateCriteriaDisabledStatus(disable: boolean) {
    this.hotelCriteria.forEach((criteria) => (criteria.disabled = disable));
  }

  canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }

  canLookupFamily(): boolean {
    return this.canLookup(PERMISSION_VIEW_FAMILY);
  }

  canLookup(permisionName: string): boolean {
    if (this.readonly) {
      return false;
    }
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === permisionName) ?? false;
  }

  getHotelApplicabilityFormGroup(name: string): UntypedFormGroup {
    return this.hotelDetailsForm.get(name) as UntypedFormGroup;
  }

  private checkOtherCritieriaElementsToBeFalsy(criteriaName: string): boolean {
    if (this.availableCriteria.SOURCE_TYPES === criteriaName) {
      return (
        this.hotelDetailsForm.get('hotelApplicability.sourceTypesGds')?.value === false &&
        this.hotelDetailsForm.get('hotelApplicability.sourceTypesAggregator')?.value === false
      );
    }
    return true;
  }

  private checkToUpdateCommissionableValue(criteriaName: string, deactivation: boolean) {
    if (this.availableCriteria.COMMISSIONABLE === criteriaName) {
      const value = deactivation ? COMMISSIONABLE_ALL : COMMISSIONABLE_YES;
      this.hotelDetailsForm.get('hotelApplicability.commissionable')?.setValue(value);
    }
  }

  private checkContentToBeEmpty(control?: AbstractControl | null): boolean {
    if (!control) {
      return false;
    }
    if (control.value === undefined || control.value === '' || control.value.length === 0) {
      return true;
    }
    return false;
  }

  private initExcludeInclude(hotelRule: HotelRuleUi) {
    this.isIncludeActive = false;
    this.isExludeActive = false;
    if (hotelRule.action === undefined) {
      return;
    }
    if (hotelRule.action === EXCLUDE_ACTION) {
      this.isExludeActive = true;
    } else if (hotelRule.action === INCLUDE_ACTION) {
      this.isIncludeActive = true;
    }
  }

  private initHotelCriteria(): SelectableCriteriaWithParams[] {
    return [
      {
        name: this.availableCriteria.POS,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.pos.label:POS`,
        numberOfBadges: 100,
        badgeItems: [],
        lookupOptions: this.canLookupPos() ? this.posLookupOptions : undefined,
        possibleValues: this.availablePosNames,
        placeholder: $localize`:@@upp.global.criteria.pos.placeholder:POS name`,
        tooltip: $localize`:@@upp.global.criteria.pos.tooltip:Enter a POS name, max. 30 alpha numeric`,
        notFoundText: $localize`:@@upp.global.criteria.pos.notFoundText:No POS found`
      },
      {
        name: this.availableCriteria.MARKETS,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.market.label:Markets`,
        numberOfBadges: 100,
        badgeItems: [],
        lookupOptions: this.canLookupMarket() ? this.marketLookupOptions : undefined,
        possibleValues: this.availableMarketsNames,
        placeholder: $localize`:@@upp.global.criteria.market.placeholder:Market name`,
        tooltip: $localize`:@@upp.global.criteria.market.tooltip:Enter a market name, max. 30 alpha numeric`,
        notFoundText: $localize`:@@upp.global.criteria.market.notFoundText:No markets found`
      },
      {
        name: this.availableCriteria.HOTEL_CHAINS_FAMILIES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelChainsFamily.label:Hotel Chains Family`,
        numberOfBadges: 100,
        badgeItems: [],
        lookupOptions: this.canLookupFamily() ? this.familyLookupOptions : undefined,
        possibleValues: this.availableHotelChainsFamilies,
        placeholder: $localize`:@@upp.global.criteria.hotelChainsFamily.placeholder:Hotel chain family name`,
        tooltip: $localize`:@@upp.global.criteria.hotelChainsFamily.tooltip:Enter a hotel chain family name, max. 30 alpha numeric`,
        notFoundText: $localize`:@@upp.global.criteria.hotelChainsFamily.notFoundText:No hotel chain family found`
      },
      {
        name: this.availableCriteria.HOTEL_PROPERTY_CODES_FAMILIES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelPropertyCodesFamily.label:Hotel Property Codes Family`,
        numberOfBadges: 100,
        badgeItems: [],
        lookupOptions: this.canLookupFamily() ? this.familyLookupOptions : undefined,
        possibleValues: this.availableHotelPropertyCodesFamilies,
        placeholder: $localize`:@@upp.global.criteria.hotelPropertyCodesFamily.placeholder:Hotel property code family name`,
        tooltip: $localize`:@@upp.global.criteria.hotelPropertyCodesFamily.tooltip:Enter a hotel property code family name, max. 30 alpha numeric`,
        notFoundText: $localize`:@@upp.global.criteria.hotelPropertyCodesFamily.notFoundText:No hotel property code family found`
      },
      {
        name: this.availableCriteria.HOTEL_MASTER_CHAINS,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelMasterChains.label:Hotel Master Chains`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.hotelMasterChains.placeholder:Hotel master chain`,
        tooltip: $localize`:@@upp.global.criteria.hotelMasterChains.tooltip:Enter 2 character hotel master chain(s)`,
        notFoundText: $localize`:@@upp.global.criteria.hotelMasterChains.notFoundText:No hotel master chain found`
      },
      {
        name: this.availableCriteria.HOTEL_CHAINS,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelChains.label:Hotel Chains`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.hotelChains.placeholder:Hotel chain`,
        tooltip: $localize`:@@upp.global.criteria.hotelChains.tooltip:Enter 2 character hotel chain(s)`,
        notFoundText: $localize`:@@upp.global.criteria.hotelChains.notFoundText:No hotel chain found`
      },
      {
        name: this.availableCriteria.HOTEL_PROPERTY_CODES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelPropertyCodes.label:Hotel Property Codes`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.hotelPropertyCodes.placeholder:Hotel property code`,
        tooltip: $localize`:@@upp.global.criteria.hotelPropertyCodes.tooltip:Enter 8 character hotel property code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.hotelPropertyCodes.notFoundText:No hotel property code found`
      },
      {
        name: this.availableCriteria.SOURCE_TYPES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.hotels.details.sourceTypesLabel:Source Types`,
        customInputField: true
      },
      {
        name: this.availableCriteria.RATE_FAMILIES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelRateFamilies.label:Hotel Rate Family Codes`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.hotelRateFamilies.placeholder:Hotel rate family code`,
        tooltip: $localize`:@@upp.global.criteria.hotelRateFamilies.tooltip:Enter 3 character rate family code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.hotelRateFamilies.notFoundText:No hotel rate family found`
      },
      {
        name: this.availableCriteria.HOTEL_RATE_CODES,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.hotelRateCodes.label:Hotel Rate Codes`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.hotelRateCodes.placeholder:Hotel rate code`,
        tooltip: $localize`:@@upp.global.criteria.hotelRateCodes.tooltip:Enter 3 character rate code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.hotelRateCodes.notFoundText:No hotel rate code found`
      },
      {
        name: this.availableCriteria.COMMISSIONABLE,
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.hotels.details.commissionableLabel:Commissionable`,
        customInputField: true
      }
    ];
  }

  private initSelectableCriteriaButtonsAndElements(hotelApplicability?: HotelApplicabilityUi) {
    if (!hotelApplicability) {
      return;
    }
    this.resetSelectableCriteriaButtonsAndElements();

    const inputCriteria = this.hotelCriteria.filter((criteria) => !this.specialCriteria.includes(criteria.name));
    const valueKeys = Object.keys(hotelApplicability);
    const values = Object.values(hotelApplicability);
    this.activateCriteriaButtonsAndInputs(inputCriteria, valueKeys, values);

    this.activateOtherCriteriaButtonsAndControls(hotelApplicability);
  }

  private activateOtherCriteriaButtonsAndControls(hotelApplicability: HotelApplicabilityUi) {
    if (
      (hotelApplicability.commissionable !== undefined && hotelApplicability.commissionable === COMMISSIONABLE_YES) ||
      (hotelApplicability.commissionable !== undefined && hotelApplicability.commissionable === COMMISSIONABLE_NO)
    ) {
      this.hotelCriteria.filter((criteria) => criteria.name === this.availableCriteria.COMMISSIONABLE)[0].active = true;
    }
    if (
      (hotelApplicability.sourceTypesGds !== undefined && hotelApplicability.sourceTypesGds === true) ||
      (hotelApplicability.sourceTypesAggregator !== undefined && hotelApplicability.sourceTypesAggregator === true)
    ) {
      this.hotelCriteria.filter((criteria) => criteria.name === this.availableCriteria.SOURCE_TYPES)[0].active = true;
    }
  }

  private activateCriteriaButtonsAndInputs(criteriaList: SelectableCriteria[], keys: string[], values: any[]) {
    criteriaList.forEach((criteria) => {
      const index = keys.indexOf(criteria.name);
      if (index !== -1) {
        if (values[index] && values[index] !== '' && values[index].length > 0) {
          criteria.active = true;
        }
      }
    });
  }

  private resetSelectableCriteriaButtonsAndElements() {
    this.hotelCriteria.forEach((criteria) => (criteria.active = false));
  }

  private setupBadgeItems(criteria: SelectableCriteriaWithParams[]) {
    criteria.forEach((criterion) => {
      criterion.badgeItems = this.hotelDetails.hotelApplicability
        ? getPropertyByKey(this.hotelDetails.hotelApplicability, criterion.name)
        : [];
    });
  }
}
