import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import {
  MARKETS_ACTION,
  MarketsActions,
  SetMarketsListAction,
  FinishMarketsUpdateAction,
  FinishMarketCreationAction,
  DeleteMarketsSearchNotificationAction,
  DeleteMarketClearStoreAction,
  DeleteMarketFromListAction,
  SetAllAvailableMarketsListAction
} from './markets-actions';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { PosMarketService } from '../../service/pos-market.service';
import { PosMarketRecord, TYPE_ERROR } from '../../service/model';
import { LoginService } from '../../service/login.service';
import { ConfigurationService } from '../../service/configuration/configuration.service';

@Injectable()
export class MarketsEffects {
  searchMarkets: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(MARKETS_ACTION.SEARCH_MARKETS),
      mergeMap((action) => {
        const { request } = (action as MarketsActions).payload;
        return this.posMarketService.searchMarkets(request).pipe(
          map((response) => new SetMarketsListAction({ marketsList: response })),
          catchError((error) => of(new SetMarketsListAction({ marketsList: [this.createErrorRecord(error)] })))
        );
      })
    )
  );

  getAllAvailableMarkets: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(MARKETS_ACTION.GET_ALL_AVAILABLE_MARKETS),
      mergeMap((action) => {
        const partitionEnabled = this.configurationService.getParameter('UPP_PARTITIONS_A');

        const { request } = {
          request: {
            version: '2.0',
            market: {}
          }
        };

        request.market = partitionEnabled
          ? { partitionId: (action as MarketsActions).payload }
          : { organization: (action as MarketsActions).payload };

        return this.posMarketService.searchMarkets(request).pipe(
          switchMap((response) => [
            new DeleteMarketsSearchNotificationAction({}),
            new SetAllAvailableMarketsListAction({ marketsList: response })
          ]),
          catchError((error) =>
            of(new SetAllAvailableMarketsListAction({ marketsList: [this.createErrorRecord(error)] }))
          )
        );
      })
    )
  );

  updateMarkets: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(MARKETS_ACTION.UPDATE_MARKETS),
      mergeMap((action) => {
        const { request } = (action as MarketsActions).payload;
        return this.posMarketService.updateMarkets(request).pipe(
          map((record) => new FinishMarketsUpdateAction({ updatedRecord: record })),
          catchError((error) => of(new FinishMarketsUpdateAction({ updatedRecord: this.createErrorRecord(error) })))
        );
      })
    )
  );

  createMarkets: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(MARKETS_ACTION.CREATE_MARKET),
      mergeMap((action) => {
        const { request } = (action as MarketsActions).payload;
        return this.posMarketService.createMarket(request).pipe(
          map((record) => new FinishMarketCreationAction({ marketRecord: record })),
          catchError((error) => of(new FinishMarketCreationAction({ marketRecord: this.createErrorRecord(error) })))
        );
      })
    )
  );

  deleteMarkets: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(MARKETS_ACTION.DELETE_MARKETS),
      mergeMap((action) => {
        const { request } = (action as MarketsActions).payload;
        return this.posMarketService.deleteMarket(request).pipe(
          switchMap((deletedMarket) => [
            new DeleteMarketsSearchNotificationAction({}),
            new DeleteMarketClearStoreAction(request),
            new DeleteMarketFromListAction({ deletedRecord: deletedMarket })
          ]),
          catchError((error) => of(new DeleteMarketFromListAction({ deletedRecord: this.createErrorRecord(error) })))
        );
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly posMarketService: PosMarketService,
    private readonly loginService: LoginService,
    private readonly configurationService: ConfigurationService
  ) {}

  createErrorRecord(error: Error): PosMarketRecord {
    return {
      id: 'Error',
      statusType: TYPE_ERROR,
      statusMessage: [error.message],
      statusNotification: { error: [error.message] }
    };
  }
}
