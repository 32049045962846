import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormBuilder } from '@angular/forms';
import { PosMarketDetail } from '../../service/model';
import { UppComponent, UppComponentNames } from '../../service/model/upp-component';
import { PosMarketsDetails } from '../../util/pos-markets/pos-markets-details';
import { UserDetailsService } from '../../service/user-details.service';
import { getEmptyPosMarketDetail } from '../../service/model/pos/pos-market-detail';
import { PosMarketServiceElements, VALIDATORS } from '../../service/model/pos/pos-market-service-elements';
import { UppValidatorService } from '../../service/upp-validator.service';
import { SelectableCriteriaWithParams } from '../../base/search-criteria/selectable-criteria';

export class PosSelectableCriteria {
  readonly REGIONS = PosMarketServiceElements.REGIONS;
  readonly COUNTRIES = PosMarketServiceElements.COUNTRIES;
  readonly CITIES = PosMarketServiceElements.CITIES;
  readonly AIRPORTS = PosMarketServiceElements.AIRPORTS;
  readonly OFFICES = PosMarketServiceElements.OFFICE_IDS;
  readonly CORPORATE_CODES = PosMarketServiceElements.CORPORATE_CODES;
}

@Component({
  selector: 'ama-ng-upp-pos-details',
  templateUrl: './pos-details.component.html',
  styleUrls: ['./pos-details.component.scss']
})
export class PosDetailsComponent extends PosMarketsDetails implements OnInit, OnChanges {
  @Input() posDetailsForm!: UntypedFormGroup;
  @Input() posDetails: PosMarketDetail = getEmptyPosMarketDetail();
  @Input() readonly = true;
  @Input() parent!: UppComponent;

  readonly COMPONENT_NAME = UppComponentNames.POS;

  worldActivated = false;

  selectableCriteria: PosSelectableCriteria;

  regionPasteRegExp = /[,|;]/; // separator regex for ',' and ';'

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly validatorService: UppValidatorService
  ) {
    super();
    this.validatorService.setComponentValidation(this.COMPONENT_NAME, VALIDATORS);
    this.selectableCriteria = new PosSelectableCriteria();
    this.includeCriterias = this.initCriteriaList();
    this.excludeCriterias = this.initCriteriaList();
  }

  ngOnInit() {
    this.setupBadgeItems(this.includeCriterias, this.posDetails, false);
    this.setupBadgeItems(this.excludeCriterias, this.posDetails, true);
    this.setUpPossibleValues(this.includeCriterias);
    this.setUpPossibleValues(this.excludeCriterias);
  }

  ngOnChanges() {
    this.initSelectableCriteria();
    this.initScreen();
  }

  initSelectableCriteria() {
    if (!this.posDetails) {
      return;
    }
    this.resetSelectableCriteriaButtonsAndElements();

    // Init all criteria elements depending on the given pos details input:
    if (this.posDetails.include) {
      this.initSelectableCriteriaForInclude(this.posDetails.include, this.readonly, this.worldActivated);
    }

    if (this.posDetails.exclude) {
      this.initSelectableCriteriaForExclude(this.posDetails.exclude);
    }
  }

  initScreen() {
    this.createForm();
    this.posDetailsForm.patchValue(this.posDetails);
    this.initWorldFlag();
    // Update criteria list with latest readonly/world settings:
    this.updateCriteriaDisabledStatus(this.includeCriterias, this.readonly || this.worldActivated);
    this.updateCriteriaDisabledStatus(this.excludeCriterias, this.readonly);
  }

  createForm() {
    if (this.posDetailsForm != null) {
      this.posDetailsForm.addControl(
        PosMarketServiceElements.ORGANIZATION,
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );
      this.posDetailsForm.addControl(PosMarketServiceElements.ID, this.formBuilder.control(''));
      this.posDetailsForm.addControl(PosMarketServiceElements.VERSION, this.formBuilder.control(''));
      this.posDetailsForm.addControl(
        PosMarketServiceElements.NAME,
        this.formBuilder.control(
          {
            value: '',
            disabled: this.parent === UppComponent.POS_MODIFY
          },
          {
            validators: this.validatorService.getValidatorsForControl(
              UppComponentNames.POS,
              PosMarketServiceElements.NAME,
              false
            )
          }
        )
      );
      this.posDetailsForm.addControl(
        PosMarketServiceElements.DESCRIPTION,
        this.formBuilder.control(
          '',
          this.validatorService.getValidatorsForControl(
            UppComponentNames.POS,
            PosMarketServiceElements.DESCRIPTION,
            false
          )
        )
      );
      this.posDetailsForm.addControl(
        PosMarketServiceElements.INCLUDE,
        this.formBuilder.group({
          world: this.formBuilder.control({ value: '', disabled: this.readonly })
        })
      );
      this.posDetailsForm.addControl(PosMarketServiceElements.EXCLUDE, this.formBuilder.group({}));
    }
  }

  initWorldFlag() {
    if (this.posDetails.include && this.posDetails.include.world === true) {
      this.worldActivated = true;
    } else {
      this.worldActivated = false;
    }
  }

  getCountryCodes(exclude = false): string[] {
    if (this.loadResourceValues()) {
      return this.countryCodes;
    }
    if (exclude) {
      return this.posDetails.exclude ? this.posDetails.exclude.countries ?? [] : [];
    }
    return this.posDetails.include ? this.posDetails.include.countries ?? [] : [];
  }

  getRegionCodes(exclude = false): string[] {
    if (this.loadResourceValues()) {
      return this.regionDescriptions;
    }
    if (exclude) {
      return this.posDetails.exclude ? this.posDetails.exclude.regions ?? [] : [];
    }
    return this.posDetails.include ? this.posDetails.include.regions ?? [] : [];
  }

  loadResourceValues(): boolean {
    return this.parent === UppComponent.POS_CREATE || this.parent === UppComponent.POS_MODIFY;
  }

  restrictInput(): boolean {
    return this.parent !== UppComponent.POS_CREATE;
  }

  setWorldActivated() {
    this.worldActivated = !this.worldActivated;
    this.updateIncludeSection(this.worldActivated);
  }

  clearScreen() {
    if (this.parent !== UppComponent.POS_MODIFY) {
      this.posDetailsForm.get(PosMarketServiceElements.NAME)?.setValue('');
    }
    this.initCriteriaValues(false);
    this.posDetailsForm.get(PosMarketServiceElements.DESCRIPTION)?.setValue('');

    this.worldActivated = false;
    this.posDetailsForm.get(PosMarketServiceElements.INCLUDE)?.enable();

    const includeElementControl = this.posDetailsForm.get(PosMarketServiceElements.INCLUDE);
    const excludeElementControl = this.posDetailsForm.get(PosMarketServiceElements.EXCLUDE);

    if (includeElementControl) {
      this.resetVisibleCriteria(false, includeElementControl);
    }

    if (excludeElementControl) {
      this.resetVisibleCriteria(false, excludeElementControl);
    }

    this.includeCriterias = this.initCriteriaList();
    this.excludeCriterias = this.initCriteriaList();
    this.setUpPossibleValues(this.includeCriterias);
    this.setUpPossibleValues(this.excludeCriterias);
  }

  activateIncludeCriteria(name: string) {
    super.activateIncludeCriteria(name, this.posDetailsForm);
  }

  activateExcludeCriteria(name: string) {
    super.activateExcludeCriteria(name, this.posDetailsForm);
  }

  getNameValidationError(): string {
    if (
      this.posDetailsForm.get(PosMarketServiceElements.NAME)?.valid ||
      !this.posDetailsForm.get(PosMarketServiceElements.NAME)?.errors
    ) {
      return '';
    }
    if (this.posDetailsForm.get(PosMarketServiceElements.NAME)?.errors?.maxlength) {
      const requiredLength = this.posDetailsForm.get(PosMarketServiceElements.NAME)?.errors?.maxlength.requiredLength;
      return (
        $localize`:@@upp.validation.pos.details.maximumCharacters:Maximum number of characters acceptable: ` +
        ' ' +
        requiredLength
      ); // NOSONAR
    }
    if (this.posDetailsForm.get(PosMarketServiceElements.NAME)?.errors?.pattern) {
      return $localize`:@@upp.validation.pos.details.typeOfCharacters:Only alphanumeric characters are acceptable`;
    }
    return '';
  }

  getFormGroup(name: string): UntypedFormGroup {
    return this.posDetailsForm.get(name) as UntypedFormGroup;
  }

  private updateIncludeSection(clearValues: boolean) {
    if (clearValues) {
      // reset the pure posDetails value:
      this.initCriteriaValues(true);

      const includeElementControl = this.posDetailsForm.get(PosMarketServiceElements.INCLUDE);

      // reset the form with all impacted controls:
      if (includeElementControl) {
        this.resetVisibleCriteria(true, includeElementControl);
      }
    }
    this.updateIncludeCriteriaStatus(clearValues);
  }

  private initCriteriaList(): SelectableCriteriaWithParams[] {
    return [
      {
        name: this.selectableCriteria.REGIONS,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.region.label:Regions`,
        numberOfBadges: 20,
        badgeItems: [],
        customPasteRegExp: this.regionPasteRegExp,
        placeholder: $localize`:@@upp.global.criteria.region.placeholder:Region code`,
        tooltip: $localize`:@@upp.global.criteria.region.tooltip:Select or enter region code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.region.notFoundText:No regions found`
      },
      {
        name: this.selectableCriteria.COUNTRIES,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.country.label:Countries`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.country.placeholder:Country code`,
        tooltip: $localize`:@@upp.global.criteria.country.tooltip:Select or enter 2 letter IATA country code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.country.notFoundText:No countries found`
      },
      {
        name: this.selectableCriteria.CITIES,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.city.label:Cities`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.city.placeholder:IATA city code`,
        tooltip: $localize`:@@upp.global.criteria.city.tooltip:Enter 3 letter IATA city code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.city.notFoundText:No cities found`
      },
      {
        name: this.selectableCriteria.AIRPORTS,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.airport.label:Airports`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.airport.placeholder:IATA airport code`,
        tooltip: $localize`:@@upp.global.criteria.airport.tooltip:Enter 3 letter IATA airport code(s)`,
        notFoundText: $localize`:@@upp.global.criteria.airport.notFoundText:No airports found`
      },
      {
        name: this.selectableCriteria.OFFICES,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.office.label:Offices`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.office.placeholder:Office ID`,
        tooltip: $localize`:@@upp.global.criteria.office.tooltip:Enter office id(s), you can use * when any value is accepted for 1 character`,
        notFoundText: $localize`:@@upp.global.criteria.office.notFoundText:No offices found`
      },
      {
        name: this.selectableCriteria.CORPORATE_CODES,
        active: false,
        disabled: this.readonly || this.worldActivated,
        title: $localize`:@@upp.global.criteria.corporateCode.label:Corporate codes`,
        numberOfBadges: 100,
        badgeItems: [],
        placeholder: $localize`:@@upp.global.criteria.corporateCode.placeholder:Corporate code & Qualifier`,
        tooltip: $localize`:@@upp.global.criteria.corporateCode.tooltip:Enter a corporate code & qualifier, 3 alpha numeric`,
        notFoundText: $localize`:@@upp.global.criteria.corporateCode.notFoundText:No corporate code & qualifier found`
      }
    ];
  }

  private initCriteriaValues(includeOnly: boolean) {
    const updatedPosDetails: PosMarketDetail = getEmptyPosMarketDetail();
    updatedPosDetails.id = this.posDetails.id;
    updatedPosDetails.name = this.posDetails.name;
    updatedPosDetails.organization = this.posDetails.organization;
    updatedPosDetails.description = this.posDetails.description;

    if (this.posDetails.exclude && includeOnly) {
      updatedPosDetails.exclude = this.posDetails.exclude;
    }

    this.posDetails = updatedPosDetails;
  }

  private setUpPossibleValues(criteria: SelectableCriteriaWithParams[]) {
    const regionsCriteria = criteria.find((c) => c.name === this.selectableCriteria.REGIONS);
    const countriesCriteria = criteria.find((c) => c.name === this.selectableCriteria.COUNTRIES);

    if (regionsCriteria) {
      regionsCriteria.possibleValues = this.getRegionCodes();
    }

    if (countriesCriteria) {
      countriesCriteria.possibleValues = this.getCountryCodes();
    }
  }
}
