import {
  COUNTRY_CODE_VALIDATION,
  EIGHTS_SYMBOLS_VALIDATION,
  MAX_LENGTH_VALIDATION,
  NAMES_VALIDATION,
  OFFICE_ID_VALIDATION,
  PATTERN_VALIDATION,
  REQUIRED,
  THREE_LETTER_CODES_VALIDATION,
  oneFlightRange,
  MIN_LENGTH_VALIDATION
} from './validators';

const ONE_FLIGHT_RANGE_REGEXP = new RegExp(`^${oneFlightRange}$`);

const getCountryCodeFormatValidationMessages = () => ({
  [COUNTRY_CODE_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.countrycode.numberOfCharacters:Maximum/Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.countrycode.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.countryCodeFormat:Only letters are allowed. Valid number of letters: 2`
});

const getOfficeIdFormatValidationMessages = () => ({
  [OFFICE_ID_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.officeid.numberOfCharacters:Maximum/Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.officeid.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.officeIdFormat:Acceptable format for Office IDs: [A-Z]{3}[A-Z0-9]{2}[0-9]{1}[0-9A-Z]{3}`
});

const getCorporateCodeValidationMessages = () => ({
  [THREE_LETTER_CODES_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.corporatecode.numberOfCharacters:Maximum/Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.corporatecode.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.corporateCodeFormat:Acceptable format for Corporate codes and Qualifiers: [0-9A-Z]{2}[0-9]`
});

const getThreeLettersValidationMessages = () => ({
  [THREE_LETTER_CODES_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.threeletters.numberOfCharacters:Maximum/Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.threeletters.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.threeLetterCodeFormat:Only letters are allowed. Valid number of letters: 3`
});

const getThreeAlphaNumericValidationMessages = () => ({
  [THREE_LETTER_CODES_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.threealpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.threealpha.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.threeAlphaNumericFormat:Only letters and digits are allowed. Valid number of characters in total: 3`
});

const getEightAlphaNumericValidationMessages = () => ({
  [EIGHTS_SYMBOLS_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.eightalpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.eightalpha.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.eightAlphaNumericFormat:Only letters and digits are allowed. Valid number of characters in total: 8`
});

const getTwoAlphaNumericValidationMessages = () => ({
  [COUNTRY_CODE_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.twoalpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.twoalpha.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.twoAlphaNumericFormat:Only letters and digits are allowed. Valid number of characters in total: 2`
});

const getTwoAlphaNumericRequiredValidationMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.required:Field is required`,
  [COUNTRY_CODE_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.twoalpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.twoalpha.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.twoAlphaNumericFormat:Only letters and digits are allowed. Valid number of characters in total: 2`
});

const getNamesValidationMessages = () => ({
  [NAMES_VALIDATION]: () =>
    $localize`:@@upp.validation.maximumCharacters:Maximum number of characters acceptable: ` + 30,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.name.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () => $localize`:@@upp.validation.typeOfCharacters:Only alphanumeric characters are acceptable`
});

const getNamesWithSpacesValidationMessages = () => ({
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.maximumCharacters:Maximum number of characters acceptable: ` + params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.flights.rule.nameWithSpaces.pattern:Only alphanumeric characters and spaces are acceptable`
});

const getNamesRequiredValidationMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.required:Field is required`,
  [NAMES_VALIDATION]: () =>
    $localize`:@@upp.validation.maximumCharacters:Maximum number of characters acceptable: ` + 30,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.name.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () => $localize`:@@upp.validation.typeOfCharacters:Only alphanumeric characters are acceptable`
});

const getTenCarriersValidationMessages = () => ({
  [COUNTRY_CODE_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.maximumNumberOfEntries:Maximum number of airline codes acceptable: ` +
    params.requiredLength,
  [PATTERN_VALIDATION]: () =>
    $localize`:@@upp.validation.twoAlphaNumericFormat:Only letters and digits are allowed. Valid number of characters in total: 2`
});

const getFlightRangesRequiredValidationMessages = () => ({
  [PATTERN_VALIDATION]: (params: any) => {
    const invalidFlightRanges = params.actualValue.filter(
      (flightRange: any) => !ONE_FLIGHT_RANGE_REGEXP.test(flightRange.trim())
    );
    return (
      $localize`:@@upp.validation.flightRangesFormat:Flight no. or range ` +
      invalidFlightRanges.join(', ') +
      ` not valid`
    );
  }
});

const getRequiredValidationMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.required:Field is required`
});

const getSingleRequiredValidationMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.required:Field is required`,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.name.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength
});

const getAllDefaultMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.required:Field is required`,
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.name.maximumNumberOfEntries:Maximum number of entries acceptable: ` +
    params.requiredLength,
  [COUNTRY_CODE_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [NAMES_VALIDATION]: () =>
    $localize`:@@upp.validation.maximumCharacters:Maximum number of characters acceptable: ` + 30,
  [EIGHTS_SYMBOLS_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.eightalpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [THREE_LETTER_CODES_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.threealpha.numberOfCharacters: Maximum / Minimum number of characters acceptable: ` +
    params.requiredValue,
  [OFFICE_ID_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.officeid.numberOfCharacters:Maximum/Minimum number of characters acceptable: ` +
    params.requiredValue
});

const getSectionValidationMessages = () => ({
  [REQUIRED]: () => $localize`:@@upp.validation.flights.content.section.required:Section is required`
});

const getMaxLengthStringValidationMessages = () => ({
  [MAX_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.maximumCharacters:Maximum number of characters acceptable: ` + params.requiredLength
});

const getMinStringValidationMessages = () => ({
  [MIN_LENGTH_VALIDATION]: (params: any) =>
    $localize`:@@upp.validation.minimumCharacters:Minimum number of characters acceptable: ` + params.requiredLength
});

export const DEFAULT_ERROR = 'Invalid format';
export const NAMES = getNamesValidationMessages();
export const NAME_WITH_SPACES_VALIDATION_MESSAGES = getNamesWithSpacesValidationMessages();
export const NAMES_REQUIRED = getNamesRequiredValidationMessages();
export const TWO_SYMBOLS = getTwoAlphaNumericValidationMessages();
export const TWO_SYMBOLS_REQUIRED = getTwoAlphaNumericRequiredValidationMessages();
export const EIGHT_SYMBOLS = getEightAlphaNumericValidationMessages();
export const THREE_SYMBOLS = getThreeAlphaNumericValidationMessages();
export const THREE_LETTERS = getThreeLettersValidationMessages();
export const CORPORATE_CODES = getCorporateCodeValidationMessages();
export const OFFICEIDS = getOfficeIdFormatValidationMessages();
export const COUNTRY_CODES = getCountryCodeFormatValidationMessages();
export const TEN_CARRIERS_MSG = getTenCarriersValidationMessages();
export const FLIGHT_RANGES_VALIDATION_MSG = getFlightRangesRequiredValidationMessages();
export const REQUIRED_MSG = getRequiredValidationMessages();
export const SINGLE_REQUIRED_MSG = getSingleRequiredValidationMessages();
export const ALL_DEFAULT_MESSAGES = getAllDefaultMessages();
export const SECTION_VALIDATION_MESSAGES = getSectionValidationMessages();
export const MAX_LENGTH_STRING_VALIDATION_MESSAGES = getMaxLengthStringValidationMessages();
export const MIN_LENGTH_STRING_VALIDATION_MESSAGES = getMinStringValidationMessages();
