<div [formGroup]="formGroup!">
  <div class="mb-5 badgeDisplay">
    <label for="{{ this.getFormElementId() }}" class="form-label">
      {{ formElementLabel }}
      <span *ngIf="mandatoryMode" class="mandatory">*</span>
    </label>
    <div class="d-flex">
      <ng-select
        *ngIf="!readonlyMode"
        dfManageNavSelect
        [id]="getFormElementId()"
        [formControlName]="formElementName"
        [items]="availableElements.length > 0 ? availableElements : getBadgeItems()"
        [multiple]="true"
        [placeholder]="placeholder ?? ''"
        bindLabel="name"
        bindValue="value"
        [selectOnTab]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [addTag]="disableNewValues ? false : addValue"
        [ngbTooltip]="tooltipText"
        class="flex-fill"
        [ngClass]="{
          'is-invalid': !formIsValid && (!showErrorAfterTouch || formElement?.touched)
        }"
        notFoundText="{{ notFoundText }}"
        (paste)="onPaste($event)"
        (remove)="onRemove($event)"
        [searchFn]="searchFn"
        [inputAttrs]="{ 'aria-labelledby': getFormElementId(), autocomplete: 'off' }"
      >
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items ? items.slice(0, numberOfBadges) : []; let i = index">
            <div class="badge rounded-pill df-closable bg-light">
              <span class="ng-value-label">{{ item.value }}</span>
              <button type="button" attr.aria-label="Remove {{ item }}" [dfManageBadgeEventsSelect]="[clear, item]">
                <span aria-hidden="true" class="fa-times"></span>
              </button>
              <span *ngIf="i + 1 < items.length" aria-hidden="true"></span>
            </div>
          </div>
          <div class="ng-value" *ngIf="(items?.length ?? 0) > numberOfBadges">
            <span class="ng-value-label">+{{ items.length - numberOfBadges }} others...</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
          <div class="form-check">
            <input
              id="{{ this.getFormElementId() }}-item-{{ index }}"
              name="{{ this.getFormElementId() }}-{{ index }}"
              type="checkbox"
              class="form-check-input"
              [ngModel]="item$.selected"
              [ngModelOptions]="{ standalone: true }"
            />
            <label
              class="form-check-label"
              for="{{ this.getFormElementId() }}-item-{{ index }}"
              (click)="$event.preventDefault()"
              [dfOptionHighlight]="search"
              >{{ item.value }}</label
            >
          </div>
        </ng-template>
      </ng-select>
      <div *ngIf="this.lookup">
        <button
          *ngIf="this.lookup"
          i18n="@@upp.global.button.lookup"
          type="button"
          class="btn btn-primary mb-2 mb-md-0 me-2 ms-2 actionButton"
          (click)="openLookup()"
        >
          Lookup
        </button>
      </div>
    </div>
    <div *ngIf="readonlyMode" class="disabledField"></div>
    <ng-select
      *ngIf="readonlyMode"
      [id]="this.getFormElementId()"
      [readonly]="true"
      class="df-ng-select-readonly"
      [formControlName]="formElementName"
      [items]="availableElements.length > 0 ? availableElements : getBadgeItems()"
      [multiple]="true"
      [placeholder]="placeholder ?? ''"
      bindLabel="name"
      bindValue="value"
      [selectOnTab]="false"
      [closeOnSelect]="false"
      [clearable]="false"
      [searchable]="false"
      notFoundText="{{ notFoundText }}"
      [inputAttrs]="{ 'aria-labelledby': getFormElementId() }"
    >
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items ? items.slice(0, numberOfBadges) : []; let i = index">
          <div class="badge rounded-pill bg-light">
            <span class="ng-value-label">{{ item.value }}</span>
            <span *ngIf="i + 1 < items.length" aria-hidden="true"></span>
          </div>
        </div>
        <div class="ng-value" *ngIf="items.length > numberOfBadges">
          <span class="ng-value-label">+{{ items.length - numberOfBadges }} others...</span>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
        <div class="form-check">
          <div [dfOptionHighlight]="search">{{ item.value }}</div>
        </div>
      </ng-template>
    </ng-select>
    <div *ngIf="!formIsValid && !readonlyMode">
      <ama-ng-upp-validation-error
        [control]="formElement"
        [parent]="parent"
        [name]="formElementName"
        [showErrorAfterTouch]="showErrorAfterTouch"
        data-test="rule-validation-message"
      >
      </ama-ng-upp-validation-error>
    </div>
  </div>
</div>
