import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseResponse } from '../base/model/base-response';
import { UppViewNames } from './model';
import { UppNotification, UppNotificationStatus } from '../model/notification';
import { setNotificationData } from '../store/families/air/air-families-action';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private readonly store: Store) {}

  handleResponse(response: BaseResponse, viewMode: UppViewNames): void {
    this.store.dispatch(setNotificationData({ notification: { warning: [], error: [], success: [] }, viewMode }));

    if (response.status.state === UppNotificationStatus.ERROR && (response.status.errors?.length ?? 0) > 0) {
      if (response.status.errors) {
        this.handleNotification(
          response.status.errors
            .map((error) => error.message)
            .filter((message): message is string => message !== undefined),
          UppNotificationStatus.ERROR,
          viewMode
        );
      }
    } else if (
      response.status.state === UppNotificationStatus.WARNING &&
      response.status.warnings &&
      (response.status.warnings.length ?? 0) > 0
    ) {
      this.handleNotification(
        response.status.warnings
          .map((warning) => warning.message)
          .filter((message): message is string => message !== undefined),
        UppNotificationStatus.WARNING,
        viewMode
      );
    }
  }

  private handleNotification(
    messages: string[],
    type: UppNotificationStatus.ERROR | UppNotificationStatus.WARNING,
    viewMode: UppViewNames
  ): void {
    this.store.dispatch(setNotificationData({ notification: { [type]: messages } as UppNotification, viewMode }));
  }
}
