import { AbstractControl, ValidationErrors } from '@angular/forms';

export const canExcludeCarriersValidator =
  (includedCarriersControl: AbstractControl) =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !control.value?.length) {
      if (
        typeof includedCarriersControl.value === 'object' &&
        includedCarriersControl?.value?.some((code: string) => code === 'YY')
      ) {
        return { excludeYYValidator: false };
      } else {
        return null;
      }
    }

    if (!includedCarriersControl.value) {
      return { canExcludeCarriers: false };
    }

    if (typeof includedCarriersControl.value === 'string') {
      return includedCarriersControl.value === 'YY' ? null : { canExcludeCarriers: false };
    }

    return includedCarriersControl.value.some((code: string) => code === 'YY') ? null : { canExcludeCarriers: false };
  };
