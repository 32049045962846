import { Action } from '@ngrx/store';
import { LoggedInUser } from '../../service/model';
import { ConfigurationOutput } from '@seco/core';

export const CORE_ACTION = {
  UPDATE_CLP_CONFIG: '[Core Action] CLP config updated',
  USER_LOGGED_IN: '[Core Action] User is logged in',
  USER_PERMISSION_FAILED: '[Core Action] User is not authorized',
  UPDATE_USER_PARTITIONS: '[Core Action] User partitions retrieved',
  UPDATE_SELECTED_PARTITION: '[Core Action] selected partition updated',
  CONFIGURATION_RETRIEVED: '[Core Action] Configuration retrieved'
};

export class UpdateClpConfig implements Action {
  readonly type = CORE_ACTION.UPDATE_CLP_CONFIG;
  constructor(public payload: any) {}
}

export class UserLoggedIn implements Action {
  readonly type = CORE_ACTION.USER_LOGGED_IN;
  constructor(public payload?: LoggedInUser) {}
}

export class UserPermissionFailed implements Action {
  readonly type = CORE_ACTION.USER_PERMISSION_FAILED;
  constructor(public payload?: string) {}
}

export class UpdateUserPartitions implements Action {
  readonly type = CORE_ACTION.UPDATE_USER_PARTITIONS;
  constructor(public payload: string[]) {}
}

export class UpdateSelectedPartition implements Action {
  readonly type = CORE_ACTION.UPDATE_SELECTED_PARTITION;
  constructor(public payload: string) {}
}

export class ConfigurationRetrieved implements Action {
  readonly type = CORE_ACTION.CONFIGURATION_RETRIEVED;
  constructor(public payload?: ConfigurationOutput) {}
}

export type CoreActions =
  | UpdateClpConfig
  | UserLoggedIn
  | UserPermissionFailed
  | ConfigurationRetrieved;
