import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';
import { HotelRuleUi, initialHotelRuleUi } from '../model';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  selectHotelRuleDisplayValue,
  selectHotelRuleDisplayActivated,
  selectHotelRuleDisplayNotification
} from '../../store/hotel/hotels-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppComponent, UppViewNames } from '../../service/model/upp-component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  ActivateHotelRuleDisplayAction,
  CopyHotelAction,
  DeleteHotelRuleCreateNotificationAction,
  DeleteHotelRuleDisplayNotificationAction,
  SetHotelModifyValueAction,
  StartHotelModificationAction
} from '../../store/hotel/hotels-action';
import { PERMISSION_MANAGE_HOTEL } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-hotels-display',
  templateUrl: './hotels-display.component.html',
  styleUrls: ['./hotels-display.component.scss']
})
export class HotelsDisplayComponent implements OnInit, OnDestroy {
  hotelForm!: UntypedFormGroup;
  hotelRule: HotelRuleUi = initialHotelRuleUi;
  notification$: Observable<UppNotification | undefined>;
  displayActivated = false;

  readonly = true;

  currentViewMode = UppViewNames.DISPLAY;
  uppComponent = UppComponent.HOTELS_DISPLAY;
  mainMessages: NotificationMessages;

  hotelsV2: boolean | null = null;

  subscription: Subscription = new Subscription();

  constructor(
    private readonly navigationService: NavigationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    this.subscription?.add(
      this.store
        .pipe(select(selectHotelRuleDisplayActivated))
        .subscribe((activated) => (this.displayActivated = activated))
    );
    this.notification$ = this.store.pipe(select(selectHotelRuleDisplayNotification));

    this.subscription?.add(
      this.configurationService.getParameter$(FeatureFlags.hotelsV2).subscribe((hotelsV2) => {
        this.hotelsV2 = hotelsV2 ?? false;
      })
    );

    this.mainMessages = {
      error: $localize`:@@upp.hotels.display.mainErrorText:The following errors for hotel rule appeared:`,
      warning: $localize`:@@upp.hotels.display.mainWarningText:The following warning for hotel rule appeared:`,
      success: $localize`:@@upp.hotels.display.mainSuccessText:The hotel rule was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('Hotel rule display');
    this.navigationService.activateHotels();
    if (!this.displayActivated) {
      this.router.navigate(['hotels/search/']);
    } else {
      this.loadHotelRule();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  createForm() {
    this.hotelForm = this.formBuilder.group({});
    this.store.pipe(select(selectHotelRuleDisplayValue)).subscribe((value) => (this.hotelRule = value));
  }

  loadHotelRule() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activateHotelRuleWithId(params.get('id')));
  }

  activateHotelRuleWithId(paramId: string | null) {
    if (!paramId) {
      throw new Error('Missing parameter (id).');
    }

    this.store.dispatch(new ActivateHotelRuleDisplayAction({ id: paramId }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelRuleDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && !!this.hotelRule && !!this.hotelRule.name;
  }

  modifyHotel() {
    this.store.dispatch(new StartHotelModificationAction({ id: undefined }));
    this.store.dispatch(new SetHotelModifyValueAction({ value: this.hotelRule }));
    this.router.navigate(['hotels/modify/'], { queryParams: { sourceView: 'display' } });
  }

  copyHotel() {
    this.store.dispatch(new CopyHotelAction({ id: this.hotelRule?.id }));
    this.store.dispatch(new DeleteHotelRuleCreateNotificationAction({}));
    this.router.navigate(['hotels/create/']);
  }

  hasManageAccess(): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === PERMISSION_MANAGE_HOTEL) ?? false;
  }
}
