import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ReefGatewayService,
  ReefAuthToken,
  ReefLoginAccessTokenOutput,
  ReefRESTService,
  ReefConfigurationService,
  ConfigurationOutput,
  HttpConfigService
} from '@seco/core';
import { ClpService, ClpLoginResponse } from '@seco/login';
import {
  UpdateClpConfig,
  UserLoggedIn,
  UserPermissionFailed,
  UpdateUserPartitions,
  ConfigurationRetrieved
} from '../store/core/actions';
import { Router } from '@angular/router';
import { InitPosStateAction } from '../store/pos/pos-actions';
import { InitMarketsStateAction } from '../store/markets/markets-actions';
import { InitHotelRuleStateAction } from '../store/hotel/hotels-action';
import { InitFlightRuleStateAction } from '../store/flight/flights-action';
import { InitFamilyStateAction } from '../store/families/families-action';
import { UserPermissionsService } from './user-permissions.service';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { getInitialFlightState } from '../store/flight/flights-state';

@Injectable()
export class LoginService {
  private readonly UPP_MODULE_NAME = 'upp';

  constructor(
    // NOSONAR
    private readonly zone: NgZone,
    private readonly store: Store<any>,
    private readonly httpConfigService: HttpConfigService,
    private readonly router: Router,
    private readonly gwService: ReefGatewayService,
    private readonly clpService: ClpService,
    private readonly permissionsService: UserPermissionsService,
    private readonly reefRESTService: ReefRESTService,
    private readonly cfService: ReefConfigurationService
  ) {}

  getABRPartitions() {
    const partitionStatus = { partition: false, error: false, code: null as any };
    return new Promise((resolve) => {
      this.reefRESTService.get('partitions/UPP').subscribe(
        (res) => {
          if (res.partitions) {
            partitionStatus.partition = true;
            this.store.dispatch(new UpdateUserPartitions(res.partitions.sort((a: any, b: any) => a.localeCompare(b))));
            resolve(partitionStatus);
          }
        },
        (errReply) => {
          if (errReply.error.errors[0].code !== 511) {
            partitionStatus.error = true;
            partitionStatus.code = errReply.error.errors[0].code;
          }
          resolve(partitionStatus);
        }
      );
    });
  }

  /**
   * Get token and nonce by calling UM action.
   */
  getClpConfig(): Observable<any> {
    return this.gwService.getAuthenticationToken().pipe(
      tap((res: ReefAuthToken) => {
        this.store.dispatch(
          new UpdateClpConfig({
            token: res.configToken,
            nonce: res.nonce,
            baseUrl: res.clpAuthURL,
            loginParameters: {
              displayDutyCode: false,
              officeNeeded: true,
              defaultOffice: false
            }
          })
        );
      })
    );
  }

  /**
   * Login by access token
   */
  loginByAccessToken(clpLoginResponse: ClpLoginResponse) {
    const loginData = {
      accessToken: clpLoginResponse.accessToken,
      idToken: clpLoginResponse.idToken,
      nonce: clpLoginResponse.nonce
    };

    this.gwService.loginInByAccessToken(loginData).subscribe((res: ReefLoginAccessTokenOutput) => {
      // have to call inside zonejs to trigger change detection, CLP code is run by default outside zonejs
      this.zone.run(() => {
        // retrieve the configuration
        this.cfService.getConfiguration(this.UPP_MODULE_NAME).subscribe((output: ConfigurationOutput) => {
          this.store.dispatch(new ConfigurationRetrieved(output));
        });

        const httpConfig = this.httpConfigService.getConfig();

        if (!httpConfig) {
          throw new Error('httpConfig is undefined.');
        }

        httpConfig.jSessionId = res.JsessionId;

        const permissions = this.permissionsService.extractUppPermissions(loginData.idToken);
        if (this.permissionsService.checkLoginPermission(permissions)) {
          this.store.dispatch(
            new UserLoggedIn({
              userAlias: res.USER_ALIAS,
              userId: res.USER_ID as string,
              officeId: res.LOGIN_AREA_ID as string,
              organization: res.ORGANIZATION as string,
              permissions: this.permissionsService.extractUppPermissions(loginData.idToken)
            })
          );
          this.store.dispatch(new InitPosStateAction({}));
          this.store.dispatch(new InitMarketsStateAction({}));
          this.store.dispatch(new InitHotelRuleStateAction({}));
          this.store.dispatch(new InitFamilyStateAction({}));

          if (this.router.url === '/') {
            this.router.navigate(['dashboard']);
          }
        } else {
          this.store.dispatch(
            new UserPermissionFailed(
              $localize`:@@upp.global.messages.noLoginPermission:You are not authorised to access this application.`
            )
          );
          this.permissionFailedLogout();
        }
      });
    });
  }

  logOutUser() {
    this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
    this.gatewayLogout();
  }

  clpLogoutSuccess(): void {
    console.log('clp logout success');
  }

  permissionFailedLogout() {
    this.clpService.logout(JSON.stringify({ postLogoutRedirectUri: this.router.url }), this.clpLogoutSuccess);
  }

  gatewayLogout(): void {
    // Call to reef gateway
    this.gwService
      .logout()
      .pipe(
        catchError(() => {
          this.permissionFailedLogout();
          window.location.reload();
          return of(null);
        })
      )
      .subscribe(() => {
        this.zone.run(() => {
          console.log('logout triggered successfully');
          this.store.dispatch(new InitPosStateAction({}));
          this.store.dispatch(new InitMarketsStateAction({}));
          this.store.dispatch(new InitHotelRuleStateAction({}));
          this.store.dispatch(new InitFamilyStateAction({}));
          this.store.dispatch(new InitFlightRuleStateAction(getInitialFlightState()));
          // Page reload requested to call the configToken action to have a new nonce
          window.location.reload();
        });
      });
  }
}
