<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" i18n-message="@@upp.global.messages.loading" message="Loading...">
  </ama-loading-alert>
</div>

<div class="mb-2">
  <form (ngSubmit)="searchClicked()" [formGroup]="searchCriteriaForm" id="flightSearchCriteriaForm">
    <div class="row">
      <div class="col text-end mb-2">
        <button
          (click)="clearClicked()"
          [disabled]="sendingStatus"
          class="btn btn-outline-primary btn-sm"
          i18n="@@upp.global.button.clear"
          id="clearDisabled"
          type="button"
        >
          Clear
        </button>
      </div>
    </div>

    <div formGroupName="rule">
      <div class="mb-4">
        <label class="form-label" for="name" i18n="@@upp.flights.display.nameLabel">Name </label>
        <input
          class="form-control"
          formControlName="name"
          i18n-ngbTooltip="@@upp.flights.common.flightRuleTooltip"
          i18n-placeholder="@@upp.flights.common.flightNamePlaceholder"
          id="name"
          ngbTooltip="Enter the Flight Rule name with letters, numbers or spaces (up to 30 characters)."
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
          placeholder="Flight rule name"
          type="text"
        />

        <div
          *ngIf="this.searchCriteriaForm.get('rule')?.get('name')?.errors; let errors"
          class="invalid mt-1"
          data-test="rule-validation"
        >
          <div
            *ngIf="errors.maxlength"
            i18n="@@upp.validation.search.maximumCharactersExceeded"
            data-test="rule-validation-message"
          >
            Maximum number of characters acceptable: {{ errors.maxlength.requiredLength }}
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="applicability">
      <div class="row mt-1 mb-5">
        <div class="col">
          <label for="pointOfSaleName" i18n="@@upp.global.criteria.point.of.sale.label" class="d-block mb-3">
            Point of sale
          </label>
          <ama-ng-upp-lookup-select
            [items]="availablePosNames"
            formControlName="pointOfSaleNames"
            [lookupOptions]="posLookupOptions"
            i18n-notFoundText="@@upp.global.criteria.pos.notFoundText"
            notFoundText="No POS found"
            id="pointOfSaleName"
          >
          </ama-ng-upp-lookup-select>
        </div>
      </div>

      <div class="row mb-5">
        <div class="d-flex align-items-start">
          <div class="d-flex flex-column w-50">
            <label for="firstMarketName" i18n="@@upp.global.criteria.firstMarket.label" class="d-block mb-3">
              First market
            </label>
            <ama-ng-upp-lookup-select
              [items]="availableMarketsNames"
              formControlName="firstMarketNames"
              [lookupOptions]="firstMarketLookupOptions"
              i18n-notFoundText="@@upp.global.criteria.markets.notFoundText"
              notFoundText="No markets found"
              id="firstMarketName"
            >
            </ama-ng-upp-lookup-select>
          </div>
          <div class="d-flex flex-column w-50">
            <label for="secondMarketName" i18n="@@upp.global.criteria.secondMarket.label" class="d-block mb-3">
              Second market
            </label>
            <ama-ng-upp-lookup-select
              [items]="availableMarketsNames"
              formControlName="secondMarketNames"
              [lookupOptions]="secondMarketLookupOptions"
              i18n-notFoundText="@@upp.global.criteria.markets.notFoundText"
              notFoundText="No markets found"
              id="secondMarketName"
            >
            </ama-ng-upp-lookup-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <hr class="separator" />
          <div class="d-flex flex-wrap">
            <label for="airContentType" class="label-criteria">
              <ng-container i18n="@@upp.flights.details.airContentTypeLabel">Air content type</ng-container>
            </label>
            <div class="form-check form-check-inline">
              <ama-ng-upp-checkbox-multiselect
                data-test="air-content-type-select"
                id="airContentType"
                formControlName="contentTypes"
                [items]="airContentTypeItems"
              ></ama-ng-upp-checkbox-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div formGroupName="rule">
      <div class="row">
        <div class="col">
          <hr class="separator" />
          <div role="radiogroup" class="d-flex flex-wrap" aria-labelledby="ruleStatusSearchFlights">
            <div class="d-flex flex-wrap">
              <span i18n="@@upp.flights.search.ruleStatusLabel" class="label-criteria" id="ruleStatusSearchFlights">
                Rule status
              </span>
              <div class="form-check">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ruleStatusBoth"
                    value="all"
                    name="ruleStatus"
                    formControlName="ruleStatus"
                  />
                  <label i18n="@@upp.global.labels.both" class="form-check-label" for="ruleStatusBoth"> Both </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ruleStatusActive"
                    value="active"
                    name="ruleStatus"
                    formControlName="ruleStatus"
                  />
                  <label i18n="@@upp.flights.search.table.active" class="form-check-label" for="ruleStatusActive">
                    Active
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ruleStatusInactive"
                    value="inactive"
                    name="ruleStatus"
                    formControlName="ruleStatus"
                  />
                  <label i18n="@@upp.flights.search.table.inactive" class="form-check-label" for="ruleStatusInactive">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-5">
      <button
        [disabled]="sendingStatus || !searchCriteriaForm.valid"
        class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
        i18n="@@upp.global.button.search"
        id="search"
        type="submit"
      >
        Search
      </button>
    </div>
  </form>
</div>
