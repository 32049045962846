import { AbstractControl } from '@angular/forms';

export const isIncludeExcludeFilled = (control: AbstractControl | null, names: string[]) => {
  if (!control) {
    return false;
  }

  let result = false;
  names.forEach((name) => {
    const val = control.get(name)?.value ?? '';

    if (val && val.length > 0) {
      result = true;
    }
  });
  return result;
};
