import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetCreateHotelRuleValueAction, SetHotelUpdateSuccessValuesAction } from '../store/hotel/hotels-action';
import { ReefRESTService } from '@seco/core';
import {
  buildHotelRuleListFromSearchResponse,
  buildHotelRuleFromDeleteResponse,
  buildHotelFromCreateOrUpateResponse
} from './handler/hotel-response-handler';
import {
  HotelRuleCreateRequest,
  HotelRuleSearchRequest,
  HotelRuleDeleteRequest,
  HotelUpdateRequest
} from '../hotels/model/hotel-request';
import { HotelRuleCreateUpdateResponse } from '../hotels/model/hotel-response';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HotelRuleUi, initialHotelRuleUi } from '../hotels/model/hotel-rule-ui';
import { Router } from '@angular/router';
import { TYPE_SUCCESS } from './model/pos';
import { ConfigurationService } from './configuration/configuration.service';
import { FeatureFlags } from '../core/util/resources';

@Injectable({
  providedIn: 'root'
})
export class HotelService {
  private readonly PROXY_ENDPOINT = 'api/v2/usap';
  private readonly hotelBasePath = '/hotel';

  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly reefRestService: ReefRESTService,
    private readonly configurationService: ConfigurationService
  ) {}

  createHotelRule(request: HotelRuleCreateRequest): Observable<HotelRuleUi> {
    return this.configurationService
      .getParameter$(FeatureFlags.hotelsV2)
      .pipe(
        switchMap((hotelsV2) =>
          this.reefRestService
            .post('/hotel/rule', request)
            .pipe(map((response) => this.buildResultForHotelRuleCreation(response, hotelsV2)))
        )
      );
  }

  searchHotelRules(request: HotelRuleSearchRequest): Observable<HotelRuleUi[]> {
    return this.configurationService
      .getParameter$(FeatureFlags.hotelsV2)
      .pipe(
        switchMap((hotelsV2) =>
          this.reefRestService
            .post('/hotel/rule/list', request)
            .pipe(map((response) => buildHotelRuleListFromSearchResponse(response, hotelsV2)))
        )
      );
  }

  deleteHotelRule(request: HotelRuleDeleteRequest): Observable<HotelRuleUi> {
    const ruleId = request.rule.id;
    const requestForDelete = this.buildBackendRequestForDelete(request);
    return this.reefRestService
      .delete(`/hotel/rule/${ruleId}`, requestForDelete)
      .pipe(map((response) => buildHotelRuleFromDeleteResponse(response, request)));
  }

  updateHotelRule(request: HotelUpdateRequest): Observable<HotelRuleUi> {
    const hotelRuleToUpdate = request.rule.id;
    return this.configurationService
      .getParameter$(FeatureFlags.hotelsV2)
      .pipe(
        switchMap((hotelsV2) =>
          this.reefRestService
            .put(`/hotel/rule/${hotelRuleToUpdate}`, request)
            .pipe(map((response) => this.buildResultForHotelRuleUpdate(response, hotelsV2)))
        )
      );
  }

  buildBackendRequestForDelete(request: HotelRuleDeleteRequest): HotelRuleDeleteRequest {
    return {
      version: request.version,
      rule: {
        version: request.rule.version
      }
    };
  }

  buildResultForHotelRuleCreation(response: HotelRuleCreateUpdateResponse, hotelsV2: boolean | null): HotelRuleUi {
    const rule = buildHotelFromCreateOrUpateResponse(response, hotelsV2);
    if (rule.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetCreateHotelRuleValueAction({ value: initialHotelRuleUi }));
    }
    return rule;
  }

  private buildResultForHotelRuleUpdate(result: HotelRuleCreateUpdateResponse, hotelsV2: boolean | null): HotelRuleUi {
    const hotelRule = buildHotelFromCreateOrUpateResponse(result, hotelsV2);
    if (hotelRule.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForHotelModify(hotelRule);
    }
    return hotelRule;
  }

  private triggerSuccessActionForHotelModify(hotelRule: HotelRuleUi) {
    this.store.dispatch(new SetHotelUpdateSuccessValuesAction({ updatedHotelRule: hotelRule }));
    this.router.navigate(['hotels/display/']);
  }
}
