<div class="d-flex flex-wrap">
  <div *ngFor="let item of items; let last = last" class="form-check" [ngClass]="{ 'me-4': !last }">
    <label class="form-check-label" [for]="item.label">
      <input
        class="form-check-input"
        type="checkbox"
        [disabled]="disabled"
        [checked]="item.selected"
        [id]="item.label"
        (change)="toggleSelected(item)"
      />
      {{ item.label }}
    </label>
  </div>
</div>
