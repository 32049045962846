import { DestroyRef } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getFormControlByPath, getTopParent } from '../../util/form-utils';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { convertTimeFormatToMinutes, isTimeFormat } from '../../util/time-format-utils';

export const moreThanValidator = (
  relatedControl: AbstractControl | string,
  destroyRef: DestroyRef,
  inclusive = false
): ValidatorFn => {
  let initialized = false;
  let leftControl: AbstractControl | null = null;
  return (control: AbstractControl) => {
    if (!initialized && control.parent) {
      if (typeof relatedControl === 'string' || relatedControl instanceof String) {
        leftControl = getFormControlByPath(getTopParent(control), relatedControl as string);
      } else {
        leftControl = relatedControl;
      }
      // Force validation of this control when related control's value changes
      // prettier-ignore
      const subscription: Subscription | undefined = leftControl?.valueChanges
        .pipe(distinctUntilChanged((a, b) => a === b)).subscribe(() => {
          control.updateValueAndValidity();
        });
      destroyRef.onDestroy(() => {
        subscription?.unsubscribe();
      });
      initialized = true;
    }

    const referenceValue = isTimeFormat(leftControl?.value)
      ? convertTimeFormatToMinutes(leftControl?.value)
      : +leftControl?.value;
    const value = isTimeFormat(control?.value) ? convertTimeFormatToMinutes(control?.value) : control.value;
    if (referenceValue !== null && value !== null) {
      const isMoreThan = inclusive ? value >= referenceValue : value > referenceValue;
      if (!isMoreThan) {
        return {
          moreThan: {
            value,
            referenceValue
          }
        };
      }
    }

    return null;
  };
};
