import { initialFamilyState, FamiliesState, initialFamilyStatus, FamilyStatus } from './families-state';
import { FamiliesActions, FAMILIES_ACTION } from './families-action';
import { UppNotification } from '../../model/notification';
import { TYPE_ERROR, TYPE_WARNING } from '../../service/model';
import { FamilyUi, HOTEL_CHAINS_TYPE, initialFamilyUi } from '../../families/model/family-ui';
import {
  addCreatedRecordToList,
  deleteRecordFromList,
  shouldNotificationCleared,
  updateRecordList
} from '../helpers/reducer-helper';
import { CORE_ACTION } from '../core/actions';

export const familiesReducer = (state = initialFamilyState, action: FamiliesActions): FamiliesState => {
  if (FamilyReducerMethods.has(action.type)) {
    const reducerMethod = FamilyReducerMethods.get(action.type);

    if (reducerMethod) {
      return reducerMethod(state, action);
    }
  }
  return state;
};

export const findFamilyWithId = (families: FamilyUi[], familyId: string): FamilyUi | undefined => {
  if (!families || families.length === 0) {
    return undefined;
  }
  return families.find((family) => family.id === familyId);
};

const getFamilyModifyValue = (state: FamiliesState, familyId: string): FamilyUi => {
  let modificationValue = state.family.modify.value;
  if (familyId) {
    const recordToModify = findFamilyWithId(state.families, familyId);
    if (recordToModify) {
      modificationValue = recordToModify;
    }
  }
  return modificationValue;
};

const getUpdatedFamily = (createFamily: FamilyUi | undefined, lastFamily: FamilyUi) => {
  if (createFamily && lastFamily && createFamily.id === lastFamily.id) {
    return lastFamily;
  } else {
    return createFamily;
  }
};

export const updateUpdatedFamilies = (families: FamilyUi[], familyToUpdate: FamilyUi): FamilyUi[] => {
  if (!families || families.length === 0) {
    return [familyToUpdate];
  }

  return families.map((obj) => [familyToUpdate].find((record) => record.id === obj.id) || obj);
};

const getFamilyValueAfterDelete = (id: string, family: FamilyUi): FamilyUi | undefined => {
  if (!family) {
    return;
  }
  if (family.id === id) {
    return initialFamilyUi;
  }
  return family;
};

const checkFamilyIdActivation = (id: string, familyStatus: FamilyStatus): boolean => {
  if (!familyStatus) {
    return false;
  }

  if (familyStatus.active && familyStatus.value && familyStatus.value.id === id) {
    return false;
  } else {
    return familyStatus.active ?? false;
  }
};

const getUpdatedFamilyAfterDelete = (id: string, family?: FamilyUi): FamilyUi | undefined => {
  if (!family) {
    return;
  }
  if (family.id === id) {
    return undefined;
  }

  return family;
};

const deleteFamilyFromList = (id: string, families: FamilyUi[]): FamilyUi[] => {
  if (!id || !families) {
    return [];
  }
  return families.filter((family) => family.id !== id);
};

const getFamiliesStateForCreate = (state: FamiliesState): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    create: {
      sending: true,
      value: state.family.create.value
    }
  },
  notification: {
    ...state.notification,
    create: {}
  }
});

const getInitialFamilyState = (): FamiliesState => initialFamilyState;

const getFamiliesStateForSearch = (state: FamiliesState): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    search: {
      ...state.family.search,
      sending: true
    }
  }
});

const getFamiliesStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    delete: {
      ...state.family.delete,
      sending: true
    }
  }
});

const getFamiliesStateForUpdate = (state: FamiliesState): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    modify: {
      sending: true,
      value: state.family.modify.value,
      active: state.family.modify.active
    }
  },
  notification: {
    ...state.notification,
    modify: {}
  }
});

const getCreateFamiliesStateForSet = (state: FamiliesState, action: FamiliesActions): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    create: {
      ...state.family.create,
      value: action.payload.familyUi
    }
  }
});

const getCreateFamiliesStateForFinish = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const newState = {
    ...state,
    family: {
      ...state.family,
      create: {
        sending: false,
        value: state.family.create.value,
        updatedValue: action.payload.familyUi
      }
    },
    notification: {
      ...state.notification,
      create: action.payload.familyUi.statusNotification
    }
  };

  const statusNotificationForFamilyCreate: UppNotification = action.payload.familyUi.statusNotification;

  if (action.payload.familyUi.hotelFamilyType === HOTEL_CHAINS_TYPE) {
    newState.availableFamiliesHotelChains = statusNotificationForFamilyCreate.success
      ? addCreatedRecordToList(state.availableFamiliesHotelChains, action.payload.familyUi)
      : state.availableFamiliesHotelChains;
  } else {
    newState.availableFamiliesHotelPropertyCodes = statusNotificationForFamilyCreate.success
      ? addCreatedRecordToList(state.availableFamiliesHotelPropertyCodes, action.payload.familyUi)
      : state.availableFamiliesHotelPropertyCodes;
  }

  return newState;
};

const getCreateNotificationStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  notification: {
    ...state.notification,
    create: {}
  }
});

const getDisplayForActivate = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  let displayValue = state.family.display.value;
  if (action.payload.id) {
    const familyToDisplay = findFamilyWithId(state.families, action.payload.id);
    if (familyToDisplay) {
      displayValue = familyToDisplay;
    }
  }
  return {
    ...state,
    family: {
      ...state.family,
      display: {
        ...state.family.display,
        value: displayValue,
        active: true
      }
    }
  };
};

const getDisplayNotificationStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  notification: {
    ...state.notification,
    display: {}
  }
});

const getCreateDisplayStateForActivate = (state: FamiliesState): FamiliesState => {
  let family = state.family.display.value;
  if (state.family.create.updatedValue) {
    family = state.family.create.updatedValue;
  }
  return {
    ...state,
    family: {
      ...state.family,
      display: {
        sending: false,
        value: family,
        active: true
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const getListStateForSet = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const newState = {
    ...state,
    family: {
      ...state.family,
      search: {
        ...state.family.search,
        sending: false
      }
    },
    notification: {
      ...state.notification
    }
  };

  switch (action.type) {
    case FAMILIES_ACTION.SET_FAMILY_LIST:
      newState.notification.search = action.payload.familyUis[0].statusNotification;
      newState[FamiliesListFields.FAMILIES] = action.payload.familyUis;
      break;
    case FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS:
      newState[FamiliesListFields.AVAILABLE_FAMILIES_HOTEL_CHAINS] = isErrorOrWarning(
        action.payload.familyUis[0].statusType
      )
        ? []
        : action.payload.familyUis;
      break;
    case FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_PROPERTY_CODES:
      newState[FamiliesListFields.AVAILABLE_FAMILIES_HOTEL_PROPERTY_CODES] = isErrorOrWarning(
        action.payload.familyUis[0].statusType
      )
        ? []
        : action.payload.familyUis;
      break;
    case FAMILIES_ACTION.SET_LOOKUP_HOTEL_PROPERTY_CODES_FAMILIES_LIST:
      newState.notification.lookup = action.payload.familyUis[0].statusNotification;
      newState[FamiliesListFields.LOKUP_HOTEL_PROPERTY_CODES_FAMILIES] = action.payload.familyUis;
      break;
    case FAMILIES_ACTION.SET_LOOKUP_HOTEL_CHAINS_FAMILIES_LIST:
      newState.notification.lookup = action.payload.familyUis[0].statusNotification;
      newState[FamiliesListFields.LOOKUP_HOTEL_CHAINS_FAMILIES] = action.payload.familyUis;
      break;
  }

  return newState;
};

const getListStateForClear = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const newState = {
    ...state,
    family: {
      ...state.family,
      search: {
        ...state.family.search,
        sending: false
      },
      display: initialFamilyStatus
    }
  };

  let field: FamiliesListFields | undefined = undefined;

  switch (action.type) {
    case FAMILIES_ACTION.CLEAR_FAMILY_LIST:
      field = FamiliesListFields.FAMILIES;
      break;
    case FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_CHAINS_FAMILY_LIST:
      field = FamiliesListFields.LOOKUP_HOTEL_CHAINS_FAMILIES;
      break;
    case FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_PROPERTY_CODES_FAMILY_LIST:
      field = FamiliesListFields.LOKUP_HOTEL_PROPERTY_CODES_FAMILIES;
      break;
  }

  if (!field) {
    throw new Error('field is null or undefined');
  }
  newState[field] = [];

  return newState;
};

const getSearchNotificationStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  notification: {
    ...state.notification,
    search: {}
  }
});

const getLookupNotificationStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  notification: {
    ...state.notification,
    lookup: {}
  }
});

const getSearchValueStateForSet = (state: FamiliesState, action: FamiliesActions): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    search: {
      sending: state.family.search.sending,
      value: action.payload.familySearchCriteria
    }
  }
});

const getClearStateHotelChainForDelete = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const clearFamilyId = action.payload.hotelChainsFamily.id;
  return getClearStateForDelete(state, clearFamilyId);
};

const getClearStateHotelCodesForDelete = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const clearFamilyId = action.payload.hotelPropertyCodesFamily.id;
  return getClearStateForDelete(state, clearFamilyId);
};

const getClearStateForDelete = (state: FamiliesState, clearFamilyId: string): FamiliesState => {
  const currentValueOfDeletedFamilyDisplay = getFamilyValueAfterDelete(clearFamilyId, state.family.display.value);
  const currentValueOfDeletedFamilyModify = getFamilyValueAfterDelete(clearFamilyId, state.family.modify.value);
  const updatedValueOfDeletedFamily = getUpdatedFamilyAfterDelete(clearFamilyId, state.family.create.updatedValue);

  if (!currentValueOfDeletedFamilyDisplay) {
    throw new Error('currentValueOfDeletedRecordDisplay is null or undefined');
  }

  if (!currentValueOfDeletedFamilyModify) {
    throw new Error('currentValueOfDeletedRecordModify is null or undefined');
  }

  return {
    ...state,
    family: {
      ...state.family,
      display: {
        ...state.family.display,
        value: currentValueOfDeletedFamilyDisplay,
        active: checkFamilyIdActivation(clearFamilyId, state.family.display)
      },
      create: {
        ...state.family.create,
        updatedValue: updatedValueOfDeletedFamily
      },
      modify: {
        ...state.family.modify,
        value: currentValueOfDeletedFamilyModify,
        active: checkFamilyIdActivation(clearFamilyId, state.family.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearFamilyId) ? {} : state.notification?.create
    }
  };
};

const getListStateForDelete = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  let familiesValue = state.families;
  const statusNotification: UppNotification = action.payload.familyUi.statusNotification;
  if (statusNotification.success) {
    familiesValue = deleteFamilyFromList(action.payload.familyUi.id, state.families);
  }

  const newState = {
    ...state,
    family: {
      ...state.family,
      delete: {
        ...state.family.delete,
        sending: false
      }
    },
    families: familiesValue,
    notification: {
      ...state.notification,
      search: statusNotification.success ? {} : statusNotification
    }
  };

  if (action.payload.familyUi.hotelFamilyType === HOTEL_CHAINS_TYPE) {
    newState.availableFamiliesHotelChains = statusNotification.success
      ? deleteRecordFromList(action.payload.familyUi.id, state?.availableFamiliesHotelChains)
      : state?.availableFamiliesHotelChains;
  } else {
    newState.availableFamiliesHotelPropertyCodes = statusNotification.success
      ? deleteRecordFromList(action.payload.familyUi.id, state?.availableFamiliesHotelPropertyCodes)
      : state?.availableFamiliesHotelPropertyCodes;
  }

  return newState;
};

const getModifyValueStateForSet = (state: FamiliesState, action: FamiliesActions): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    modify: {
      ...state.family.modify,
      value: action.payload.familyUi
    }
  }
});

const getModificationStateForStart = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const modificationValue = getFamilyModifyValue(state, action.payload.id);
  return {
    ...state,
    family: {
      ...state.family,
      modify: {
        ...state.family.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.family.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const getModificationStateForCancel = (state: FamiliesState): FamiliesState => ({
  ...state,
  family: {
    ...state.family,
    modify: {
      sending: state.family.modify.sending,
      value: initialFamilyUi,
      active: false
    }
  },
  notification: {
    ...state.notification,
    modify: {},
    display: {}
  }
});

const getUpdateSuccessStateForSet = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  let updatedDisplayValue = state.family.display.value;
  if (action.payload.familyUi) {
    updatedDisplayValue = action.payload.familyUi;
  }
  const updatedFamilies = updateUpdatedFamilies(state.families, action.payload.familyUi);
  return {
    ...state,
    family: {
      ...state.family,
      display: {
        ...state.family.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.family.modify.sending,
        value: initialFamilyUi,
        active: false
      }
    },
    families: updatedFamilies
  };
};

const getUpdateStateForFinish = (state: FamiliesState, action: FamiliesActions): FamiliesState => {
  const newState = {
    ...state,
    family: {
      ...state.family,
      modify: {
        ...state.family.modify,
        sending: false
      },
      create: {
        ...state.family.create,
        updatedValue: getUpdatedFamily(state.family.create.updatedValue, action.payload.updatedFamilyUi)
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedFamilyUi.statusNotification.warning,
        error: action.payload.updatedFamilyUi.statusNotification.error
      },
      display: {
        success: action.payload.updatedFamilyUi.statusNotification.success
      }
    }
  };

  const statusNotificationForFamilyUpdate: UppNotification = action.payload.updatedFamilyUi.statusNotification;

  if (action.payload.updatedFamilyUi.hotelFamilyType === HOTEL_CHAINS_TYPE) {
    newState.availableFamiliesHotelChains = statusNotificationForFamilyUpdate.success
      ? updateRecordList(state.availableFamiliesHotelChains, action.payload.updatedFamilyUi)
      : state.availableFamiliesHotelChains;
  } else {
    newState.availableFamiliesHotelPropertyCodes = statusNotificationForFamilyUpdate.success
      ? updateRecordList(state.availableFamiliesHotelPropertyCodes, action.payload.updatedFamilyUi)
      : state.availableFamiliesHotelPropertyCodes;
  }

  return newState;
};

const isErrorOrWarning = (statusType: string): boolean => statusType === TYPE_WARNING || statusType === TYPE_ERROR;

const getModifyNotificationStateForDelete = (state: FamiliesState): FamiliesState => ({
  ...state,
  notification: {
    ...state.notification,
    modify: {}
  }
});

const makeFamiliesSelection = (state: FamiliesState, action: FamiliesActions): FamiliesState => ({
  ...state,
  selectedFamiliesNames: action.payload
});

const clearFamiliesSelection = (state: FamiliesState): FamiliesState => ({
  ...state,
  selectedFamiliesNames: new Set<string>()
});

enum FamiliesListFields {
  FAMILIES = 'families',
  AVAILABLE_FAMILIES_HOTEL_CHAINS = 'availableFamiliesHotelChains',
  AVAILABLE_FAMILIES_HOTEL_PROPERTY_CODES = 'availableFamiliesHotelPropertyCodes',
  LOOKUP_HOTEL_CHAINS_FAMILIES = 'lookupHotelChainsFamilies',
  LOKUP_HOTEL_PROPERTY_CODES_FAMILIES = 'lookupHotelPropertyCodesFamilies'
}

export const FamilyReducerMethods: Map<string, (state: FamiliesState, action: FamiliesActions) => FamiliesState> =
  new Map<string, (state: FamiliesState, action: FamiliesActions) => FamiliesState>([
    [FAMILIES_ACTION.INIT_FAMILY_STATE, getInitialFamilyState],
    [CORE_ACTION.UPDATE_SELECTED_PARTITION, getInitialFamilyState],
    [FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CHAINS, getFamiliesStateForCreate],
    [FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CODES, getFamiliesStateForCreate],
    [FAMILIES_ACTION.SET_CREATE_FAMILY_VALUE, getCreateFamiliesStateForSet],
    [FAMILIES_ACTION.FINISH_FAMILY_CREATION, getCreateFamiliesStateForFinish],
    [FAMILIES_ACTION.DELETE_FAMILY_CREATE_NOTIFICATION, getCreateNotificationStateForDelete],
    [FAMILIES_ACTION.ACTIVATE_FAMILY_DISPLAY, getDisplayForActivate],
    [FAMILIES_ACTION.DELETE_FAMILY_DISPLAY_NOTIFICATION, getDisplayNotificationStateForDelete],
    [FAMILIES_ACTION.ACTIVATE_CREATED_FAMILY_DISPLAY, getCreateDisplayStateForActivate],
    [FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CHAINS, getFamiliesStateForSearch],
    [FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CODES, getFamiliesStateForSearch],
    [FAMILIES_ACTION.SET_FAMILY_LIST, getListStateForSet],
    [FAMILIES_ACTION.SET_LOOKUP_HOTEL_CHAINS_FAMILIES_LIST, getListStateForSet],
    [FAMILIES_ACTION.SET_LOOKUP_HOTEL_PROPERTY_CODES_FAMILIES_LIST, getListStateForSet],
    [FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS, getListStateForSet],
    [FAMILIES_ACTION.SET_ALL_AVAILABLE_FAMILY_HOTEL_PROPERTY_CODES, getListStateForSet],
    [FAMILIES_ACTION.CLEAR_FAMILY_LIST, getListStateForClear],
    [FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_CHAINS_FAMILY_LIST, getListStateForClear],
    [FAMILIES_ACTION.CLEAR_LOOKUP_HOTEL_PROPERTY_CODES_FAMILY_LIST, getListStateForClear],
    [FAMILIES_ACTION.DELETE_FAMILY_SEARCH_NOTIFICATION, getSearchNotificationStateForDelete],
    [FAMILIES_ACTION.DELETE_FAMILY_LOOKUP_NOTIFICATION, getLookupNotificationStateForDelete],
    [FAMILIES_ACTION.SET_FAMILY_SEARCH_VALUE, getSearchValueStateForSet],
    [FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CHAINS, getFamiliesStateForDelete],
    [FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CODES, getFamiliesStateForDelete],
    [FAMILIES_ACTION.DELETE_FAMILY_CHAINS_CLEAR_STORE, getClearStateHotelChainForDelete],
    [FAMILIES_ACTION.DELETE_FAMILY_CODES_CLEAR_STORE, getClearStateHotelCodesForDelete],
    [FAMILIES_ACTION.DELETE_FAMILY_FROM_LIST, getListStateForDelete],
    [FAMILIES_ACTION.SET_FAMILY_MODIFY_VALUE, getModifyValueStateForSet],
    [FAMILIES_ACTION.START_FAMILY_MODIFICATION, getModificationStateForStart],
    [FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CHAINS, getFamiliesStateForUpdate],
    [FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CODES, getFamiliesStateForUpdate],
    [FAMILIES_ACTION.CANCEL_FAMILY_MODIFICATION, getModificationStateForCancel],
    [FAMILIES_ACTION.SET_FAMILY_UPDATE_SUCCESS_VALUES, getUpdateSuccessStateForSet],
    [FAMILIES_ACTION.FINISH_FAMILY_UPDATE, getUpdateStateForFinish],
    [FAMILIES_ACTION.DELETE_FAMILY_MODIFY_NOTIFICATION, getModifyNotificationStateForDelete],
    [FAMILIES_ACTION.MAKE_FAMILIES_SELECTION, makeFamiliesSelection],
    [FAMILIES_ACTION.CLEAR_FAMILIES_SELECTION, clearFamiliesSelection]
  ]);
