import { PosMarketRecord } from '../../service/model/pos/pos-market-record';
import { PosMarketDetail } from '../../service/model/pos/pos-market-detail';
import { PosState } from '../pos/pos-state';
import { BaseUi } from '../../base/model/base-ui';

export const deleteRecordFromList = <Type extends BaseUi>(id: string, recordList?: Type[]): Type[] => {
  if (!id || !recordList) {
    return [];
  }
  return recordList.filter((record) => record.id !== id);
};

export const updateRecordList = <Type extends BaseUi>(records: Type[] | undefined, recordToUpdate: Type): Type[] => {
  if (!records || records.length === 0) {
    return [recordToUpdate];
  }

  return records.map((obj) => [recordToUpdate].find((record) => record.id === obj.id) || obj);
};

export const addCreatedRecordToList = <Type extends BaseUi>(records: Type[] | undefined, newRecord: Type): Type[] => {
  if (!records) {
    return [newRecord];
  }

  return [...records, newRecord];
};

export const findRecordWithId = (
  recordList: PosMarketRecord[] | undefined,
  recordId: string
): PosMarketRecord | undefined => {
  if (!recordList || recordList.length === 0) {
    return undefined;
  }
  return recordList.find((record) => record.id === recordId);
};

export const getRecordValueAfterDelete = (
  id: string,
  recordDetail: PosMarketDetail | undefined
): PosMarketDetail | undefined => {
  if (!recordDetail) {
    return;
  }
  if (recordDetail.id === id) {
    return { organization: '', name: '' };
  }
  return recordDetail;
};

export const getUpdatedRecordValueAfterDelete = (
  id: string,
  record: PosMarketRecord | undefined
): PosMarketRecord | undefined => {
  if (!record) {
    return;
  }
  if (record.posMarketDetail && record.posMarketDetail.id === id) {
    return undefined;
  }

  return record;
};

export const getUpdatedRecord = (
  createUpdatedRecord: PosMarketRecord | undefined,
  lastUpdatedRecord: PosMarketRecord | undefined
) => {
  if (
    createUpdatedRecord &&
    createUpdatedRecord.posMarketDetail &&
    lastUpdatedRecord &&
    lastUpdatedRecord.posMarketDetail &&
    createUpdatedRecord.posMarketDetail.id === lastUpdatedRecord.posMarketDetail.id
  ) {
    return lastUpdatedRecord;
  } else {
    return createUpdatedRecord;
  }
};

export const shouldNotificationCleared = (state: any, id: string) =>
  state &&
  state.notification &&
  state.notification.create &&
  state.notification.create.links &&
  state.notification.create.links[0] &&
  state.notification.create.links[0].id === id;

export const getPosMarketDetailValue = (state: PosState, posId: string): PosMarketDetail | undefined => {
  let posMarketDetailValue: PosMarketDetail | undefined = undefined;

  const posValue = findRecordWithId(state.posRecords, posId);

  if (posValue && posValue.posMarketDetail) {
    posMarketDetailValue = posValue.posMarketDetail;
  } else {
    if (state.pointOfSale.display && posId === state.pointOfSale.display.value.id) {
      posMarketDetailValue = state.pointOfSale.display.value;
    }
  }

  return posMarketDetailValue;
};
