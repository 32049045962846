import { PosActions, POS_ACTION } from './pos-actions';
import { PosState, posInitialState, initialPosStatus, PosStatus } from './pos-state';
import { PosMarketDetail } from '../../service/model/pos/pos-market-detail';
import { UppNotification } from '../../model/notification';
import {
  deleteRecordFromList,
  updateRecordList,
  findRecordWithId,
  getRecordValueAfterDelete,
  getUpdatedRecordValueAfterDelete,
  getUpdatedRecord,
  getPosMarketDetailValue,
  addCreatedRecordToList
} from '../helpers/reducer-helper';
import { TYPE_SUCCESS } from '../../service/model';
import { CORE_ACTION } from '../core/actions';

const UNDEFINED_SEARCH_OBJECT = 'Search object is null or undefined.';

export const posReducer = (state = posInitialState, action: PosActions): PosState => {
  if (PosReducerMethods.has(action.type)) {
    const reducerMethod = PosReducerMethods.get(action.type);

    return reducerMethod ? reducerMethod(state, action) : state;
  }
  return state;
};

const create = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    create: {
      sending: true,
      value: state.pointOfSale.create.value
    }
  },
  notification: {
    ...state.notification,
    create: {}
  }
});

const setCreateValue = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    create: {
      sending: state.pointOfSale.create.sending,
      value: action.payload.value,
      updatedRecord: state.pointOfSale.create.updatedRecord
    }
  }
});

const finishCreate = (state = posInitialState, action: PosActions): PosState => {
  let availableRecordsAfteCreate = state.availablePosRecords;
  const statusNotificationForCreate: UppNotification = action.payload.posRecord.statusNotification;
  if (statusNotificationForCreate.success) {
    availableRecordsAfteCreate = addCreatedRecordToList(state.availablePosRecords, action.payload.posRecord);
  }
  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      create: {
        sending: false,
        value: state.pointOfSale.create.value,
        updatedRecord: action.payload.posRecord
      }
    },
    notification: {
      ...state.notification,
      create: action.payload.posRecord.statusNotification
    },
    availablePosRecords: availableRecordsAfteCreate
  };
};

const deleteCreateNotification = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  notification: {
    ...state.notification,
    create: {}
  }
});

const activateCreateDisplay = (state = posInitialState, action: PosActions): PosState => {
  let posValue = state.pointOfSale.display.value;

  if (state.pointOfSale.create.updatedRecord?.posMarketDetail) {
    posValue = state.pointOfSale.create.updatedRecord.posMarketDetail;
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      display: {
        sending: false,
        value: posValue,
        active: true
      },
      modify: {
        ...state.pointOfSale.modify,
        active: false
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const setModifyValue = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    modify: {
      ...state.pointOfSale.modify,
      value: action.payload.value
    }
  }
});

const startModify = (state = posInitialState, action: PosActions): PosState => {
  const modificationValue = getPosModifyValue(state, action.payload.id);

  if (!modificationValue) {
    throw new Error('Value for record modification missing.');
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      modify: {
        ...state.pointOfSale.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.pointOfSale.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const cancelModify = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    modify: {
      sending: state.pointOfSale.modify.sending,
      value: { organization: '', name: '' },
      active: false
    }
  },
  notification: {
    ...state.notification,
    modify: {},
    display: {}
  }
});

const setModifySuccessValue = (state = posInitialState, action: PosActions): PosState => {
  let updatedDisplayValue = state.pointOfSale.display.value;

  if (action.payload.updatedPosRecord && action.payload.updatedPosRecord.posMarketDetail) {
    updatedDisplayValue = action.payload.updatedPosRecord.posMarketDetail;
  }

  const updatedPosRecords = updateRecordList(state.posRecords, action.payload.updatedPosRecord);

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      display: {
        ...state.pointOfSale.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.pointOfSale.modify.sending,
        value: { organization: '', name: '' },
        active: false
      }
    },
    posRecords: updatedPosRecords
  };
};

const copy = (state = posInitialState, action: PosActions): PosState => {
  const posDetailValue = getPosMarketDetailValue(state, action.payload.id);

  if (!posDetailValue) {
    return { ...state };
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      create: {
        ...state.pointOfSale.create,
        value: {
          ...posDetailValue,
          name: '', // set name empty
          description: 'Copy of '.concat(posDetailValue.name)
        },
        active: true
      }
    },
    notification: {
      ...state.notification
    }
  };
};

const update = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    modify: {
      sending: true,
      value: state.pointOfSale.modify.value,
      active: state.pointOfSale.modify.active
    }
  },
  notification: {
    ...state.notification,
    modify: {}
  }
});

const finsihUpdate = (state = posInitialState, action: PosActions): PosState => {
  let availableRecordsAfterUpdate = state.availablePosRecords;
  const statusNotificationForUpdate: UppNotification = action.payload.updatedPosRecord.statusNotification;
  if (statusNotificationForUpdate.success) {
    availableRecordsAfterUpdate = updateRecordList(state.availablePosRecords, action.payload.updatedPosRecord);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      modify: {
        ...state.pointOfSale.modify,
        sending: false
      },
      create: {
        ...state.pointOfSale.create,
        updatedRecord: getUpdatedRecord(state.pointOfSale.create.updatedRecord, action.payload.updatedPosRecord)
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedPosRecord.statusNotification.warning,
        error: action.payload.updatedPosRecord.statusNotification.error
      },
      display: {
        success: action.payload.updatedPosRecord.statusNotification.success
      }
    },
    availablePosRecords: availableRecordsAfterUpdate
  };
};

const deleteModifyNotification = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  notification: {
    ...state.notification,
    modify: {}
  }
});

const activateDispay = (state = posInitialState, action: PosActions): PosState => {
  let displayValue: PosMarketDetail | undefined = state.pointOfSale.display.value;

  if (action.payload.id) {
    const recordToDisplay = findRecordWithId(state.posRecords, action.payload.id);
    if (recordToDisplay) {
      displayValue = recordToDisplay.posMarketDetail;
    }
  }

  if (!displayValue) {
    throw new Error('displayValue is null or undefined.');
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      display: {
        sending: state.pointOfSale.display.sending,
        value: displayValue,
        active: true
      }
    }
  };
};

const deleteDispayNotification = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  notification: {
    ...state.notification,
    display: {}
  }
});

const clearResults = (state = posInitialState, _action: PosActions): PosState => {
  if (!state.pointOfSale.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      search: {
        ...state.pointOfSale.search,
        sending: false
      },
      delete: {
        ...state.pointOfSale.delete,
        sending: false
      },
      display: initialPosStatus
    },
    posRecords: []
  };
};

const search = (state = posInitialState, _action: PosActions): PosState => {
  if (!state.pointOfSale.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      search: {
        ...state.pointOfSale.search,
        sending: true
      }
    }
  };
};

const setSearchValue = (state = posInitialState, action: PosActions): PosState => {
  if (!state.pointOfSale.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      search: {
        sending: state.pointOfSale.search.sending,
        value: action.payload.value
      }
    }
  };
};

const deletePos = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  pointOfSale: {
    ...state.pointOfSale,
    delete: {
      ...state.pointOfSale.delete,
      sending: true
    }
  }
});

const deleteFromList = (state = posInitialState, action: PosActions): PosState => {
  let records = state.posRecords;
  let availableRecords = state.availablePosRecords;

  let statusNotification: UppNotification = action.payload.deletedRecord.statusNotification;

  if (statusNotification.success) {
    records = deleteRecordFromList(action.payload.deletedRecord.id, records);
    statusNotification = {};
    availableRecords = deleteRecordFromList(action.payload.deletedRecord.id, availableRecords);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      delete: {
        ...state.pointOfSale.delete,
        sending: false
      }
    },
    posRecords: records,
    availablePosRecords: availableRecords,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const deleteClearStore = (state = posInitialState, action: PosActions): PosState => {
  const clearPosId = action.payload.pointOfSale.id;

  const currentValueOfDeletedRecordDisplay = getRecordValueAfterDelete(clearPosId, state.pointOfSale.display.value);
  const currentValueOfDeletedRecordModify = getRecordValueAfterDelete(clearPosId, state.pointOfSale.modify.value);
  const updatedValueOfDeletedRecord = getUpdatedRecordValueAfterDelete(
    clearPosId,
    state.pointOfSale.create.updatedRecord
  );

  if (!currentValueOfDeletedRecordDisplay) {
    throw new Error('currentValueOfDeletedRecordDisplay is null or undefined');
  }

  if (!currentValueOfDeletedRecordModify) {
    throw new Error('currentValueOfDeletedRecordModify is null or undefined');
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      display: {
        ...state.pointOfSale.display,
        value: currentValueOfDeletedRecordDisplay,
        active: checkActiveById(clearPosId, state.pointOfSale.display)
      },
      create: {
        ...state.pointOfSale.create,
        updatedRecord: updatedValueOfDeletedRecord
      },
      modify: {
        ...state.pointOfSale.modify,
        value: currentValueOfDeletedRecordModify,
        active: checkActiveById(clearPosId, state.pointOfSale.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearPosId) ? {} : state.notification?.create
    }
  };
};

const setList = (state = posInitialState, action: PosActions): PosState => {
  if (!state.pointOfSale.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      search: {
        ...state.pointOfSale.search,
        sending: false
      },
      delete: {
        ...state.pointOfSale.delete,
        sending: false
      }
    },
    posRecords: action.payload.posList,
    notification: {
      ...state.notification,
      search: action.payload.posList[0].statusNotification
    }
  };
};

const setAllAvailablePosList = (state = posInitialState, action: PosActions): PosState => {
  if (!state.pointOfSale.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    pointOfSale: {
      ...state.pointOfSale,
      search: {
        ...state.pointOfSale.search,
        sending: false
      },
      delete: {
        ...state.pointOfSale.delete,
        sending: false
      }
    },
    availablePosRecords: action.payload.posList[0].statusType === TYPE_SUCCESS ? action.payload.posList : [],
    notification: {
      ...state.notification,
      search: action.payload.posList[0].statusNotification
    }
  };
};

const updateList = (state = posInitialState, action: PosActions): PosState => {
  const updatedPosList = updateRecordList(state.posRecords, action.payload.posRecord);
  return {
    ...state,
    posRecords: updatedPosList
  };
};

const deleteSearchNotification = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  notification: {
    ...state.notification,
    search: {}
  }
});

const makePosSelection = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  selectedPosNames: action.payload
});

const clearPosSelection = (state = posInitialState, action: PosActions): PosState => ({
  ...state,
  selectedPosNames: new Set<string>()
});

const getInitState = (state = posInitialState, action: PosActions): PosState => posInitialState;

const getPosModifyValue = (state: PosState, posId: string): PosMarketDetail | undefined => {
  let modificationValue: PosMarketDetail | undefined = state.pointOfSale.modify.value;

  if (posId) {
    const recordToModify = findRecordWithId(state.posRecords, posId);

    if (recordToModify) {
      modificationValue = recordToModify.posMarketDetail;
    }
  }

  return modificationValue;
};

const checkActiveById = (id: string, posStatus: PosStatus): boolean | undefined => {
  if (!posStatus) {
    return false;
  }

  if (posStatus.active && posStatus.value && posStatus.value.id === id) {
    return false;
  } else {
    return posStatus.active;
  }
};

const shouldNotificationCleared = (state: any, id: string): boolean =>
  state &&
  state.notification &&
  state.notification.create &&
  state.notification.create.links[0] &&
  state.notification.create.links[0].id === id;

export const PosReducerMethods: Map<string, (state: PosState, action: PosActions) => PosState> = new Map<
  string,
  (state: PosState, action: PosActions) => PosState
>([
  [POS_ACTION.CREATE_POS, create],
  [POS_ACTION.SET_CREATE_POS_VALUE, setCreateValue],
  [POS_ACTION.FINISH_POS_CREATION, finishCreate],
  [POS_ACTION.DELETE_POS_CREATE_NOTIFICATION, deleteCreateNotification],
  [POS_ACTION.ACTIVATE_CREATED_POS_DISPLAY, activateCreateDisplay],
  [POS_ACTION.SET_POS_MODIFY_VALUE, setModifyValue],
  [POS_ACTION.START_POS_MODIFICATION, startModify],
  [POS_ACTION.CANCEL_POS_MODIFICATION, cancelModify],
  [POS_ACTION.SET_POS_UPDATE_SUCCESS_VALUES, setModifySuccessValue],
  [POS_ACTION.COPY_POS, copy],
  [POS_ACTION.UPDATE_POS, update],
  [POS_ACTION.FINISH_POS_UPDATE, finsihUpdate],
  [POS_ACTION.DELETE_POS_MODIFY_NOTIFICATION, deleteModifyNotification],
  [POS_ACTION.ACTIVATE_POS_DISPLAY, activateDispay],
  [POS_ACTION.DELETE_POS_DISPLAY_NOTIFICATION, deleteDispayNotification],
  [POS_ACTION.CLEAR_POS_RESULTS, clearResults],
  [POS_ACTION.SEARCH_POS, search],
  [POS_ACTION.GET_ALL_AVAILABLE_POS, search],
  [POS_ACTION.SET_SEARCH_POS_VALUE, setSearchValue],
  [POS_ACTION.DELETE_POS, deletePos],
  [POS_ACTION.DELETE_POS_FROM_LIST, deleteFromList],
  [POS_ACTION.DELETE_POS_CLEAR_STORE, deleteClearStore],
  [POS_ACTION.SET_POS_LIST, setList],
  [POS_ACTION.SET_ALL_AVAILABLE_POS_LIST, setAllAvailablePosList],
  [POS_ACTION.UPDATE_POS_LIST, updateList],
  [POS_ACTION.DELETE_POS_SEARCH_NOTIFICATION, deleteSearchNotification],
  [POS_ACTION.INIT_POS_STATE, getInitState],
  [CORE_ACTION.UPDATE_SELECTED_PARTITION, getInitState],
  [POS_ACTION.MAKE_POS_SELECTION, makePosSelection],
  [POS_ACTION.CLEAR_POS_SELECTION, clearPosSelection]
]);
