// Journey types
import { UppComponentNames, UppViewNames } from '../service/model';

export const ANY_SEGMENT_CONNECTION = 'any';
export const journeyTypesOptions = [
  {
    value: 'NonStop',
    label: 'Non Stop'
  },
  {
    value: 'Direct',
    label: 'Direct'
  },
  {
    value: 'COG',
    label: 'CoG'
  },
  {
    value: 'Connection2',
    label: 'Connection with 2 segments',
    parent: ANY_SEGMENT_CONNECTION
  },
  {
    value: 'Connection3',
    label: 'Connection with 3 segments',
    parent: ANY_SEGMENT_CONNECTION
  },
  {
    value: 'Connection4',
    label: 'Connection with 4 segments',
    parent: ANY_SEGMENT_CONNECTION
  },
  {
    value: 'Connection5',
    label: 'Connection with 5 segments',
    parent: ANY_SEGMENT_CONNECTION
  }
];
export const journeyTypesGroupValueFn = (_groupName: string) => ({
  value: ANY_SEGMENT_CONNECTION,
  label: 'Connection from 2 to 5 segments'
});

export const searchNotificationMessages = {
  error: $localize`:@@upp.global.text.mainErrorText:The request failed due to the following errors:`,
  warning: $localize`:@@upp.global.text.mainWarningText:Warnings were generated during the search process:`,
  success: $localize`:@@upp.global.text.mainSuccessText:The search was successful.`
};

export class FormElements {
  static readonly NAME = 'name';
  static readonly HOTEL_TYPE = 'hotelFamilyType';
  static readonly CHAINS = 'hotelChains';
  static readonly PROPERTY_CODES = 'hotelPropertyCodes';
  static readonly CARRIER_CODES = 'carrierCodes';
  static readonly DESCRIPTION = 'description';
  static readonly ID = 'id';
}

export enum FormKey {
  AirFamiliesSearch = `${UppComponentNames.AIR_FAMILIES}-${UppViewNames.SEARCH}-form`,
  AirFamilyForm = `${UppComponentNames.AIR_FAMILIES}-form`
}
