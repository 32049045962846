import { Action } from '@ngrx/store';
import { LookupOptions } from '../../core/util/lookup-options';

export const NAVIGATION_ACTION = {
  SET_NAVIGATION_PARAMS: '[Navigation Action] Set navigation params',
  CLEAR_LOOKUP_DATA: '[Navigation Action] Clear lookup data',
  SET_PARTITION_DESTINATION: '[Navigation Action] Set partition destination',
  RESET_PARTITION_DESTINATION: '[Navigation Action] Reset partition destination',
  METAGROUP_SWITCH_ALERT_DISPLAYED: '[Navigation Action] Metagroup switch alert displayed'
};

export class SetNavigationParams implements Action {
  readonly type = NAVIGATION_ACTION.SET_NAVIGATION_PARAMS;
  constructor(public payload?: LookupOptions) {}
}

export class ClearLookupData implements Action {
  readonly type = NAVIGATION_ACTION.CLEAR_LOOKUP_DATA;
  constructor(public payload?: LookupOptions) {}
}

export class SetPartitionDestination implements Action {
  readonly type = NAVIGATION_ACTION.SET_PARTITION_DESTINATION;
  constructor(public payload: string) {}
}

export class ResetPartitionDestination implements Action {
  readonly type = NAVIGATION_ACTION.SET_PARTITION_DESTINATION;
  constructor(public payload?: any) {}
}

export class MetaGroupSwitchAlertDisplayed implements Action {
  readonly type = NAVIGATION_ACTION.METAGROUP_SWITCH_ALERT_DISPLAYED;
  constructor(public payload?: any) {}
}

export type NavigationActions =
  | SetNavigationParams
  | ClearLookupData
  | SetPartitionDestination
  | ResetPartitionDestination
  | MetaGroupSwitchAlertDisplayed;
