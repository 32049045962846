import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FaresCreateComponent } from '../../fares/fares-create/fares-create.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFareCreateGuard {
  canDeactivate(component: FaresCreateComponent): Observable<boolean> | boolean {
    component.storeUnsavedChanges();
    return true;
  }
}
