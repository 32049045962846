import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoginService } from '../service/login.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private readonly loginService = inject(LoginService);

  private readonly handleUnauthorizedAccess = () => {
    this.loginService.permissionFailedLogout();
    window.location.reload();
    return of(null);
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((response, _) => {
        if (!(response instanceof HttpErrorResponse)) {
          return of(response);
        }
        if (response.status === 401) {
          this.handleUnauthorizedAccess();
        }
        return of(response);
      })
    );
  }
}
