<div class="mb-5">
  <ama-ng-upp-button-group
    [items]="airlineScopeButtonGroupItems"
    (toggled)="onAirlineScopeToggle($event)"
  ></ama-ng-upp-button-group>
  <ama-ng-upp-validation-error-message
    [control]="hasSelected!"
    [messageFunctions]="SECTION_VALIDATION_MESSAGES"
  ></ama-ng-upp-validation-error-message>
</div>

<div *ngIf="airlineScopeButtonGroupMap.carrierCodes.active" class="flex-basis-100">
  <label class="form-label" for="carrierCode">
    <ng-container>{{ carrierCodeLabel }}</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <ama-ng-upp-lookup-select
    id="carrierCode"
    [formControl]="getControl('carrierCodes')"
    [multiple]="multiple"
    [addTag]="true"
    notFoundText="No airlines found"
    i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText"
    [ngbTooltip]="CARRIER_CODE_TOOLTIP"
  >
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message
    [control]="formGroup.get('carrierCodes')!"
    [messageFunctions]="carrierCodesValidationMessages"
  ></ama-ng-upp-validation-error-message>
</div>

<div *ngIf="airlineScopeButtonGroupMap?.alliances?.active" class="flex-basis-100">
  <label class="form-label" for="alliances">
    <ng-container>{{ allianceLabel }}</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <ama-ng-upp-lookup-select
    id="alliances"
    [formControl]="getControl('alliances')"
    [items]="availableAlliances"
    [multiple]="multiple"
    bindValue="value"
    bindLabel="label"
    notFoundText="No alliance found"
    i18n-notFoundText="@@upp.global.criteria.alliance.notFoundText"
  >
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message [control]="formGroup.get('alliances')!"> </ama-ng-upp-validation-error-message>
</div>

<div *ngIf="airlineScopeButtonGroupMap?.carriersFamily?.active" class="flex-basis-100">
  <label for="family" class="form-label">
    <ng-container>{{ airlineFamilyLabel }}</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <ama-ng-upp-lookup-select
    id="family"
    [formControl]="getControl('carriersFamily')"
    [multiple]="multiple"
    [items]="availableAirlineFamilyNames"
    [lookupOptions]="disabled ? undefined : getAirlineFamiliesLookupOptions()"
    notFoundText="No air families found"
    i18n-notFoundText="@@upp.global.criteria.airlineFamilies.notFoundText"
  >
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message [control]="formGroup.get('carriersFamily')!">
  </ama-ng-upp-validation-error-message>
</div>
