import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { HotelRuleSearchCriteriaUi, initialHotelRuleSearchCriteria } from '../../model/hotel-rule-search-criteria';
import {
  COMMISSIONABLE_ALL,
  COMMISSIONABLE_YES,
  RULE_STATUS_ALL,
  ALL_ACTION,
  COMMISSIONABLE_NO
} from '../../model/hotel-rule-ui';
import { Store, select } from '@ngrx/store';
import { selectHotelRuleSearchValue } from '../../../store/hotel/hotels-selector';
import { SetHotelRuleSearchValueAction } from '../../../store/hotel/hotels-action';
import { RuleSearchCriteria, SearchCriteriaComponent } from '../../../base/search-criteria/search-criteria.component';
import { SelectableCriteria } from '../../../base/search-criteria/selectable-criteria';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { Subscription } from 'rxjs';
import { LookupOptions } from '../../../core/util/lookup-options';
import { selectAllAvailablePosRecords } from '../../../store/pos/pos-selector';
import { selectAllAvailableMarketsRecords } from '../../../store/markets/markets-selector';
import { UppComponentNames, UppViewNames } from '../../../service/model';
import { PERMISSION_VIEW_MARKET, PERMISSION_VIEW_POS } from '../../../service/user-permissions.service';
import { UserDetailsService } from '../../../service/user-details.service';
import { FeatureFlags } from '../../../core/util/resources';

export class HotelRuleSearchCriteria extends RuleSearchCriteria {
  readonly COMMISSIONABLE = 'commissionable';
}

@Component({
  selector: 'ama-ng-upp-hotels-search-criteria',
  templateUrl: './hotels-search-criteria.component.html',
  styleUrls: ['./hotels-search-criteria.component.scss']
})
export class HotelsSearchCriteriaComponent extends SearchCriteriaComponent implements OnInit {
  @Output() searchHotels = new EventEmitter<HotelRuleSearchCriteriaUi>();

  @Input() sendingStatus = false;

  searchCriteria: HotelRuleSearchCriteriaUi = initialHotelRuleSearchCriteria;

  availableDynamicCriteria: HotelRuleSearchCriteria;

  subscription: Subscription = new Subscription();

  hotelsV2: boolean = false;

  availablePosNames: string[] = [];
  posLookupOptions?: LookupOptions;
  marketLookupOptions?: LookupOptions;
  availableMarketsNames: string[] = [];

  get nameFormControl() {
    return this.searchCriteriaForm?.get('name');
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    super();
    this.availableDynamicCriteria = new HotelRuleSearchCriteria();
    this.dynamicSearchCriteria = this.initDynamicCriteriaButtons();
  }

  ngOnInit(): void {
    this.store.pipe(select(selectHotelRuleSearchValue)).subscribe((value) => (this.searchCriteria = value));

    this.subscription?.add(
      this.configurationService.getParameter$(FeatureFlags.hotelsV2).subscribe((hotelsV2) => {
        this.hotelsV2 = hotelsV2 ?? false;
      })
    );

    this.subscription.add(
      this.store.pipe(select(selectAllAvailablePosRecords)).subscribe((result) => {
        if (result != null) {
          this.availablePosNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((x) => x !== undefined);
        }
      })
    );

    this.subscription.add(
      this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
        if (result != null) {
          this.availableMarketsNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((x) => x !== undefined);
        }
      })
    );

    this.initSelectableCriteriaButtonsAndElements();
    this.createAndLoadSearchForm();

    if (this.hotelsV2) {
      this.posLookupOptions = this.getPosMarketNamesLookupOptions();
      this.marketLookupOptions = this.getMarketNamesLookupOptions();
    }
  }

  createAndLoadSearchForm() {
    this.searchCriteriaForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.maxLength(30)]),
      organization: this.formBuilder.control(''),
      actionType: this.formBuilder.control(''),
      ruleStatus: this.formBuilder.control(''),
      commissionable: this.formBuilder.control('')
    });

    if (this.hotelsV2) {
      this.searchCriteriaForm.addControl(
        'applicability',
        this.formBuilder.group({
          pointOfSale: this.formBuilder.control(undefined),
          destination: this.formBuilder.control(undefined)
        })
      );
    }

    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  searchClicked() {
    this.searchHotels.emit(this.searchCriteriaForm?.value);
  }

  storeUnsavedHotelSearch() {
    this.store.dispatch(new SetHotelRuleSearchValueAction({ hotelSearchCriteria: this.searchCriteriaForm?.value }));
  }

  clearClicked() {
    this.searchCriteria = initialHotelRuleSearchCriteria;
    this.initSelectableCriteriaButtonsAndElements();
    this.searchCriteriaForm?.patchValue(this.searchCriteria);
    this.searchCriteriaForm?.get('name')?.setValue('');
    this.searchCriteriaForm?.get('applicability')?.reset();
  }

  protected checkToUpdateDynamicCriteriaValue(criteriaName: string, deactivation: boolean) {
    if (this.availableDynamicCriteria.COMMISSIONABLE === criteriaName) {
      const value = deactivation ? COMMISSIONABLE_ALL : COMMISSIONABLE_YES;
      this.searchCriteriaForm?.get('commissionable')?.setValue(value);
    }
    if (this.availableDynamicCriteria.RULE_STATUS === criteriaName) {
      this.searchCriteriaForm?.get('ruleStatus')?.setValue(RULE_STATUS_ALL);
    }
    if (this.availableDynamicCriteria.ACTION_TYPE === criteriaName) {
      this.searchCriteriaForm?.get('actionType')?.setValue(ALL_ACTION);
    }
  }

  protected activateOtherCriteriaButtonsAndControls() {
    if (
      this.searchCriteria.commissionable !== undefined &&
      (this.searchCriteria.commissionable === COMMISSIONABLE_YES ||
        this.searchCriteria.commissionable === COMMISSIONABLE_NO)
    ) {
      const commissionableCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.COMMISSIONABLE
      );
      if (commissionableCriteria) {
        commissionableCriteria.active = true;
      }
    }
    if (this.searchCriteria.actionType !== undefined && this.searchCriteria.actionType !== ALL_ACTION) {
      const actionTypeCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.ACTION_TYPE
      );
      if (actionTypeCriteria) {
        actionTypeCriteria.active = true;
      }
    }
    if (this.searchCriteria.ruleStatus !== undefined && this.searchCriteria.ruleStatus !== RULE_STATUS_ALL) {
      const ruleStatusCriteria = this.dynamicSearchCriteria?.find(
        (criteria) => criteria.name === this.availableDynamicCriteria.RULE_STATUS
      );
      if (ruleStatusCriteria) {
        ruleStatusCriteria.active = true;
      }
    }
  }

  private initDynamicCriteriaButtons(): SelectableCriteria[] {
    return [
      {
        name: this.availableDynamicCriteria.ACTION_TYPE,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.actionTypeLabel:Action Type`
      },
      {
        name: this.availableDynamicCriteria.RULE_STATUS,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.ruleStatusLabel:Rule Status`
      },
      {
        name: this.availableDynamicCriteria.COMMISSIONABLE,
        active: false,
        disabled: false,
        title: $localize`:@@upp.hotels.search.commissionableLabel:Commissionable`
      }
    ];
  }

  private getPosMarketNamesLookupOptions(): LookupOptions | undefined {
    if (this.canLookupPos()) {
      return {
        destinationComponent: UppComponentNames.POS,
        sourceComponent: UppComponentNames.HOTELS,
        sourceView: UppViewNames.SEARCH,
        fieldPath: `applicability.pointOfSaleNames`,
        singleSelect: true
      };
    }
    return undefined;
  }

  private getMarketNamesLookupOptions(): LookupOptions | undefined {
    if (this.canLookupMarket()) {
      return {
        destinationComponent: UppComponentNames.MARKETS,
        sourceComponent: UppComponentNames.HOTELS,
        sourceView: UppViewNames.SEARCH,
        fieldPath: `marketNames`,
        singleSelect: true
      };
    }
    return undefined;
  }

  private canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  private canLookup(permisionName: string): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === permisionName) ?? false;
  }

  private canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }
}
