import { RULE_STATUS_ACTIVE } from './fare-rule-ui';

export interface FareSearchCriteriaUi {
  rule: {
    organization: string;
    name?: string;
    ruleStatus: string;
  };
}

export const initialFareRuleSearchCriteria: FareSearchCriteriaUi = {
  rule: {
    organization: '',
    // set to RULL_STATUS_ALL once ABR API can search for both
    ruleStatus: RULE_STATUS_ACTIVE
  }
};
