import { BaseUi, baseUiEquals } from '../../base/model/base-ui';
import { arraysEquals } from '../../util/arrayUtils';

export const HOTEL_CHAINS_TYPE = 'hotelChains';
export const HOTEL_PROP_CODES_TYPE = 'hotelPropertyCodes';

export interface FamilyUi extends BaseUi {
  version?: number;
  organization?: string;
  partitionId?: string;
  name: string;
  description?: string;
  hotelFamilyType: string;
  hotelChains?: string[];
  hotelPropertyCodes?: string[];
}

export const initialFamilyUi: FamilyUi = {
  organization: '',
  name: '',
  description: '',
  hotelFamilyType: HOTEL_CHAINS_TYPE,
  id: '',
  hotelChains: [],
  hotelPropertyCodes: []
};

export interface FamilySearchCriteriaUI {
  version?: number;
  organization?: string;
  partitionId?: string;
  name?: string;
  hotelFamilyType: string;
  hotelChains?: string[];
  hotelPropertyCodes?: string[];
}

export const initialFamilySearchCriteria: FamilySearchCriteriaUI = {
  organization: '',
  hotelFamilyType: HOTEL_CHAINS_TYPE,
  hotelChains: [],
  hotelPropertyCodes: []
};

export const familyUiEquals = (f1: FamilyUi | undefined, f2: FamilyUi | undefined): boolean =>
  baseUiEquals(f1, f2) &&
  !!f1 &&
  !!f2 &&
  f1.name === f2.name &&
  f1.organization === f2.organization &&
  f1.description === f2.description &&
  f1.hotelFamilyType === f2.hotelFamilyType &&
  arraysEquals(f1.hotelChains, f2.hotelChains) &&
  arraysEquals(f1.hotelPropertyCodes, f2.hotelPropertyCodes);
