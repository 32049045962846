import { MarketsActions, MARKETS_ACTION } from './markets-actions';
import { MarketsState, marketsInitialState, MarketsStatus } from './markets-state';
import { PosMarketDetail } from '../../service/model';
import { UppNotification } from '../../model/notification';
import {
  deleteRecordFromList,
  updateRecordList,
  findRecordWithId,
  getRecordValueAfterDelete,
  getUpdatedRecordValueAfterDelete,
  getUpdatedRecord,
  shouldNotificationCleared,
  addCreatedRecordToList
} from '../helpers/reducer-helper';
import { TYPE_SUCCESS } from '../../service/model';
import { CORE_ACTION } from '../core/actions';

const UNDEFINED_SEARCH_OBJECT = 'Search object is null or undefined.';

export const marketsReducer = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  if (MarketReducerMethods.has(action.type)) {
    const reducerMethod = MarketReducerMethods.get(action.type);

    if (reducerMethod) {
      return reducerMethod(state, action);
    }
  }
  return state;
};

const clearResults = (state = marketsInitialState, _action: MarketsActions): MarketsState => {
  if (!state.markets.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      search: {
        ...state.markets.search,
        sending: false
      }
    },
    marketsRecords: []
  };
};

const search = (state = marketsInitialState, _action: MarketsActions): MarketsState => {
  if (!state.markets.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      search: {
        ...state.markets.search,
        sending: true
      }
    }
  };
};

const setSearchValue = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  if (!state.markets.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      search: {
        sending: state.markets.search.sending,
        value: action.payload.value
      }
    }
  };
};

const setList = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  if (!state.markets.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      search: {
        ...state.markets.search,
        sending: false
      }
    },
    marketsRecords: action.payload.marketsList,
    notification: {
      ...state.notification,
      search: action.payload.marketsList[0].statusNotification
    }
  };
};

const deleteFromList = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let availableRecordsAfterDelete = state.availableMarketsRecords;
  let records = state.marketsRecords;

  let statusNotification: UppNotification = action.payload.deletedRecord.statusNotification;

  if (statusNotification.success) {
    records = deleteRecordFromList(action.payload.deletedRecord.id, records);
    statusNotification = {};
    availableRecordsAfterDelete = deleteRecordFromList(action.payload.deletedRecord.id, availableRecordsAfterDelete);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      delete: {
        ...state.markets.delete,
        sending: false
      }
    },
    marketsRecords: records,
    availableMarketsRecords: availableRecordsAfterDelete,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const setAllAvailableMarketsList = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  if (!state.markets.search) {
    throw new Error(UNDEFINED_SEARCH_OBJECT);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      search: {
        ...state.markets.search,
        sending: false
      }
    },
    availableMarketsRecords:
      action.payload.marketsList[0].statusType === TYPE_SUCCESS ? action.payload.marketsList : [],
    notification: {
      ...state.notification,
      search: action.payload.marketsList[0].statusNotification
    }
  };
};

const deleteMarketFromList = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let availableRecordsAfterDelete = state.availableMarketsRecords;
  let records = state.marketsRecords;

  let statusNotification: UppNotification = action.payload.deletedRecord.statusNotification;

  if (statusNotification.success) {
    records = deleteRecordFromList(action.payload.deletedRecord.id, records);
    statusNotification = {};
    availableRecordsAfterDelete = deleteRecordFromList(action.payload.deletedRecord.id, availableRecordsAfterDelete);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      delete: {
        ...state.markets.delete,
        sending: false
      }
    },
    marketsRecords: records,
    availableMarketsRecords: availableRecordsAfterDelete,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const deleteSearchNotification = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  notification: {
    ...state.notification,
    search: {}
  }
});

const activateDispay = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let displayValue = state.markets.display.value;

  if (action.payload.id) {
    const recordToDisplay = findRecordWithId(state.marketsRecords, action.payload.id);

    displayValue = recordToDisplay?.posMarketDetail;
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      display: {
        sending: state.markets.display.sending,
        value: displayValue,
        active: true
      }
    }
  };
};

const deleteDispayNotification = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  notification: {
    ...state.notification,
    display: {}
  }
});

const deleteMarket = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    delete: {
      ...state.markets.delete,
      sending: true
    }
  }
});

const deleteClearStore = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  const clearMarketId = action.payload.market.id;

  const currentValueOfDeletedRecordDisplay = getRecordValueAfterDelete(clearMarketId, state.markets.display.value);
  const currentValueOfDeletedRecordModify = getRecordValueAfterDelete(clearMarketId, state.markets.modify.value);
  const updatedValueOfDeletedRecord = getUpdatedRecordValueAfterDelete(
    clearMarketId,
    state.markets.create.updatedRecord
  );

  return {
    ...state,
    markets: {
      ...state.markets,
      display: {
        ...state.markets.display,
        value: currentValueOfDeletedRecordDisplay,
        active: checkActiveById(clearMarketId, state.markets.display)
      },
      create: {
        ...state.markets.create,
        updatedRecord: updatedValueOfDeletedRecord
      },
      modify: {
        ...state.markets.modify,
        value: currentValueOfDeletedRecordModify,
        active: checkActiveById(clearMarketId, state.markets.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearMarketId) ? {} : state.notification?.create
    }
  };
};

const setModifyValue = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    modify: {
      ...state.markets.modify,
      value: action.payload.value
    }
  }
});

const startModification = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  const modificationValue = getMarketModifyValue(state, action.payload.id);
  return {
    ...state,
    markets: {
      ...state.markets,
      modify: {
        ...state.markets.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.markets.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const copy = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  const marketDetailValue = getPosMarketDetailValue(state, action.payload.id);

  if (!marketDetailValue) {
    throw new Error('marketDetailValue is null or undefined.');
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      create: {
        ...state.markets.create,
        value: {
          ...marketDetailValue,
          name: '', // set name empty
          description: 'Copy of '.concat(marketDetailValue.name)
        },
        active: true
      }
    },
    notification: {
      ...state.notification
    }
  };
};

const update = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    modify: {
      sending: true,
      value: state.markets.modify.value,
      active: state.markets.modify.active
    }
  },
  notification: {
    ...state.notification,
    modify: {}
  }
});

const cancelModify = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    modify: {
      sending: state.markets.modify.sending,
      value: { organization: '', name: '' },
      active: false
    }
  },
  notification: {
    ...state.notification,
    modify: {},
    display: {}
  }
});

const setUpdateSucess = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let updatedDisplayValue = state.markets.display.value;
  if (action.payload.updatedRecord && action.payload.updatedRecord.posMarketDetail) {
    updatedDisplayValue = action.payload.updatedRecord.posMarketDetail;
  }
  const updatedRecords = updateRecordList(state.marketsRecords, action.payload.updatedRecord);
  return {
    ...state,
    markets: {
      ...state.markets,
      display: {
        ...state.markets.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.markets.modify.sending,
        value: { organization: '', name: '' },
        active: false
      }
    },
    marketsRecords: updatedRecords
  };
};

const finishUpdate = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let availableRecordsAfterUpdate = state.availableMarketsRecords;
  const statusNotificationForUpdate: UppNotification = action.payload.updatedRecord.statusNotification;
  if (statusNotificationForUpdate.success) {
    availableRecordsAfterUpdate = updateRecordList(state.availableMarketsRecords, action.payload.updatedRecord);
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      modify: {
        ...state.markets.modify,
        sending: false
      },
      create: {
        ...state.markets.create,
        updatedRecord: getUpdatedRecord(state.markets.create.updatedRecord, action.payload.updatedRecord)
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedRecord.statusNotification.warning,
        error: action.payload.updatedRecord.statusNotification.error
      },
      display: {
        success: action.payload.updatedRecord.statusNotification.success
      }
    },
    availableMarketsRecords: availableRecordsAfterUpdate
  };
};

const deleteModifyNotification = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  notification: {
    ...state.notification,
    modify: {}
  }
});

const create = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    create: {
      sending: true,
      value: state.markets.create.value
    }
  },
  notification: {
    ...state.notification,
    create: {}
  }
});

const setCreateValue = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  markets: {
    ...state.markets,
    create: {
      sending: state.markets.create.sending,
      value: action.payload.value,
      updatedRecord: state.markets.create.updatedRecord
    }
  }
});

const finishCreate = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let availableRecordsAfteCreate = state.availableMarketsRecords;
  const statusNotificationForCreate: UppNotification = action.payload.marketRecord.statusNotification;
  if (statusNotificationForCreate.success) {
    availableRecordsAfteCreate = addCreatedRecordToList(state.availableMarketsRecords, action.payload.marketRecord);
  }
  return {
    ...state,
    markets: {
      ...state.markets,
      create: {
        sending: false,
        value: state.markets.create.value,
        updatedRecord: action.payload.marketRecord
      }
    },
    notification: {
      ...state.notification,
      create: action.payload.marketRecord.statusNotification
    },
    availableMarketsRecords: availableRecordsAfteCreate
  };
};

const deleteCreateNotification = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  notification: {
    ...state.notification,
    create: {}
  }
});

const makeMarketSelection = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  selectedMarketsNames: action.payload
});

const clearMarketSelection = (state = marketsInitialState, action: MarketsActions): MarketsState => ({
  ...state,
  selectedMarketsNames: new Set<string>()
});

const getMarketsInitialState = (state = marketsInitialState, action: MarketsActions): MarketsState =>
  marketsInitialState;

const activateCreateDispay = (state = marketsInitialState, action: MarketsActions): MarketsState => {
  let marketsValue = state.markets.display.value;

  if (state.markets.create.updatedRecord) {
    marketsValue = state.markets.create.updatedRecord.posMarketDetail;
  }

  return {
    ...state,
    markets: {
      ...state.markets,
      display: {
        sending: false,
        value: marketsValue,
        active: true
      },
      modify: {
        ...state.markets.modify,
        active: false
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const getMarketModifyValue = (state: MarketsState, marketId: string): PosMarketDetail | undefined => {
  let modificationValue = state.markets.modify.value;

  if (marketId) {
    const recordToModify = findRecordWithId(state.marketsRecords, marketId);

    modificationValue = recordToModify?.posMarketDetail;
  }

  return modificationValue;
};

const getPosMarketDetailValue = (state: MarketsState, marketId: string): PosMarketDetail | undefined => {
  let posMarketDetailValue: PosMarketDetail | undefined;

  const marketValue = findRecordWithId(state.marketsRecords, marketId);
  if (marketValue && marketValue.posMarketDetail) {
    posMarketDetailValue = marketValue.posMarketDetail;
  } else {
    if (state.markets.display && state.markets.display.value && marketId === state.markets.display.value.id) {
      posMarketDetailValue = state.markets.display.value;
    }
  }

  return posMarketDetailValue;
};

const checkActiveById = (id: string, marketsStatus: MarketsStatus): boolean => {
  if (!marketsStatus) {
    return false;
  }

  if (marketsStatus.active && marketsStatus.value && marketsStatus.value.id === id) {
    return false;
  } else {
    return marketsStatus.active ?? false;
  }
};

export const MarketReducerMethods: Map<string, (state: MarketsState, action: MarketsActions) => MarketsState> = new Map<
  string,
  (state: MarketsState, action: MarketsActions) => MarketsState
>([
  [MARKETS_ACTION.CLEAR_MARKETS_RESULTS, clearResults],
  [MARKETS_ACTION.SEARCH_MARKETS, search],
  [MARKETS_ACTION.GET_ALL_AVAILABLE_MARKETS, search],
  [MARKETS_ACTION.SET_SEARCH_MARKETS_VALUE, setSearchValue],
  [MARKETS_ACTION.SET_MARKETS_LIST, setList],
  [MARKETS_ACTION.DELETE_MARKET_FROM_LIST, deleteFromList],
  [MARKETS_ACTION.SET_ALL_AVAILABLE_MARKETS_LIST, setAllAvailableMarketsList],
  [MARKETS_ACTION.DELETE_MARKET_FROM_LIST, deleteMarketFromList],
  [MARKETS_ACTION.DELETE_MARKETS_SEARCH_NOTIFICATION, deleteSearchNotification],
  [MARKETS_ACTION.ACTIVATE_MARKETS_DISPLAY, activateDispay],
  [MARKETS_ACTION.DELETE_MARKETS_DISPLAY_NOTIFICATION, deleteDispayNotification],
  [MARKETS_ACTION.DELETE_MARKETS, deleteMarket],
  [MARKETS_ACTION.DELETE_MARKETS_CLEAR_STORE, deleteClearStore],
  [MARKETS_ACTION.SET_MARKETS_MODIFY_VALUE, setModifyValue],
  [MARKETS_ACTION.START_MARKETS_MODIFICATION, startModification],
  [MARKETS_ACTION.COPY_MARKET, copy],
  [MARKETS_ACTION.UPDATE_MARKETS, update],
  [MARKETS_ACTION.CANCEL_MARKETS_MODIFICATION, cancelModify],
  [MARKETS_ACTION.SET_MARKETS_UPDATE_SUCCESS_VALUES, setUpdateSucess],
  [MARKETS_ACTION.FINISH_MARKETS_UPDATE, finishUpdate],
  [MARKETS_ACTION.DELETE_MARKETS_MODIFY_NOTIFICATION, deleteModifyNotification],
  [MARKETS_ACTION.CREATE_MARKET, create],
  [MARKETS_ACTION.SET_CREATE_MARKET_VALUE, setCreateValue],
  [MARKETS_ACTION.FINISH_MARKET_CREATION, finishCreate],
  [MARKETS_ACTION.DELETE_MARKETS_CREATE_NOTIFICATION, deleteCreateNotification],
  [MARKETS_ACTION.ACTIVATE_CREATED_MARKET_DISPLAY, activateCreateDispay],
  [MARKETS_ACTION.INIT_MARKETS_STATE, getMarketsInitialState],
  [CORE_ACTION.UPDATE_SELECTED_PARTITION, getMarketsInitialState],
  [MARKETS_ACTION.MAKE_MARKET_SELECTION, makeMarketSelection],
  [MARKETS_ACTION.CLEAR_MARKET_SELECTION, clearMarketSelection]
]);
