import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../service/core/navigation.service';
import { JourneyUi } from '../model';
import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import {
  selectFlightRuleDisplayValue,
  selectFlightRuleDisplayActivated,
  selectFlightRuleDisplayNotification
} from '../../store/flight/flights-selector';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppComponent } from '../../service/model/upp-component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {
  ActivateFlightRuleDisplayAction,
  CopyFlightRuleAction,
  DeleteFlightRuleCreateNotificationAction,
  DeleteFlightRuleDisplayNotificationAction,
  SetFlightModifyValueAction,
  StartFlightModificationAction
} from '../../store/flight/flights-action';
import { PERMISSION_MANAGE_EDIFACT_RULE, PERMISSION_MANAGE_NDC_RULE } from '../../service/user-permissions.service';
import { UserDetailsService } from '../../service/user-details.service';
import { ContentType } from '../model/journey';

@Component({
  selector: 'ama-ng-upp-flights-display',
  templateUrl: './flights-display.component.html',
  styleUrls: ['./flights-display.component.scss']
})
export class FlightsDisplayComponent implements OnInit {
  flightForm!: UntypedFormGroup;
  flightRule!: JourneyUi;
  notification$: Observable<UppNotification | undefined>;
  displayActivated = false;

  readonly = true;
  uppComponent = UppComponent.FLIGHTS_DISPLAY;
  mainMessages: NotificationMessages;

  hasManageAccess = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.store
      .pipe(select(selectFlightRuleDisplayActivated))
      .subscribe((activated) => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectFlightRuleDisplayNotification));
    this.mainMessages = {
      error: $localize`:@@upp.flights.display.mainErrorText:The following errors for flight rule appeared:`,
      warning: $localize`:@@upp.flights.display.mainWarningText:The following warning for flight rule appeared:`,
      success: $localize`:@@upp.flights.display.mainSuccessText:The flight rule was stored successfully.`
    };
  }

  ngOnInit() {
    this.createForm();
    this.navigationService.setSelectedMenuTitle('Flight rule display');
    this.navigationService.activateFlights();
    if (!this.displayActivated) {
      this.router.navigate(['flights/search/']);
    } else {
      this.loadFlightRule();
    }
  }

  createForm() {
    this.flightForm = this.formBuilder.group({});
    this.store.pipe(select(selectFlightRuleDisplayValue)).subscribe((value) => {
      this.flightRule = value;
      this.hasManageAccess = this.getHasManageAccess();
    });
  }

  loadFlightRule() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activateFlightRuleWithId(params.get('id')!));
  }

  activateFlightRuleWithId(paramId: string) {
    this.store.dispatch(new ActivateFlightRuleDisplayAction({ id: paramId }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteFlightRuleDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess && !!this.flightRule?.rule?.name;
  }

  modifyFlight() {
    this.store.dispatch(new StartFlightModificationAction({ id: undefined }));
    this.store.dispatch(new SetFlightModifyValueAction({ value: this.flightRule }));
    this.router.navigate(['flights/modify/'], { queryParams: { sourceView: 'display' } });
  }

  copyFlight() {
    this.store.dispatch(new CopyFlightRuleAction({ id: this.flightRule?.rule?.id }));
    this.store.dispatch(new DeleteFlightRuleCreateNotificationAction({}));
    this.router.navigate(['flights/create/']);
  }

  getHasManageAccess(): boolean {
    const contentTypes = this.flightRule.applicability.contentTypes ?? [ContentType.EDIFACT];
    return (
      (this.hasManageEdifactPermission() || !contentTypes.includes(ContentType.EDIFACT)) &&
      (this.hasManageNDCPermission() || !contentTypes.includes(ContentType.NDC))
    );
  }

  private hasManageEdifactPermission(): boolean {
    return this.userDetailsService.loggedInUser?.permissions?.includes(PERMISSION_MANAGE_EDIFACT_RULE) ?? false;
  }

  private hasManageNDCPermission(): boolean {
    return this.userDetailsService.loggedInUser?.permissions?.includes(PERMISSION_MANAGE_NDC_RULE) ?? false;
  }
}
