<div class="d-flex flex-wrap">
  <ng-container *ngFor="let item of items">
    <button
      type="button"
      [id]="item.name"
      (click)="toggle(item)"
      [disabled]="item.disabled"
      [ngbTooltip]="item.tooltip"
      [ngClass]="item.active ? 'btn-primary' : 'btn-outline-primary'"
      class="btn btn-sm rounded-pill me-4"
    >
      {{ item.title }}
    </button>
    <div *ngIf="item.endOfLine" class="w-100"></div>
  </ng-container>
</div>
