<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="sendingStatus ?? false"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<div class="mb-3">
  <form [formGroup]="searchCriteriaForm" id="familySearchCriteriaForm" (ngSubmit)="searchClicked()">
    <div class="row">
      <div class="col text-end mb-2">
        <button
          i18n="@@upp.global.button.clear"
          id="clearDisabled"
          type="button"
          [disabled]="sendingStatus"
          class="btn btn-outline-primary btn-sm"
          (click)="clearClicked()"
        >
          Clear
        </button>
      </div>
    </div>

    <div class="mb-4 mt-3">
      <label i18n="@@upp.families.search.nameLabel" for="name" class="form-label">Name </label>
      <input
        type="text"
        class="form-control"
        id="name"
        [formControlName]="elements.NAME"
        [ngClass]="{ 'is-invalid': !searchCriteriaForm.controls['name'].valid }"
        placeholder="Hotel family name"
        i18n-placeholder="@@upp.families.common.namePlaceholder"
        ngbTooltip="Enter the Hotel Family name with letters, numbers or spaces (up to 30 characters)"
        i18n-ngbTooltip="@@upp.families.common.nameTooltip"
        oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
      />
      <div *ngIf="!searchCriteriaForm.controls['name'].valid" class="invalid mt-1">
        <div *ngIf="searchCriteriaForm.controls['name'].errors?.maxlength">
          <ng-container i18n="@@upp.validation.families.search.maximumCharacters"
            >Maximum number of characters acceptable:</ng-container
          >
          {{ searchCriteriaForm.controls['name'].errors?.maxlength?.requiredLength }}
        </div>
        <div
          i18n="@@upp.validation.families.search.typeOfCharacters"
          *ngIf="
            searchCriteriaForm.controls['name'].errors?.pattern &&
            !searchCriteriaForm.controls['name'].errors?.maxlength
          "
        >
          Only alphanumeric characters and spaces are acceptable
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-3" *ngIf="!this.lookup">
      <div class="col mt-4">
        <div role="radiogroup" aria-labelledby="familyTypeSearch">
          <div class="d-flex flex-wrap mb-3">
            <span i18n="@@upp.families.details.hotelFamilyTypeLabel" class="label-criteria" id="familyTypeSearch"
              >Family type</span
            >
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="familyTypeHotelChains"
                [value]="elements.CHAINS"
                [name]="elements.HOTEL_TYPE"
                [formGroup]="searchCriteriaForm"
                [formControlName]="elements.HOTEL_TYPE"
                (change)="changeHotelType(elements.CHAINS)"
                ng-checked="true"
                checked="checked"
              />
              <label i18n="@@upp.families.details.hotelChainLabel" class="form-check-label" for="familyTypeHotelChains">
                Hotel chain
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="familyTypeHotelPropertyCodes"
                [value]="elements.PROPERTY_CODES"
                [name]="elements.HOTEL_TYPE"
                [formGroup]="searchCriteriaForm"
                [formControlName]="elements.HOTEL_TYPE"
                (change)="changeHotelType(elements.PROPERTY_CODES)"
              />
              <label
                i18n="@@upp.families.details.hotelPropertyCodeLabel"
                class="form-check-label"
                for="familyTypeHotelPropertyCodes"
              >
                Hotel property code
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ama-ng-upp-multi-select-badges
          *ngIf="isHotelCriteriaActive(elements.CHAINS)"
          [formElementName]="elements.CHAINS"
          formElementLabel="Hotel chains"
          i18n-formElementLabel="@@upp.global.criteria.hotelChains.label"
          [formGroup]="searchCriteriaForm"
          placeholder="Hotel chain"
          i18n-placeholder="@@upp.global.criteria.hotelChains.placeholder"
          [numberOfBadges]="100"
          [readonlyMode]="readonly"
          [badgeItems]="searchCriteria.hotelChains"
          notFoundText="No hotel chain found"
          i18n-notFoundText="@@upp.global.criteria.hotelChains.notFoundText"
          tooltipText="Enter 2 character hotel chain(s)"
          i18n-tooltipText="@@upp.global.criteria.hotelChains.tooltip"
          [parent]="COMPONENT_NAME"
        >
        </ama-ng-upp-multi-select-badges>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ama-ng-upp-multi-select-badges
          *ngIf="isHotelCriteriaActive(elements.PROPERTY_CODES)"
          [formElementName]="elements.PROPERTY_CODES"
          formElementLabel="Hotel property codes"
          i18n-formElementLabel="@@upp.global.criteria.hotelPropertyCodes.label"
          [formGroup]="searchCriteriaForm"
          placeholder="Hotel property code"
          i18n-placeholder="@@upp.global.criteria.hotelPropertyCodes.placeholder"
          [numberOfBadges]="100"
          [readonlyMode]="readonly"
          [badgeItems]="searchCriteria.hotelPropertyCodes"
          notFoundText="No hotel property code found"
          i18n-notFoundText="@@upp.global.criteria.hotelPropertyCodes.notFoundText"
          tooltipText="Enter 8 character hotel property code(s)"
          i18n-tooltipText="@@upp.global.criteria.hotelPropertyCodes.tooltip"
          [parent]="COMPONENT_NAME"
        >
        </ama-ng-upp-multi-select-badges>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        i18n="@@upp.global.button.search"
        *ngIf="!sendingStatus"
        id="search"
        type="submit"
        class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
        [disabled]="!searchCriteriaForm.valid"
      >
        Search
      </button>

      <button
        i18n="@@upp.global.button.search"
        *ngIf="sendingStatus"
        id="searchIsSending"
        type="submit"
        disabled
        class="btn btn-primary btn-action mb-2 mb-md-0 me-2"
      >
        Search
      </button>
    </div>
  </form>
</div>
