import { AbstractControl, ValidationErrors } from '@angular/forms';

export const includeTwoCarriersValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  if (typeof control.value === 'string') {
    return control.value === 'YY' ? null : { includeTwoOrMoreCarriers: true };
  }

  return control.value.length < 2 && control.value.every((code: string) => code !== 'YY')
    ? { includeTwoOrMoreCarriers: true }
    : null;
};
