import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectLoggedInUser } from '../store/core/selector';
import { LoggedInUser } from './model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  loggedInUser: LoggedInUser | undefined;

  constructor(private readonly store: Store<any>) {
    this.store.pipe(select(selectLoggedInUser)).subscribe((user) => (this.loggedInUser = user));
  }

  getLssOrganization() {
    return this.loggedInUser ? this.loggedInUser.organization : '';
  }

  assignEntity(element: { partitionId?: string; organization?: string }): any {
    if (this.loggedInUser?.partitions) {
      if ('organization' in element) {
        delete element['organization'];
      }
      element.partitionId = this.loggedInUser.selectedPartition;
    } else {
      if ('partitionId' in element) {
        delete element['partitionId'];
      }
      element.organization = this.loggedInUser?.organization;
    }
    return element;
  }

  assignRuleEntity(element?: { partition?: string; organization?: string }): any {
    if (!element) {
      throw new Error('Element is null or undefined.');
    }

    if (this.loggedInUser?.partitions) {
      if ('organization' in element) {
        delete element['organization'];
      }
      element.partition = this.loggedInUser.selectedPartition;
    } else {
      if ('partition' in element) {
        delete element['partition'];
      }
      element.partition = this.loggedInUser?.organization;
    }
    return element;
  }
}
