/**
 *  Gets object's propery value by path.
 */
export const getByPath = (obj: any, path: string | null): any => {
  if (!obj || !path) {
    return null;
  }

  const indexOfSeparator = path.indexOf('.');
  let propertyName = path;
  if (indexOfSeparator > 0) {
    propertyName = path.substring(0, indexOfSeparator);
    path = path.substring(indexOfSeparator + 1);
  } else {
    path = null;
  }

  let innerObj: any;
  const indexOfOpeningBracket = propertyName.indexOf('[');
  if (indexOfOpeningBracket > 0) {
    const arrayIndex = propertyName.substring(indexOfOpeningBracket + 1, propertyName.length - 1);
    propertyName = propertyName.substring(0, indexOfOpeningBracket);
    innerObj = obj[propertyName]?.[parseInt(arrayIndex, 10)];
  } else {
    innerObj = obj[propertyName];
  }

  if (path?.length && path?.length > 0) {
    return getByPath(innerObj, path);
  }

  return innerObj;
};

export function getPropertyByKey<T>(obj: T, key: any): any {
  if (!key) {
    return undefined;
  }

  return obj[key as keyof T];
}
