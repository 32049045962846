<form [formGroup]="actionOrValueForm">
  <label i18n="@@upp.flights.details.actionOrValue.label" class="d-block form-label">
    Action
    <span class="mandatory">*</span>
  </label>
  <div class="d-flex align-items-center w-100 controls-container">
    <button
      i18n="@@upp.flights.details.exclude"
      type="button"
      id="exclude"
      class="btn btn-criteria btn-sm mb-md-0 me-4"
      [ngClass]="isExcludeActive ? 'btn-exclude' : 'btn-outline-exclude'"
      [disabled]="readonly"
      (click)="setExcludeSelected()"
    >
      Exclude
    </button>
    <button
      i18n="@@upp.flights.details.forceInclude"
      type="button"
      id="include"
      class="btn btn-criteria btn-sm mb-md-0 me-4"
      [ngClass]="isIncludeActive ? 'btn-include' : 'btn-outline-include'"
      [disabled]="readonly"
      ngbTooltip="Enter rule content with Exclude action. Then select Force include to override the rule."
      i18n-ngbTooltip="@@upp.flights.details.forceIncludeAction.tooltip"
      (click)="setIncludeSelected()"
    >
      Force include
    </button>
    <button
      *ngIf="allowSorting"
      i18n="@@upp.flights.details.sort"
      type="button"
      id="sort"
      class="btn btn-criteria btn-sm mb-md-0 me-4"
      [ngClass]="isSortActive ? 'btn-sort' : 'btn-outline-sort'"
      [disabled]="readonly"
      ngbTooltip="Enter a sort action value between -100 and 100."
      i18n-ngbTooltip="@@upp.flights.details.sortAction.tooltip"
      (click)="toggleSortSelected()"
      data-test="sort-button"
    >
      Sort
    </button>
    <ng-container *ngIf="allowSorting && (isSortActive || (isSortActive && readonly))">
      <button
        *ngIf="isSortActive && !readonly"
        type="button"
        class="btn btn-outline-sort btn-sort-value btn-sm mb-md-0"
        (click)="decrementSortValue()"
        data-test="sort-decrement-button"
        >-</button
      >
      <input
        formControlName="value"
        id="value"
        [ngClass]="{ 'is-invalid': !valueControl.valid && valueControl.touched }"
        type="number"
        class="text-center mt-2 mr-2 mx-2"
        [readonly]="readonly"
        [disabled]="readonly"
        type="text"
        data-test="sort-value-field"
      />
      <button
        *ngIf="isSortActive && !readonly"
        type="button"
        class="btn btn-outline-sort btn-sort-value btn-sm mb-md-0"
        (click)="incrementSortValue()"
        data-test="sort-increment-button"
        >+</button
      >
    </ng-container>
  </div>
</form>
