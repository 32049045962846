import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { MarketsCreateComponent } from '../../markets/markets-create/markets-create.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateMarketCreateGuard {
  constructor(private readonly guardService: GuardService) {}
  canDeactivate(component: MarketsCreateComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
