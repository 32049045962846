import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from './service/login.service';
import { combineLatest, filter, Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import {
  selectClpError,
  selectLoggedIn,
  selectLoggedInUser,
  selectClpConfig,
  selectConfiguration
} from './store/core/selector';
import { LoggedInUser } from './service/model';
import { ClpInitConfig, LoginConfigService } from '@seco/login';
import { selectNavigation } from './store/navigation/navigation-selector';
import { PlatformLocation } from '@angular/common';
import { SetNavigationParams } from './store/navigation/navigation-actions';
import { ConfigurationOutput } from '@seco/core';
import { GetAllAvailablePosAction } from './store/pos/pos-actions';
import { GetAllAvailableMarketsAction } from './store/markets/markets-actions';
import {
  GetAllAvailableFamilyHotelChainsAction,
  GetAllAvailableFamilyHotelPropertyCodesAction
} from './store/families/families-action';
import { getAllAvailableAirFamilies } from './store/families/air/air-families-action';
import { jwtDecode, JwtPayload } from 'jwt-decode';

interface CustomJwtPayload extends JwtPayload {
  UM_APPLICATION_ID: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn$: Observable<boolean>;
  partitionCallDone$!: Observable<boolean>;
  loggedInUser$!: Observable<LoggedInUser>;
  loggedInUser!: LoggedInUser;

  reefConfiguration$: Observable<ConfigurationOutput | undefined>;
  clpError$: Observable<any>;
  appName = 'Amadeus Unified Preferences Platform';
  errorMessagePartition = $localize`@@upp.global.text.partitionError:We couldn't sign you in. Try again,\
or contact your administrator if the issue persists.`;
  lookup = false;

  constructor(
    private readonly store: Store<any>,
    private readonly loginConfigService: LoginConfigService,
    private readonly loginService: LoginService,
    location: PlatformLocation
  ) {
    //this.loginService.getClpConfig();
    this.loggedIn$ = this.store.pipe(select(selectLoggedIn));
    this.store.pipe(select(selectLoggedInUser), filter<LoggedInUser | undefined>(Boolean)).subscribe((user) => {
      const organization = user?.selectedPartition ? user.selectedPartition : user?.organization;
      this.store.dispatch(new GetAllAvailablePosAction(organization));
      this.store.dispatch(new GetAllAvailableMarketsAction(organization));
      this.store.dispatch(getAllAvailableAirFamilies(organization));
      this.store.dispatch(new GetAllAvailableFamilyHotelChainsAction(organization));
      this.store.dispatch(new GetAllAvailableFamilyHotelPropertyCodesAction(organization));

      this.loggedInUser$ = of(user);
    });

    this.reefConfiguration$ = this.store.pipe(select(selectConfiguration));
    this.clpError$ = this.store.pipe(select(selectClpError));
    this.store.select(selectClpConfig).subscribe((config) => {
      if (!config?.token) {
        return;
      }
      const decoded = jwtDecode<CustomJwtPayload>(config.token);
      //TODO: change clpAppId to 'FOA' FRONTO-50082
      this.loginConfigService.setConfig({
        clpAppId: decoded['UM_APPLICATION_ID'] === 'UPP' ? 'UPP' : 'FOA',
        language: 'en_gb',
        clpConfig: this.store
          .select(selectClpConfig)
          .pipe(filter((config): config is ClpInitConfig => config !== undefined)),
        useSSO: false,
        onSuccess: this.loginService.loginByAccessToken.bind(this.loginService)
      });

      location.onPopState(() => {
        this.store.dispatch(new SetNavigationParams({}));
      });
      combineLatest([this.loggedIn$, this.reefConfiguration$]).subscribe(([loggedIn, configuration]) => {
        if (loggedIn) {
          if (configuration?.moduleParameters['upp'].parameters['UPP_PARTITIONS_A']) {
            this.loginService.getABRPartitions().then((reply: any) => {
              if (reply.error) {
                this.clpError$ = of([{ error: { message: this.errorMessagePartition }, code: reply.code }]);
              } else {
                this.partitionCallDone$ = of(true);
              }
            });
          } else {
            this.partitionCallDone$ = of(true);
          }
        }
      });
    });
  }

  // Clean JSESSIONID cookie before closure
  @HostListener('window:unload', ['$event'])
  clearBeforeClosure() {
    this.signOffUser();
  }

  ngOnInit(): void {
    this.initLookup();
  }

  initLookup() {
    this.store.pipe(select(selectNavigation)).subscribe((lookupParam) => {
      this.lookup = lookupParam && lookupParam.lookup !== undefined ? lookupParam.lookup : false;
    });
  }

  signOffUser() {
    this.loginService.logOutUser();
  }
}
