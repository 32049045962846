import { AfterContentInit, Component, EventEmitter, Injector, Input, OnDestroy, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { LookupOptions } from '../../core/util/lookup-options';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectNavigation } from '../../store/navigation/navigation-selector';
import { take } from 'rxjs/operators';
import { UppViewNames } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';

@Component({
  selector: 'ama-ng-upp-lookup-select',
  templateUrl: './lookup-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LookupSelectComponent,
      multi: true
    }
  ]
})
export class LookupSelectComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() id?: string;
  @Input() items: any[] = [];
  @Input() multiple?: boolean;
  @Input() addTag?: boolean;
  // New tags will be added in uppercase. Set to false to disable.
  @Input() addUppercase = true;
  @Input() numberOfBadges = 100;
  @Input() placeholder?: string;
  @Input() tooltip?: string;
  @Input() notFoundText?: string;

  @Input() bindLabel?: string;
  @Input() bindValue?: string;

  // Grouping properties
  @Input() groupBy?: string;
  @Input() groupValue?: (groupKey: string, children: any[]) => any;
  @Input() selectableGroupAsModel = false;
  @Input() selectableGroup = false;

  @Input() lookupOptions?: LookupOptions;

  @Output() closed = new EventEmitter<void>();
  @Output() changed = new EventEmitter<any>();

  private readonly REQUIRED_LOOKUP_SELECT_ERROR = 'Control is required for lookup-select component';

  _onChange: any;
  _onTouched: any;
  _disabled!: boolean;
  _value: any;

  // separator processed are ',', ';' and space character
  defaultPasteRegExp = /[,;\s]/;

  control!: AbstractControl | null;
  controlName!: string | null;

  lookupSubscription!: Subscription;

  constructor(
    private readonly injector: Injector,
    private readonly store: Store,
    private readonly navigationService: NavigationService
  ) {}

  ngAfterContentInit(): void {
    this.control = this.injector.get(NgControl).control;

    if (!this.control) {
      throw new Error(this.REQUIRED_LOOKUP_SELECT_ERROR);
    }

    this.controlName = this.getControlName(this.control);

    if (!this.lookupOptions) {
      return;
    }

    this.lookupSubscription = this.store.pipe(select(selectNavigation), take(1)).subscribe((returnedLookupOptions) => {
      const path = this.buildPath(this.control);
      if (
        !returnedLookupOptions?.data ||
        path !== `${returnedLookupOptions.fieldPath}.${returnedLookupOptions.fieldName}`
      ) {
        return;
      }

      if (this.multiple) {
        this.control?.setValue(returnedLookupOptions.data);
      } else {
        this.control?.setValue(returnedLookupOptions.data.length > 0 ? returnedLookupOptions.data[0] : null);
      }
      this.navigationService.clearLookupData();
    });
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  onChange(_event?: any): void {
    this._onChange(this._value);
    this.changed.emit(this._value);
  }

  onPaste(_event: any): void {
    // required to add the entry into the input field
    _event.stopPropagation();
    // required to stop showing the suggestion message
    _event.preventDefault();

    // If items are objects and not strings ignore paste event.
    if (this.bindLabel || this.bindValue || (this.items.length > 0 && typeof this.items[0] !== 'string')) {
      return;
    }

    const pastedValue: string = _event.clipboardData.getData('text/plain')?.trim();
    if (pastedValue) {
      if (this.multiple) {
        const currentValue = this._value ?? [];

        const pastedValues = this.getValidPastedValues(pastedValue, currentValue);
        this._value = [...currentValue, ...pastedValues];
        this.onChange();

        // Add new items to the dropdown as if a tag is added manually, not with paste.
        const newItems = pastedValues.filter(
          (val) => this.items.findIndex((item) => item.toLowerCase() === val?.toLowerCase()) === -1
        );
        this.items = [...this.items, ...newItems];
      } else {
        this._value = this.normalizeValue(pastedValue);
        this.items = [this._value];
        this.onChange();
      }
    }
  }

  addValue(newValue: string): string | null {
    return this.normalizeValue(newValue);
  }

  onClose(): void {
    this._onTouched();
    this.closed.emit();
  }

  openLookup(): void {
    this.navigationService.disableNavigation();
    // Set lookup field name and path, so the user shouldn't do it manually every time.
    const fieldName = this.controlName;
    const path = this.buildPath(this.control);
    const fieldPath = path.slice(0, path.lastIndexOf('.'));

    const navigationParams: LookupOptions = {
      destinationComponent: `${this.lookupOptions?.destinationComponent}/${UppViewNames.SEARCH}/`,
      sourceComponent: this.lookupOptions?.sourceComponent,
      sourceView: this.lookupOptions?.sourceView,
      lookup: true,
      data: this.multiple ? this.control?.value : this.control?.value ? [this.control.value] : [],
      fieldName,
      fieldPath,
      singleSelect: !this.multiple
    };
    this.navigationService.navigate(navigationParams);
  }

  isIndeterminate(items: { selected: any; children: any[] }): boolean {
    if (items.selected) {
      return false;
    }
    if (items.children) {
      const childrenCount = items.children.length;
      const selectedCount = items.children.filter((x) => x.selected).length;
      return selectedCount > 0 && childrenCount > selectedCount;
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
  }

  private normalizeValue(newValue: string): string | null {
    if (newValue?.trim().length > 0) {
      return this.addUppercase ? newValue.trim().toUpperCase() : newValue.trim();
    }
    return null;
  }

  private getValidPastedValues(pastedValue: string, currentValue: string[]): (string | null)[] {
    return pastedValue
      .split(this.defaultPasteRegExp)
      .filter((val) => {
        val = val.trim();
        if (val.length === 0) {
          return false;
        }

        // If adding tags is not allowed and pasted value is not one of items then don't add it.
        if (!this.addTag && this.items.findIndex((item) => item.toLowerCase() === val.toLowerCase()) === -1) {
          return false;
        }
        // If already present in current values don't add it (avoid duplicates).
        return currentValue.findIndex((item) => item.toLowerCase() === val.toLowerCase()) === -1;
      })
      .map((val) => this.normalizeValue(val))
      .filter((val) => val !== null);
  }

  /**
   * Builds full path to the given control by recursively traversing parents.
   * @param control to get the path for.
   * @param currentPath empty string by default
   * @returns full path to the control (including control's name) in the form group hierarchy.
   */
  private buildPath(control?: AbstractControl | null, currentPath = ''): string {
    const parent = control?.parent;
    if (parent) {
      if (parent instanceof FormArray) {
        const index = parent.controls.indexOf(control);
        const parentName = this.getControlName(parent);
        return this.buildPath(parent?.parent, `${parentName}[${index}]${currentPath ? '.' : ''}${currentPath}`);
      } else {
        currentPath = `${this.getControlName(control)}${currentPath ? '.' : ''}${currentPath}`;
        return this.buildPath(parent, currentPath);
      }
    } else {
      return currentPath;
    }
  }

  private getControlName(control: AbstractControl): string | null {
    if (control.parent) {
      return (
        Object.keys(control.parent.controls).find((controlName) => control.parent?.get(controlName) === control) ?? null
      );
    }
    return null;
  }
}
