import { NgModule } from '@angular/core';
import { TableSortDirective } from '../core/util/table-sort.directive';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UppNotificationComponent } from './upp-notification/upp-notification.component';
import { LoadingAlertComponent } from './loading-alert/loading-alert.component';
import { ShowValidationErrorComponent } from './show-validation-error/show-validation-error.component';
import { AutoFocusDirective } from './auto-focus/auto-focus.directive';
import { CriteriaListComponent } from './criteria/criteria-list/criteria-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MultiSelectBadgesComponent } from './multi-select-badges/multi-select-badges.component';
import { DfSelectModule } from '@design-factory/design-factory';
import { PipesModule } from '../core/util/pipes/pipes.module';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { LookupSelectComponent } from './lookup-select/lookup-select.component';
import { ValidationErrorMessageComponent } from './validation-error-message/validation-error-message.component';
import { CheckboxMultiselectComponent } from './checkbox-multiselect/checkbox-multiselect.component';

@NgModule({
  imports: [CommonModule, NgbModule, ReactiveFormsModule, FormsModule, DfSelectModule, PipesModule],
  declarations: [
    TableSortDirective,
    UppNotificationComponent,
    AutoFocusDirective,
    LoadingAlertComponent,
    ShowValidationErrorComponent,
    CriteriaListComponent,
    MultiSelectBadgesComponent,
    ButtonGroupComponent,
    LookupSelectComponent,
    ValidationErrorMessageComponent,
    CheckboxMultiselectComponent
  ],
  exports: [
    TableSortDirective,
    UppNotificationComponent,
    LoadingAlertComponent,
    ShowValidationErrorComponent,
    AutoFocusDirective,
    CriteriaListComponent,
    MultiSelectBadgesComponent,
    ButtonGroupComponent,
    LookupSelectComponent,
    ValidationErrorMessageComponent,
    CheckboxMultiselectComponent
  ]
})
export class ComponentsModule {}
