export interface HotelRule {
  organization: string;
  name: string;
  id?: string;
  version?: number;
  description?: string;
  active: boolean;
  action: string;
  hotelApplicability: HotelApplicability;
  weight?: number;
}

export interface HotelApplicability {
  pointOfSales?: Location[];
  destinations?: Location[];
  sourceTypes?: string[];
  masterChains?: string[];
  chains?: string[];
  hotelChainsFamilies?: HotelChainsFamily[];
  propertyCodes?: string[];
  hotelPropertyCodesFamilies?: HotelPropertyCodesFamily[];
  rateCodes?: string[];
  commissionable?: boolean;
  rateFamilies?: string[];
}

export interface HotelChainsFamily {
  name: string;
}

export interface HotelPropertyCodesFamily {
  name: string;
}

export const initialHotelRule: HotelRule = {
  organization: '',
  name: '',
  active: false,
  action: '',
  hotelApplicability: {}
};

export interface Location {
  name: string;
  id?: string;
}

export const locationEquals = (l1: Location, l2: Location): boolean => l1.name === l2.name && l1.id === l2.id;
