import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { initialFlightRuleSearchCriteria, JourneySearchCriteriaUi } from '../../model';
import { UppComponentNames, UppViewNames } from '../../../service/model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { selectAllAvailablePosRecords } from '../../../store/pos/pos-selector';
import { LookupOptions } from '../../../core/util/lookup-options';
import { PERMISSION_VIEW_MARKET, PERMISSION_VIEW_POS } from '../../../service/user-permissions.service';
import { UserDetailsService } from '../../../service/user-details.service';
import { selectAllAvailableMarketsRecords } from '../../../store/markets/markets-selector';
import { SetFlightRuleSearchValueAction } from '../../../store/flight/flights-action';
import { selectFlightRuleSearchValue } from '../../../store/flight/flights-selector';
import { Subscription } from 'rxjs';
import { ValueLabelItem } from '../../../model/value-label-item';
import { ContentType } from '../../model/journey';

@Component({
  selector: 'app-flight-search-criteria',
  templateUrl: './flight-search-criteria.component.html',
  styleUrls: ['./flight-search-criteria.component.scss']
})
export class FlightSearchCriteriaComponent implements OnInit, OnDestroy {
  @Output() searchFlights = new EventEmitter<JourneySearchCriteriaUi>();

  @Input() sendingStatus = false;

  searchCriteriaForm!: UntypedFormGroup;
  searchCriteria: JourneySearchCriteriaUi = initialFlightRuleSearchCriteria;

  availablePosNames: string[] = [];
  posLookupOptions?: LookupOptions;
  firstMarketLookupOptions?: LookupOptions;
  secondMarketLookupOptions?: LookupOptions;
  availableMarketsNames: string[] = [];
  airContentTypeItems: ValueLabelItem<string>[] = [];

  readonly UppComponentNames = UppComponentNames;
  private readonly subscription = new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store,
    private readonly userDetailsService: UserDetailsService
  ) {}

  ngOnInit(): void {
    this.airContentTypeItems = [
      {
        value: ContentType.EDIFACT,
        label: 'EDIFACT'
      },
      {
        value: ContentType.NDC,
        label: 'NDC'
      }
    ];

    this.createForm();

    this.subscription.add(
      this.store.pipe(select(selectAllAvailablePosRecords)).subscribe((result) => {
        if (result != null) {
          this.availablePosNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((x) => x !== undefined);
        }
      })
    );

    this.subscription.add(
      this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
        if (result != null) {
          this.availableMarketsNames = result
            .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
            .filter((x) => x !== undefined);
        }
      })
    );

    this.subscription.add(
      this.store.pipe(select(selectFlightRuleSearchValue)).subscribe((value) => this.loadForm(value))
    );

    this.posLookupOptions = this.getPosMarketNamesLookupOptions();
    this.firstMarketLookupOptions = this.getFirstMarketNamesLookupOptions();
    this.secondMarketLookupOptions = this.getSecondMarketNamesLookupOptions();
  }

  loadForm(value: JourneySearchCriteriaUi) {
    this.searchCriteria = value;
    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  createForm() {
    this.searchCriteriaForm = this.formBuilder.group({});

    this.searchCriteriaForm.addControl(
      'rule',
      this.formBuilder.group({
        id: [],
        version: ['1.0'],
        organization: [this.userDetailsService.getLssOrganization()],
        name: this.formBuilder.control('', [Validators.maxLength(30)]),
        ruleStatus: this.formBuilder.control('all')
      })
    );
    this.searchCriteriaForm.addControl(
      'applicability',
      this.formBuilder.group({
        pointOfSaleNames: this.formBuilder.control(''),
        firstMarketNames: this.formBuilder.control(''),
        secondMarketNames: this.formBuilder.control(''),
        contentTypes: this.formBuilder.control([])
      })
    );
  }

  searchClicked() {
    this.searchFlights.emit(this.searchCriteriaForm.value);
  }

  clearClicked() {
    this.searchCriteria = initialFlightRuleSearchCriteria;
    this.searchCriteriaForm.patchValue(this.searchCriteria);
  }

  private getPosMarketNamesLookupOptions(): LookupOptions | undefined {
    if (this.canLookupPos()) {
      return {
        destinationComponent: UppComponentNames.POS,
        sourceComponent: UppComponentNames.FLIGHTS,
        sourceView: UppViewNames.SEARCH,
        fieldPath: `applicability.pointOfSaleNames`,
        singleSelect: true
      };
    }
    return undefined;
  }

  private getFirstMarketNamesLookupOptions(): LookupOptions | undefined {
    if (this.canLookupMarket()) {
      return {
        destinationComponent: UppComponentNames.MARKETS,
        sourceComponent: UppComponentNames.FLIGHTS,
        sourceView: UppViewNames.SEARCH,
        fieldPath: `firstMarketNames`,
        singleSelect: true
      };
    }
    return undefined;
  }

  private getSecondMarketNamesLookupOptions(): LookupOptions | undefined {
    if (this.canLookupMarket()) {
      return {
        destinationComponent: UppComponentNames.MARKETS,
        sourceComponent: UppComponentNames.FLIGHTS,
        sourceView: UppViewNames.SEARCH,
        fieldPath: `secondMarketNames`,
        singleSelect: true
      };
    }
    return undefined;
  }

  storeUnsavedFlightSearch() {
    this.store.dispatch(new SetFlightRuleSearchValueAction({ flightSearchCriteria: this.searchCriteriaForm.value }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  private canLookup(permisionName: string): boolean {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === permisionName) ?? false;
  }

  private canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }
}
