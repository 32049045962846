import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ExclusionKeys, ReferenceKeys } from './fare-criteria-data';
import { FareServiceElements, ServiceType } from './fare-service-elements';

export class FareFormValidator {
  hasMandatoryData(fareDetailsForm: UntypedFormGroup): boolean {
    if (!fareDetailsForm) {
      return false;
    }

    const referenceForm = fareDetailsForm.get(FareServiceElements.REFERENCE);
    const exclusionForm = fareDetailsForm.get(FareServiceElements.EXCLUSION);

    if (!referenceForm || !exclusionForm) {
      return false;
    }

    const hasMandatoryReference =
      this.hasValueInForm(referenceForm, ReferenceKeys.POS) &&
      this.hasValueInForm(referenceForm, ReferenceKeys.OM) &&
      this.hasValueInForm(referenceForm, ReferenceKeys.DM);

    let hasMandatoryConnectionsNum = true;
    if (exclusionForm.get(ExclusionKeys.ST)) {
      const serviceTypesSelected: string[] = exclusionForm.get(ExclusionKeys.ST)?.value;
      if (
        serviceTypesSelected &&
        (serviceTypesSelected.includes(ServiceType.ALL) || serviceTypesSelected.includes(ServiceType.C))
      ) {
        if (!this.hasValueInForm(exclusionForm, FareServiceElements.NUMBER_OF_CONNECTIONS)) {
          hasMandatoryConnectionsNum = false;
        }
      }
    }
    const hasMandatoryExclusion =
      this.hasValueInForm(exclusionForm, ExclusionKeys.CNT) &&
      this.hasValueInForm(exclusionForm, ExclusionKeys.PR) &&
      this.hasValueInForm(exclusionForm, FareServiceElements.PRICE_DIFFERENCE_MIN) &&
      this.hasValueInForm(exclusionForm, FareServiceElements.PRICE_DIFFERENCE_MAX);
    return hasMandatoryReference && hasMandatoryExclusion && hasMandatoryConnectionsNum;
  }

  private hasValueInForm(form: AbstractControl, formControlName: string): boolean {
    if (form.get(formControlName)) {
      const currentValue = form.get(formControlName)?.value;
      if (typeof currentValue === 'string' || Array.isArray(currentValue)) {
        return currentValue.length > 0;
      }
      if (typeof currentValue === 'number') {
        return true;
      }
    }
    return false;
  }
}
