<img class="card-img-top" src="{{ card.imageSrc }}" alt="{{ card.imageAlt }}" />
<div class="card-body">
  <h3 class="card-title">{{ card.title }}</h3>
  <p class="card-text">{{ card.description }}</p>
  <p class="card-text">
    @for (button of card.buttons; track card.title; let last = $last) {
    <button routerLink="{{ button.link }}" [class.me-3]="!last" type="button" class="btn btn-primary btn-sm">
      {{ button.label }}
    </button>
    }
  </p>
</div>
