import { FareRuleUi, initialFareRuleUi } from '../../fares/model/fare-rule-ui';
import { UppNotification } from '../../model/notification';
import { shouldNotificationCleared } from '../helpers/reducer-helper';
import { FaresActions, FARES_ACTION } from './fares-action';
import { FareRuleStatus, FaresState, initialFareRuleStatus, initialFaresState } from './fares-state';

export const faresReducer = (state = initialFaresState, action: FaresActions): FaresState => {
  if (FareReducerMethods.has(action.type)) {
    const reducerMethod = FareReducerMethods.get(action.type);

    if (reducerMethod) {
      return reducerMethod(state, action);
    }
  }
  return state;
};

const getInitialFaresState = (state = initialFaresState): FaresState => initialFaresState;

const create = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    create: {
      sending: true,
      value: state.fareRule.create.value
    }
  },
  notification: {
    ...state.notification,
    create: {}
  }
});

const setCreateValue = (state = initialFaresState, action: FaresActions): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    create: {
      ...state.fareRule.create,
      value: action.payload.value
    }
  }
});

const finishCreation = (state = initialFaresState, action: FaresActions): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    create: {
      sending: false,
      value: state.fareRule.create.value,
      updatedRule: action.payload.fareRule
    }
  },
  notification: {
    ...state.notification,
    create: action.payload.fareRule.statusNotification
  }
});

const setCredeleteCreateNotificationateValue = (state = initialFaresState): FaresState => ({
  ...state,
  notification: {
    ...state.notification,
    create: {}
  }
});

const activateDisplay = (state = initialFaresState, action: FaresActions): FaresState => {
  let displayValue = state.fareRule.display.value;
  if (action.payload.id) {
    const ruleToDisplay = findRuleWithId(state.fareRules, action.payload.id);
    if (ruleToDisplay) {
      displayValue = ruleToDisplay;
    }
  }
  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      display: {
        ...state.fareRule.display,
        value: displayValue,
        active: true
      }
    }
  };
};

const deleteDisplayNotification = (state = initialFaresState): FaresState => ({
  ...state,
  notification: {
    ...state.notification,
    display: {}
  }
});

const activateDispay = (state = initialFaresState): FaresState => {
  let rule = state.fareRule.display.value;
  if (state.fareRule.create.updatedRule) {
    rule = state.fareRule.create.updatedRule;
  }
  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      display: {
        sending: false,
        value: rule,
        active: true
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const search = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    search: {
      ...state.fareRule.search,
      sending: true
    }
  }
});

const setList = (state = initialFaresState, action: FaresActions): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    search: {
      ...state.fareRule.search,
      sending: false
    }
  },
  fareRules: action.payload.fareRules,
  notification: {
    ...state.notification,
    search: action.payload.fareRules[0]?.statusNotification
  }
});

const clearList = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    search: {
      ...state.fareRule.search,
      sending: false
    },
    display: initialFareRuleStatus
  },
  fareRules: []
});

const deleteSearchNotification = (state = initialFaresState): FaresState => ({
  ...state,
  notification: {
    ...state.notification,
    search: {}
  }
});

const setSearchValue = (state = initialFaresState, action: FaresActions): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    search: {
      sending: state.fareRule.search.sending,
      value: action.payload.fareSearchCriteria
    }
  }
});

const deleteRule = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    delete: {
      ...state.fareRule.delete,
      sending: true
    }
  }
});

const deleteClearStore = (state = initialFaresState, action: FaresActions): FaresState => {
  const clearFareRuleId = action.payload.fare.persistentId;

  const currentValueOfDeletedRuleDisplay = getFareRuleValueAfterDelete(clearFareRuleId, state.fareRule.display.value);
  const currentValueOfDeletedRuleModify = getFareRuleValueAfterDelete(clearFareRuleId, state.fareRule.modify.value);
  const updatedValueOfDeletedRule = getUpdatedFareRuleAfterDelete(clearFareRuleId, state.fareRule.create.updatedRule);

  if (!currentValueOfDeletedRuleDisplay) {
    throw new Error('currentValueOfDeletedRecordDisplay is null or undefined');
  }

  if (!currentValueOfDeletedRuleModify) {
    throw new Error('currentValueOfDeletedRecordModify is null or undefined');
  }

  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      display: {
        ...state.fareRule.display,
        value: currentValueOfDeletedRuleDisplay,
        active: checkFareRuleIdActivation(clearFareRuleId, state.fareRule.display)
      },
      create: {
        ...state.fareRule.create,
        updatedRule: updatedValueOfDeletedRule
      },
      modify: {
        ...state.fareRule.modify,
        value: currentValueOfDeletedRuleModify,
        active: checkFareRuleIdActivation(clearFareRuleId, state.fareRule.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearFareRuleId) ? {} : state.notification?.create
    }
  };
};

const deleteFromList = (state = initialFaresState, action: FaresActions): FaresState => {
  let rules = state.fareRules;
  let statusNotification: UppNotification = action.payload.deletedRule.statusNotification;
  if (statusNotification.success) {
    rules = deleteRuleFromList(action.payload.deletedRule.id, state.fareRules);
    statusNotification = {};
  }

  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      delete: {
        ...state.fareRule.delete,
        sending: false
      }
    },
    fareRules: rules,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const setModifyValue = (state = initialFaresState, action: FaresActions): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    modify: {
      ...state.fareRule.modify,
      value: action.payload.value
    }
  }
});

const startModification = (state = initialFaresState, action: FaresActions): FaresState => {
  const modificationValue = getFareModifyValue(state, action.payload.id);
  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      modify: {
        ...state.fareRule.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.fareRule.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const update = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    modify: {
      sending: true,
      value: state.fareRule.modify.value,
      active: state.fareRule.modify.active
    }
  },
  notification: {
    ...state.notification,
    modify: {}
  }
});

const cancelModification = (state = initialFaresState): FaresState => ({
  ...state,
  fareRule: {
    ...state.fareRule,
    modify: {
      sending: state.fareRule.modify.sending,
      value: initialFareRuleUi,
      active: false
    }
  },
  notification: {
    ...state.notification,
    modify: {},
    display: {}
  }
});

const updateSuccessValues = (state = initialFaresState, action: FaresActions): FaresState => {
  let updatedDisplayValue = state.fareRule.display.value;
  if (action.payload.updatedFareRule) {
    updatedDisplayValue = action.payload.updatedFareRule;
  }
  const updatedFareRules = updateUpdatedFareRules(state.fareRules, action.payload.updatedFareRule);
  return {
    ...state,
    fareRule: {
      ...state.fareRule,
      display: {
        ...state.fareRule.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.fareRule.modify.sending,
        value: initialFareRuleUi,
        active: false
      }
    },
    fareRules: updatedFareRules
  };
};

const finishUpdate = (state = initialFaresState, action: FaresActions): FaresState => ({
    ...state,
    fareRule: {
      ...state.fareRule,
      modify: {
        ...state.fareRule.modify,
        sending: false
      },
      create: {
        ...state.fareRule.create,
        updatedRule: getUpdatedFareRule(state.fareRule.create.updatedRule, action.payload.updatedFareRule)
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedFareRule.statusNotification.warning,
        error: action.payload.updatedFareRule.statusNotification.error
      },
      display: {
        success: action.payload.updatedFareRule.statusNotification.success
      }
    }
  });

const deleteModifyNotification = (state = initialFaresState): FaresState => ({
  ...state,
  notification: {
    ...state.notification,
    modify: {}
  }
});

export const findRuleWithId = (fares: FareRuleUi[], ruleId: string): FareRuleUi | undefined => {
  if (!fares || fares.length === 0) {
    return undefined;
  }
  return fares.find((fare) => fare.id === ruleId);
};

const getFareModifyValue = (state: FaresState, ruleId: string): FareRuleUi => {
  let modificationValue = state.fareRule.modify.value;
  if (ruleId) {
    const recordToModify = findRuleWithId(state.fareRules, ruleId);
    if (recordToModify) {
      modificationValue = recordToModify;
    }
  }
  return modificationValue;
};

const getUpdatedFareRule = (createFareRule: FareRuleUi | undefined, lastFareRule: FareRuleUi | undefined) => {
  if (createFareRule && lastFareRule && createFareRule.id === lastFareRule.id) {
    return lastFareRule;
  } else {
    return createFareRule;
  }
};

export const updateUpdatedFareRules = (fareRules: FareRuleUi[], fareRuleToUpdate: FareRuleUi): FareRuleUi[] => {
  if (!fareRules || fareRules.length === 0) {
    return [fareRuleToUpdate];
  }

  return fareRules.map((obj) => [fareRuleToUpdate].find((record) => record.id === obj.id) || obj);
};

const getFareRuleValueAfterDelete = (id: string, fareRule: FareRuleUi): FareRuleUi | undefined => {
  if (!fareRule) {
    return;
  }
  if (fareRule.id === id) {
    return initialFareRuleUi;
  }
  return fareRule;
};

const checkFareRuleIdActivation = (id: string, fareRuleStatus: FareRuleStatus): boolean => {
  if (!fareRuleStatus) {
    return false;
  }

  if (fareRuleStatus.active && fareRuleStatus.value && fareRuleStatus.value.id === id) {
    return false;
  } else {
    return fareRuleStatus.active ?? false;
  }
};

const getUpdatedFareRuleAfterDelete = (id: string, fareRule?: FareRuleUi): FareRuleUi | undefined => {
  if (!fareRule) {
    return;
  }
  if (fareRule.id === id) {
    return undefined;
  }

  return fareRule;
};

const deleteRuleFromList = (id: string, fareRules: FareRuleUi[]): FareRuleUi[] => {
  if (!id || !fareRules) {
    return [];
  }
  return fareRules.filter((fare) => fare.id !== id);
};

export const FareReducerMethods: Map<string, (state: FaresState, action: FaresActions) => FaresState> = new Map<
  string,
  (state: FaresState, action: FaresActions) => FaresState
>([
  [FARES_ACTION.INIT_FARE_RULE_STATE, getInitialFaresState],
  [FARES_ACTION.CREATE_FARE_RULE, create],
  [FARES_ACTION.SET_CREATE_FARE_RULE_VALUE, setCreateValue],
  [FARES_ACTION.FINISH_FARE_RULE_CREATION, finishCreation],
  [FARES_ACTION.DELETE_FARE_RULE_CREATE_NOTIFICATION, setCredeleteCreateNotificationateValue],
  [FARES_ACTION.ACTIVATE_FARE_RULE_DISPLAY, activateDisplay],
  [FARES_ACTION.DELETE_FARE_RULE_DISPLAY_NOTIFICATION, deleteDisplayNotification],
  [FARES_ACTION.ACTIVATE_CREATED_FARE_RULE_DISPLAY, activateDispay],
  [FARES_ACTION.SEARCH_FARE_RULE, search],
  [FARES_ACTION.SET_FARE_RULE_LIST, setList],
  [FARES_ACTION.CLEAR_FARE_RULE_LIST, clearList],
  [FARES_ACTION.DELETE_FARE_RULE_SEARCH_NOTIFICATION, deleteSearchNotification],
  [FARES_ACTION.SET_FARE_RULE_SEARCH_VALUE, setSearchValue],
  [FARES_ACTION.DELETE_FARE_RULE, deleteRule],
  [FARES_ACTION.DELETE_FARE_RULE_CLEAR_STORE, deleteClearStore],
  [FARES_ACTION.DELETE_FARE_RULE_FROM_LIST, deleteFromList],
  [FARES_ACTION.SET_FARE_MODIFY_VALUE, setModifyValue],
  [FARES_ACTION.START_FARE_MODIFICATION, startModification],
  [FARES_ACTION.UPDATE_FARE, update],
  [FARES_ACTION.CANCEL_FARE_MODIFICATION, cancelModification],
  [FARES_ACTION.SET_FARE_UPDATE_SUCCESS_VALUES, updateSuccessValues],
  [FARES_ACTION.FINISH_FARE_UPDATE, finishUpdate],
  [FARES_ACTION.DELETE_FARE_RULE_MODIFY_NOTIFICATION, deleteModifyNotification]
]);
