import { ALL_ACTION, RULE_STATUS_ALL } from './flight-rule-ui';
import { ContentType } from './journey';

export interface JourneySearchCriteria {
  rule: {
    organization?: string;
    partitionId?: string;
    name?: string;
    active?: boolean;
    action?: string;
  };

  applicability?: {
    pointOfSale?: {
      names: string[];
    };
    marketPairs?: {
      firstMarket?: {
        names: string[];
      };
      secondMarket?: {
        names: string[];
      };
    };
    carriers?: string[];
    contentTypes?: ContentType[];
  };
}

export interface JourneySearchCriteriaUi {
  rule: {
    organization: string;
    name?: string;
    ruleStatus: string;
    actionType: string;
  };
  applicability?: {
    pointOfSaleNames: string[];
    firstMarketNames: string[];
    secondMarketNames: string[];
    contentTypes: ContentType[];
  };
}

export const initialFlightRuleSearchCriteria: JourneySearchCriteriaUi = {
  rule: {
    name: '',
    organization: '',
    ruleStatus: RULE_STATUS_ALL,
    actionType: ALL_ACTION
  },
  applicability: {
    pointOfSaleNames: [],
    firstMarketNames: [],
    secondMarketNames: [],
    contentTypes: []
  }
};
