<h1 i18n="@@upp.hotels.search.title">Search Hotel Rules</h1>

<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="(hotelRuleDeleteSending$ | async) ?? false"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<ama-upp-notification
  id="markets-search-notification"
  class="col"
  [mainMessages]="mainMessages"
  [notification]="(notification$ | async)!"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-hotels-search-criteria
  (searchHotels)="searchHotelRules($event)"
  [sendingStatus]="(hotelRuleSearchSending$ | async) ?? false"
>
</ama-ng-upp-hotels-search-criteria>

<ama-ng-upp-basic-search-result-table
  [searchResults]="(hotelRules$ | async) ?? undefined"
  [sendingStatus]="(hotelRuleSearchSending$ | async) ?? false"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getEntryType()"
  [recordToSearchResult]="recordToSearchResult"
  [entryToUpdateCreator]="entryToUpdateCreator"
  [entryToUpdateNameResolver]="entryToUpdateNameResolver"
  (deleteEntry)="deleteHotelRule($any($event))"
  (clearTableEvent)="clearHotelRules()"
  (showDetail)="showHotelRule($event)"
  (modifyDetail)="modifyHotelRule($event)"
  (copyDetail)="copyHotelRule($event)"
  [copyForbidden]="!hotelsV2"
  [managePermission]="managePermission"
  [displayableFields]="displayableFields"
></ama-ng-upp-basic-search-result-table>
