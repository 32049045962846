import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetCreateFlightRuleValueAction, SetFlightUpdateSuccessValuesAction } from '../store/flight/flights-action';
import { ReefRESTService } from '@seco/core';
import {
  buildFlightFromCreateOrUpateResponse,
  buildFlightRuleListFromSearchResponse,
  buildFlightRuleFromDeleteResponse
} from './handler/flight-response-handler';
import {
  FlightRuleCreateRequest,
  FlightRuleSearchRequest,
  FlightRuleDeleteRequest,
  FlightRuleUpdateRequest
} from '../flights/model/flight-request';
import { FlightRuleCreateUpdateResponse } from '../flights/model/flight-response';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { JourneyUi, initialJourneyUi } from '../flights/model/flight-rule-ui';
import { Router } from '@angular/router';
import { TYPE_SUCCESS } from './model/pos';

@Injectable({
  providedIn: 'root'
})
export class FlightService {
  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly reefRestService: ReefRESTService
  ) {}

  createFlightRule(request: FlightRuleCreateRequest): Observable<JourneyUi> {
    return this.reefRestService
      .post(`/journey/v2/rule`, request)
      .pipe(map((response) => this.buildResultForFlightRuleCreation(response)));
  }

  searchFlightRules(request: FlightRuleSearchRequest): Observable<JourneyUi[]> {
    return this.reefRestService.post(`/journey/v2/rule/list`, request).pipe(
      map((response) => {
        const resp = { ...response };
        return buildFlightRuleListFromSearchResponse(resp);
      })
    );
  }

  deleteFlightRule(request: FlightRuleDeleteRequest): Observable<JourneyUi> {
    const journeyId = request.journey.rule.id;
    const requestForDelete = this.buildBackendRequestForDelete(request);
    return this.reefRestService
      .delete(`/journey/v2/rule/${journeyId}`, requestForDelete)
      .pipe(map((response) => buildFlightRuleFromDeleteResponse(response, request)));
  }

  updateFlightRule(request: FlightRuleUpdateRequest): Observable<JourneyUi> {
    const flightRuleToUpdate = request.journey.rule.id;
    return this.reefRestService
      .put(`/journey/v2/rule/${flightRuleToUpdate}`, request)
      .pipe(map((response) => this.buildResultForFlightRuleUpdate(response)));
  }

  buildResultForFlightRuleCreation(response: FlightRuleCreateUpdateResponse): JourneyUi {
    const journey = buildFlightFromCreateOrUpateResponse(response);
    if (journey.statusType === TYPE_SUCCESS) {
      this.store.dispatch(
        new SetCreateFlightRuleValueAction({
          value: initialJourneyUi
        })
      );
    }
    return journey;
  }

  buildBackendRequestForDelete(request: FlightRuleDeleteRequest): FlightRuleDeleteRequest {
    return {
      version: request.version,
      journey: {
        rule: {
          version: request.journey.rule.version
        }
      }
    };
  }

  private buildResultForFlightRuleUpdate(result: FlightRuleCreateUpdateResponse): JourneyUi {
    const journey = buildFlightFromCreateOrUpateResponse(result);
    if (journey.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForFlightModify(journey);
    }
    return journey;
  }

  private triggerSuccessActionForFlightModify(journey: JourneyUi) {
    this.store.dispatch(new SetFlightUpdateSuccessValuesAction({ updatedFlightRule: journey }));
    this.router.navigate(['flights/display/']);
  }
}
