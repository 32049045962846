import { Component, OnDestroy, OnInit } from '@angular/core';
import { UppComponent } from '../../service/model';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import {
  selectFamilyModifySending,
  selectFamilyModifyValue,
  selectFamilyModifyNotification
} from '../../store/families/families-selector';
import {
  UpdateFamilyHotelChainsAction,
  UpdateFamilyHotelPropertyCodesAction,
  SetFamilyModifyValueAction,
  CancelFamilyModificationAction,
  DeleteFamilyModifyNotificationAction
} from '../../store/families/families-action';
import {
  HOTEL_CHAINS_TYPE,
  FamilyUi,
  HotelChainsUpdateRequest,
  HotelPropertyCodesUpdateRequest,
  convertFamilyUiToHotelPropertyCodesFamily,
  convertFamilyUiToHotelChainsFamily,
  familyUiEquals
} from '../model';
import { validateFamily } from '../model/families-validator';
import { FormComponent } from '../../model/FormComponent';
import { UserDetailsService } from '../../service/user-details.service';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../service/core/navigation-constants';
import { RouteURL } from '../../model/route-url';

@Component({
  selector: 'ama-ng-upp-families-modify',
  templateUrl: './families-modify.component.html',
  styleUrls: ['./families-modify.component.scss']
})
export class FamiliesModifyComponent implements OnInit, OnDestroy, FormComponent {
  familyDetail: FamilyUi = {
    id: '',
    organization: '',
    name: '',
    description: '',
    hotelFamilyType: HOTEL_CHAINS_TYPE,
    hotelChains: [],
    hotelPropertyCodes: []
  };
  notification$: Observable<UppNotification | undefined>;

  mainMessages: NotificationMessages;
  sendingStatus!: boolean;

  familyForm!: UntypedFormGroup;
  uppComponent = UppComponent.FAMILIES_MODIFY;
  readonly = false;
  sourceView!: string;

  private subscriptions: Subscription[] = [];
  private lastSavedFamilyUi!: FamilyUi;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService
  ) {
    this.subscriptions.push(
      this.store.pipe(select(selectFamilyModifySending)).subscribe((sending) => (this.sendingStatus = sending))
    );
    this.subscriptions.push(
      this.store
        .pipe(select(selectFamilyModifyValue))
        .subscribe((value) => (this.lastSavedFamilyUi = this.familyDetail = value))
    );
    this.notification$ = this.store.pipe(select(selectFamilyModifyNotification));
    this.mainMessages = {
      error: $localize`:@@upp.families.modify.mainErrorText:The following errors for family appeared:`,
      warning: $localize`:@@upp.families.modify.mainWarningText:The following warning for family appeared:`,
      success: $localize`:@@upp.families.modify.mainSuccessText:The family was stored successfully.`
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Families Modification');
    this.navigationService.activateFlights();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.familyForm = this.formBuilder.group({});

    if (this.familyDetail) {
      this.familyForm.patchValue(this.familyDetail);
    }
  }

  cancelModification() {
    const id = this.familyDetail.id;
    this.store.dispatch(new CancelFamilyModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate([`${RouteURL.familyHotelDisplay}/${id}`]); // NOSONAR
    } else {
      this.router.navigate([RouteURL.familyHotelSearch]);
    }
  }

  saveModification() {
    const chainsChosen = this.familyDetail.hotelFamilyType && this.familyDetail.hotelFamilyType === HOTEL_CHAINS_TYPE;
    this.notification$ = this.store.pipe(select(selectFamilyModifyNotification));
    let request;
    if (chainsChosen) {
      request = this.createRequestForChains();
      request.hotelChainsFamily = this.userDetailsService.assignEntity(request.hotelChainsFamily);
      this.store.dispatch(new UpdateFamilyHotelChainsAction({ request }));
    } else {
      request = this.createRequestForCodes();
      request.hotelPropertyCodesFamily = this.userDetailsService.assignEntity(request.hotelPropertyCodesFamily);
      this.store.dispatch(new UpdateFamilyHotelPropertyCodesAction({ request }));
    }
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetFamilyModifyValueAction({
        familyUi: this.familyForm?.getRawValue()
      })
    );
  }

  closeNotification() {
    this.store.dispatch(new DeleteFamilyModifyNotificationAction({}));
    this.notification$ = this.store.pipe(select(selectFamilyModifyNotification));
  }

  saveButtonEnabled(): boolean {
    return validateFamily(this.familyForm);
  }

  hasUnsavedChanges(): boolean {
    return !familyUiEquals(this.lastSavedFamilyUi, this.familyForm?.value as FamilyUi);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequestForChains(): HotelChainsUpdateRequest {
    return {
      version: '1.0',
      hotelChainsFamily: convertFamilyUiToHotelChainsFamily(this.familyForm?.getRawValue())
    };
  }

  private createRequestForCodes(): HotelPropertyCodesUpdateRequest {
    return {
      version: '1.0',
      hotelPropertyCodesFamily: convertFamilyUiToHotelPropertyCodesFamily(this.familyForm?.getRawValue())
    };
  }
}
