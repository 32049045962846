import { Location, locationEquals } from './hotel-rule';
import { BaseUi, baseUiEquals } from '../../base/model/base-ui';
import { arraysEquals } from '../../util/arrayUtils';
import { ButtonGroupItem } from '../../model/button-group-item';

export const INCLUDE_ACTION = 'include';
export const EXCLUDE_ACTION = 'exclude';
export const LIGHT_EXCLUDE_ACTION = 'light_exclude';
export const ALL_ACTION = 'all';
export const COMMISSIONABLE_YES = 'yes';
export const COMMISSIONABLE_ALL = 'all';
export const COMMISSIONABLE_NO = 'no';
export const SOURCE_TYPE_GDS = 'GDS';
export const SOURCE_TYPE_AGGREGATOR = 'Aggregator';
export const RULE_STATUS_ACTIVE = 'active';
export const RULE_STATUS_INACTIVE = 'inactive';
export const RULE_STATUS_ALL = 'all';
export const SOURCE_TYPE_ALL_LABEL = 'All';
export const INITIAL_ACTION_VALUE = '';

export const INITIAL_EMPTY_VALUE = ' ';

export class HotelFormElements {
  static readonly ID = 'id';
  static readonly NAME = 'name';
  static readonly ORGANIZATION = 'organization';
  static readonly VERSION = 'version';
  static readonly ACTIVE = 'active';
  static readonly ACTION = 'action';
  static readonly DESCRIPTION = 'description';

  static readonly HAS_RULE_CONTENT = 'hasRuleContent';
  static readonly HOTEL_APPLICABILITY = 'hotelApplicability';

  static readonly IS_PROPERTIES_CHECKED = 'isPropertiesChecked';
  static readonly HAS_PROPERTIES_SELECTED = 'hasPropertiesSelected';

  static readonly IS_RATES_CHECKED = 'isRatesChecked';
  static readonly HAS_RATES_SELECTED = 'hasRatesSelected';

  static readonly SOURCE_TYPE_GDS = 'sourceTypesGds';
  static readonly SOURCE_TYPE_AGGREGATOR = 'sourceTypesAggregator';
  static readonly SOURCE_TYPE = 'sourceType';

  static readonly POINT_OF_SALE_NAMES = 'pointOfSaleNames';
  static readonly DESTINATION_NAMES = 'destinationNames';

  static readonly MASTER_CHAINS = 'masterChains';
  static readonly CHAINS = 'chains';
  static readonly HOTEL_CHAINS_FAMILIES = 'hotelChainsFamilies';
  static readonly PROPERTY_CODES = 'propertyCodes';
  static readonly HOTEL_PROPERTY_CODES_FAMILIES = 'hotelPropertyCodesFamilies';
  static readonly RATE_CODES = 'rateCodes';
  static readonly RATE_FAMILIES = 'rateFamilies';
  static readonly COMMISSIONABLE = 'commissionable';
}

export class RuleContentButtonNames {
  static readonly PROPERTIES = 'properties';
  static readonly RATES = 'rates';
}

export interface HotelRuleUi extends BaseUi {
  organization?: string;
  partitionId?: string;
  name: string;
  version?: number;
  description?: string;
  active: boolean;
  action: string;
  lightExclude?: boolean;
  hotelApplicability?: HotelApplicabilityUi;
  hasRuleContent?: boolean;
}

export interface HotelApplicabilityUi {
  pointOfSales?: Location[];
  destinations?: Location[];
  pointOfSaleNames?: string[];
  destinationNames?: string[];
  masterChains?: string[];
  chains?: string[];
  hotelChainsFamilies?: string[];
  propertyCodes?: string[];
  hotelPropertyCodesFamilies?: string[];
  rateCodes?: string[];
  sourceType?: string;
  sourceTypesGds?: boolean;
  sourceTypesAggregator?: boolean;
  commissionable?: string;
  rateFamilies?: string[];
  hasPropertiesSelected?: boolean;
  isRatesChecked?: boolean;
  isPropertiesChecked?: boolean;
  hasRatesSelected?: boolean;
}

export const initialHotelRuleUi: HotelRuleUi = {
  organization: '',
  name: '',
  active: true,
  action: EXCLUDE_ACTION,
  hotelApplicability: {
    commissionable: '',
    sourceTypesAggregator: false,
    sourceTypesGds: false,
    hasPropertiesSelected: false,
    hasRatesSelected: false
  },
  description: '',
  lightExclude: false,
  id: '',
  version: undefined
};

export const initialHotelApplicability: HotelApplicabilityUi = {
  sourceTypesGds: true,
  sourceTypesAggregator: true,
  commissionable: undefined,
  pointOfSaleNames: undefined,
  destinationNames: undefined,
  masterChains: undefined,
  chains: undefined,
  hotelChainsFamilies: undefined,
  propertyCodes: undefined,
  hotelPropertyCodesFamilies: undefined,
  rateCodes: undefined,
  rateFamilies: undefined,
  sourceType: undefined
};

export const initialHotelRuleV2Ui: HotelRuleUi = {
  action: 'exclude',
  active: true,
  description: '',
  hasRuleContent: false,
  hotelApplicability: {
    chains: undefined,
    commissionable: '',
    destinationNames: undefined,
    hotelChainsFamilies: undefined,
    hotelPropertyCodesFamilies: undefined,
    isPropertiesChecked: false,
    isRatesChecked: false,
    masterChains: undefined,
    pointOfSaleNames: undefined,
    propertyCodes: undefined,
    rateCodes: undefined,
    rateFamilies: undefined,
    sourceType: 'All',
    sourceTypesAggregator: false,
    sourceTypesGds: false
  },
  id: '',
  name: '',
  organization: '',
  version: undefined
};

export const initialCreateHotelValue: HotelRuleUi = {
  action: 'exclude',
  active: true,
  description: '',
  hasRuleContent: false,
  hotelApplicability: {
    commissionable: '',
    hasPropertiesSelected: false,
    hasRatesSelected: false,
    sourceTypesAggregator: false,
    sourceTypesGds: false
  },
  id: '',
  name: '',
  organization: '',
  version: undefined
};

export const ruleContentButtonGroupItems: ButtonGroupItem[] = [
  {
    name: RuleContentButtonNames.PROPERTIES,
    active: false,
    disabled: false,
    title: $localize`:@@upp.global.hotel.properties.label:Properties/Chains`
  },
  {
    name: RuleContentButtonNames.RATES,
    active: false,
    disabled: false,
    title: $localize`:@@upp.global.hotel.rates.label:Rates`
  }
];

export const isHotelRuleUiEquals = (h1?: HotelRuleUi, h2?: HotelRuleUi) =>
  baseUiEquals(h1, h2) &&
  h1!.organization === h2!.organization &&
  h1!.name === h2!.name &&
  h1!.version === h2!.version &&
  h1!.description === h2!.description &&
  h1!.active === h2!.active &&
  h1!.action === h2!.action &&
  h1!.lightExclude === h2!.lightExclude &&
  isHotelApplicabilityUiEquals(h1?.hotelApplicability, h2?.hotelApplicability);

export const isHotelApplicabilityUiEquals = (h1?: HotelApplicabilityUi, h2?: HotelApplicabilityUi) => {
  if (h1 === h2) {
    return true;
  } else {
    if (h1 === undefined || h2 === undefined) {
      return false;
    }
    return (
      arraysEquals(h1.pointOfSales, h2.pointOfSales, locationEquals) &&
      arraysEquals(h1.destinations, h2.destinations, locationEquals) &&
      arraysEquals(h1.pointOfSaleNames, h2.pointOfSaleNames) &&
      arraysEquals(h1.destinationNames, h2.destinationNames) &&
      arraysEquals(h1.masterChains, h2.masterChains) &&
      arraysEquals(h1.chains, h2.chains) &&
      arraysEquals(h1.hotelChainsFamilies, h2.hotelChainsFamilies) &&
      arraysEquals(h1.propertyCodes, h2.propertyCodes) &&
      arraysEquals(h1.hotelPropertyCodesFamilies, h2.hotelPropertyCodesFamilies) &&
      arraysEquals(h1.rateCodes, h2.rateCodes) &&
      h1.sourceTypesGds === h2.sourceTypesGds &&
      h1.sourceTypesAggregator === h2.sourceTypesAggregator &&
      h1.commissionable === h2.commissionable &&
      arraysEquals(h1.rateFamilies, h2.rateFamilies)
    );
  }
};
