<h1 i18n="@@upp.hotels.display.title">Display Hotel Rule</h1>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="(notification$ | async)!"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

@if(hotelsV2) {
<app-hotel-details-v2
  [hotelDetailsForm]="hotelForm!"
  [hotelDetails]="hotelRule"
  [readonly]="readonly"
  [viewMode]="currentViewMode"
>
</app-hotel-details-v2>
} @else {
<ama-ng-upp-hotel-details
  [hotelDetailsForm]="hotelForm"
  [hotelDetails]="hotelRule"
  [readonly]="readonly"
  [parent]="uppComponent"
>
</ama-ng-upp-hotel-details>
}

<div class="d-flex justify-content-end">
  @if(hotelsV2 && hasManageAccess()) {
  <button
    i18n="@@upp.global.button.copy"
    type="button"
    class="btn btn-outline-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="copyHotel()"
    data-test="copy-data-button"
  >
    Copy
  </button>
  }
  <button
    i18n="@@upp.global.button.modify"
    type="button"
    [disabled]="!modifyButtonEnabled()"
    class="btn btn-primary mb-2 mb-md-0 me-2 actionButton"
    (click)="modifyHotel()"
    data-test="modify-data-button"
  >
    Modify
  </button>
</div>
