import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FamilyHotelChainsService } from '../../service/family-hotel-chains.service';
import { FamilyHotelPropertyCodesService } from '../../service/family-hotel-property-codes.service';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import {
  FAMILIES_ACTION,
  FamiliesActions,
  FinishFamilyCreationAction,
  SetFamiliesListAction,
  DeleteFamilySearchNotificationAction,
  DeleteFamilyFromListAction,
  DeleteFamilyChainsClearStoreAction,
  DeleteFamilyCodesClearStoreAction,
  FinishFamilyUpdateAction,
  SetLookupHotelChainsFamiliesListAction,
  SetLookupHotelPropertyCodesFamiliesListAction,
  SetAllAvailableFamilyHotelChainsAction,
  SetAllAvailableFamilyHotelPropertyCodesAction,
  DeleteFamilyLookupNotificationAction
} from './families-action';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';
import { FamilyUi, HOTEL_CHAINS_TYPE, HOTEL_PROP_CODES_TYPE } from '../../families/model';
import { TYPE_ERROR } from '../../service/model/pos/pos-market-record';
import { ConfigurationService } from '../../service/configuration/configuration.service';

@Injectable()
export class FamiliesEffects {
  createFamilyHotelChains: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CHAINS),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelChainsService.createHotelChainsFamily(request).pipe(
          map((family) => new FinishFamilyCreationAction({ familyUi: family })),
          catchError((error) => of(new FinishFamilyCreationAction({ familyUi: createError(error, HOTEL_CHAINS_TYPE) })))
        );
      })
    )
  );

  createFamilyHotelPropertyCodes: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.CREATE_FAMILY_HOTEL_CODES),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelCodesService.createHotelPropertyCodesFamily(request).pipe(
          map((family) => new FinishFamilyCreationAction({ familyUi: family })),
          catchError((error) => of(new FinishFamilyCreationAction({ familyUi: createError(error, HOTEL_PROP_CODES_TYPE) })))
        );
      })
    )
  );

  searchFamilyHotelChains: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CHAINS),
      mergeMap((action) => this.searchFamily(HOTEL_CHAINS_TYPE, action as FamiliesActions))
    )
  );

  searchFamilyHotelCodes: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.SEARCH_FAMILY_HOTEL_CODES),
      mergeMap((action) => this.searchFamily(HOTEL_PROP_CODES_TYPE, action as FamiliesActions))
    )
  );

  deleteFamilyHotelChains: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CHAINS),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelChainsService.deleteHotelChainsFamily(request).pipe(
          switchMap((familyUi) => [
            new DeleteFamilySearchNotificationAction({}),
            new DeleteFamilyChainsClearStoreAction(request),
            new DeleteFamilyFromListAction({ familyUi })
          ]),
          catchError((error) => of(new DeleteFamilyFromListAction({ familyUi: createError(error, HOTEL_CHAINS_TYPE) })))
        );
      })
    )
  );

  deleteFamilyHotelCodes: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.DELETE_FAMILY_HOTEL_CODES),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelCodesService.deleteHotelPropertyCodesFamily(request).pipe(
          switchMap((familyUi) => [
            new DeleteFamilySearchNotificationAction({}),
            new DeleteFamilyCodesClearStoreAction(request),
            new DeleteFamilyFromListAction({ familyUi })
          ]),
          catchError((error) => of(new DeleteFamilyFromListAction({ familyUi: createError(error, HOTEL_PROP_CODES_TYPE) })))
        );
      })
    )
  );

  updateFamilyHotelChains: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CHAINS),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelChainsService.updateHotelChainsFamily(request).pipe(
          map((familyUi) => new FinishFamilyUpdateAction({ updatedFamilyUi: familyUi })),
          catchError((error) => of(new FinishFamilyUpdateAction({ updatedFamilyUi: createError(error, HOTEL_CHAINS_TYPE) })))
        );
      })
    )
  );

  updateFamilyHotelCodes: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.UPDATE_FAMILY_HOTEL_CODES),
      mergeMap((action) => {
        const { request } = (action as FamiliesActions).payload;
        return this.hotelCodesService.updateHotelPropertyCodesFamily(request).pipe(
          map((familyUi) => new FinishFamilyUpdateAction({ updatedFamilyUi: familyUi })),
          catchError((error) => of(new FinishFamilyUpdateAction({ updatedFamilyUi: createError(error, HOTEL_PROP_CODES_TYPE) })))
        );
      })
    )
  );

  getAllAvailableHotelFamilyChains: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.GET_ALL_AVAILABLE_FAMILY_HOTEL_CHAINS),
      mergeMap((action) => {
        const partitionEnabled = this.configurationService.getParameter('UPP_PARTITIONS_A');
        const { request } = {
          request: {
            version: '2.0',
            hotelChainsFamily: {}
          }
        };

        request.hotelChainsFamily = partitionEnabled
          ? { partitionId: (action as FamiliesActions).payload }
          : { organization: (action as FamiliesActions).payload };

        return this.hotelChainsService.searchHotelChainsFamilies(request).pipe(
          switchMap((response) => [
            new SetAllAvailableFamilyHotelChainsAction({ familyUis: response }),
            new DeleteFamilySearchNotificationAction({})
          ]),
          catchError((error) =>
            of(new SetAllAvailableFamilyHotelChainsAction({ familyUis: [createError(error, HOTEL_CHAINS_TYPE)] }))
          )
        );
      })
    )
  );

  getAllAvailableHotelFamilyPropertyCodes: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FAMILIES_ACTION.GET_ALL_AVAILABLE_FAMILY_HOTEL_CODES),

      mergeMap((action) => {
        const partitionEnabled = this.configurationService.getParameter('UPP_PARTITIONS_A');

        const { request } = {
          request: {
            version: '2.0',
            hotelPropertyCodesFamily: {}
          }
        };

        request.hotelPropertyCodesFamily = partitionEnabled
          ? { partitionId: (action as FamiliesActions).payload }
          : { organization: (action as FamiliesActions).payload };

        return this.hotelCodesService.searchHotelPropertyCodesFamilies(request).pipe(
          switchMap((response) => [
            new SetAllAvailableFamilyHotelPropertyCodesAction({ familyUis: response }),
            new DeleteFamilySearchNotificationAction({})
          ]),
          catchError((error) =>
            of(
              new SetAllAvailableFamilyHotelPropertyCodesAction({
                familyUis: [createError(error, HOTEL_PROP_CODES_TYPE)]
              })
            )
          )
        );
      })
    )
  );

  constructor(
    private readonly actions: Actions,
    private readonly hotelChainsService: FamilyHotelChainsService,
    private readonly hotelCodesService: FamilyHotelPropertyCodesService,
    private readonly configurationService: ConfigurationService
  ) {}

  searchFamily(type: string, action: FamiliesActions) {
    const request = action.payload.request;
    let result: Observable<FamilyUi[]>;
    if (type === HOTEL_CHAINS_TYPE) {
      result = this.hotelChainsService.searchHotelChainsFamilies(request);
    } else {
      result = this.hotelCodesService.searchHotelPropertyCodesFamilies(request);
    }

    if (action.payload.lookup) {
      if (type === HOTEL_CHAINS_TYPE) {
        return result.pipe(
          switchMap((familyUis) => [
            new DeleteFamilyLookupNotificationAction({}),
            new SetLookupHotelChainsFamiliesListAction({ familyUis })
          ]),
          catchError((error) => of(new SetLookupHotelChainsFamiliesListAction({ familyUis: [createError(error, type)] })))
        );
      } else {
        return result.pipe(
          switchMap((familyUis) => [
            new DeleteFamilyLookupNotificationAction({}),
            new SetLookupHotelPropertyCodesFamiliesListAction({ familyUis })
          ]),
          catchError((error) => of(new SetLookupHotelPropertyCodesFamiliesListAction({ familyUis: [createError(error, type)] })))
        );
      }
    }

    return result.pipe(
      switchMap((familyUis) => [
        new DeleteFamilySearchNotificationAction({}),
        new SetFamiliesListAction({ familyUis })
      ]),
      catchError((error) => of(new SetFamiliesListAction({ familyUis: [createError(error, type)] })))
    );
  }
}

const createError = (error: Error, hotelFamilyType: string): FamilyUi => ({
  statusType: TYPE_ERROR,
  id: 'familyError',
  organization: '',
  name: '',
  hotelFamilyType,
  statusNotification: { error: [error.message] }
});
