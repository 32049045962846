import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { catchError, map } from 'rxjs/operators';
import { selectPosRecords } from '../store/pos/pos-selector';
import { PosMarketUpdateRequest } from './model/req/pos-market-request';
import {
  PosSearchRequest,
  PosMarketSearchResponse,
  PosMarketRecord,
  PosMarketDeleteRequest,
  MarketsSearchRequest,
  MarketSearchResponse
} from './model';
import { buildPosRecordList, buildPosResponseRecord, buildMarketRecordList } from './handler';
import { TYPE_SUCCESS } from './model/pos';
import { SetPosCreateValueAction, SetPosUpdateSuccessValuesAction } from '../store/pos/pos-actions';
import { PosCommonResponse } from './model/resp/pos-market-response';
import { SetMarketsUpdateSuccessValuesAction, SetMarketCreateValueAction } from '../store/markets/markets-actions';
import { buildPosMarketDeleteResult } from './handler/pos-response-handler';
import { ReefRESTService } from '@seco/core';
import { createErrorRecord } from '../store/pos/pos-effects.service';

@Injectable({
  providedIn: 'root'
})
export class PosMarketService {
  readonly SUCCESS_STATUS = 0;
  readonly WARNING_STATUS = 1;
  readonly FAILED_STATUS = 2;

  private readonly pointOfSaleBasePath = '/pointOfSale';
  private readonly marketBasePath = '/market';

  constructor(
    private readonly zone: NgZone,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly reefRestService: ReefRESTService
  ) {}

  searchPos(request: PosSearchRequest): Observable<PosMarketRecord[]> {
    return this.reefRestService
      .post('/pointOfSale/list', request)
      .pipe(map((response) => this.buildPosResultList(response)));
  }

  searchMarkets(request: MarketsSearchRequest): Observable<PosMarketRecord[]> {
    return this.reefRestService
      .post('/market/list', request)
      .pipe(map((response) => this.buildMarketsResultList(response)));
  }

  deletePos(request: PosMarketDeleteRequest): Observable<PosMarketRecord> {
    const posToDelete = request.pointOfSale?.id;

    return this.reefRestService
      .delete(`pointOfSale/${posToDelete}`, request)
      .pipe(map((response) => this.handlePosDeleteResponse(response, request)));
  }

  createPos(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    return this.reefRestService
      .post(this.pointOfSaleBasePath, request)
      .pipe(map((response) => this.buildPosResultForCreate(response)));
  }

  updatePos(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    const posToUpdate = request.pointOfSale?.id;

    return this.reefRestService
      .put(`/pointOfSale/${posToUpdate}`, request)
      .pipe(map((response) => this.buildPosResultForUpdate(response)));
  }

  createMarket(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    return this.reefRestService
      .post(this.marketBasePath, request)
      .pipe(map((response) => this.buildMarketResultForCreate(response)));
  }

  updateMarkets(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    const marketToUpdate = request.market?.id;

    return this.reefRestService
      .put(`/market/${marketToUpdate}`, request)
      .pipe(map((response) => this.buildMarketsResultForUpdate(response)));
  }

  deleteMarket(request: PosMarketDeleteRequest): Observable<PosMarketRecord> {
    const marketToDelete = request.market?.id;

    return this.reefRestService
      .delete(`/market/${marketToDelete}`, request)
      .pipe(map((response) => this.handleMarketDeleteResponse(response, request)));
  }

  lookupPos(request: PosSearchRequest): Observable<PosMarketRecord[]> {
    return this.searchPos(request).pipe(catchError((error) => this.handleHttpPosMarketError(error)));
  }

  lookupMarkets(request: MarketsSearchRequest): Observable<PosMarketRecord[]> {
    return this.searchMarkets(request).pipe(catchError((error) => this.handleHttpPosMarketError(error)));
  }

  /**
   * TODO: Remove all *Mocked* functions as soon as backend services are stable.
   */
  deletePosMocked(request: PosMarketDeleteRequest): Observable<PosMarketRecord[]> {
    return of(this.deletePosFromList(request));
  }

  createPosMocked(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    return this.doMockedUpdateCall(request).pipe(
      map((response) => this.buildPosResultForCreate(JSON.parse(response.success.result)))
    );
  }

  updatePosMocked(request: PosMarketUpdateRequest): Observable<PosMarketRecord> {
    return this.doMockedUpdateCall(request).pipe(
      map((response) => this.buildPosResultForUpdate(JSON.parse(response.success.result)))
    );
  }

  private buildPosResultList(searchResponse: PosMarketSearchResponse): PosMarketRecord[] {
    return buildPosRecordList(searchResponse);
  }

  private buildMarketsResultList(searchResponse: MarketSearchResponse): PosMarketRecord[] {
    return buildMarketRecordList(searchResponse);
  }

  private buildPosResultForUpdate(result: PosCommonResponse): PosMarketRecord {
    const record = buildPosResponseRecord(result);
    if (record.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForPosModify(record);
    }
    return record;
  }

  private triggerSuccessActionForPosModify(record: PosMarketRecord) {
    this.store.dispatch(new SetPosUpdateSuccessValuesAction({ updatedPosRecord: record }));
    this.router.navigate(['pos/display/']);
  }

  private buildMarketsResultForUpdate(result: PosCommonResponse): PosMarketRecord {
    const record = buildPosResponseRecord(result);
    if (record.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForMarketsModify(record);
    }
    return record;
  }

  private triggerSuccessActionForMarketsModify(record: PosMarketRecord) {
    this.store.dispatch(new SetMarketsUpdateSuccessValuesAction({ updatedRecord: record }));
    this.router.navigate(['markets/display/']);
  }

  private buildPosResultForCreate(result: PosCommonResponse): PosMarketRecord {
    const record = buildPosResponseRecord(result, true);
    if (record.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetPosCreateValueAction({ value: { organization: '', name: '' } }));
    }
    return record;
  }

  private buildMarketResultForCreate(result: PosCommonResponse): PosMarketRecord {
    const record = buildPosResponseRecord(result, true);
    if (record.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetMarketCreateValueAction({ value: { organization: '', name: '' } }));
    }
    return record;
  }

  private handlePosDeleteResponse(response: PosCommonResponse, request: PosMarketDeleteRequest): PosMarketRecord {
    return buildPosMarketDeleteResult(response, request.pointOfSale?.id);
  }

  private handleMarketDeleteResponse(response: PosCommonResponse, request: PosMarketDeleteRequest): PosMarketRecord {
    return buildPosMarketDeleteResult(response, request.market?.id);
  }

  private deletePosFromList(request: PosMarketDeleteRequest): PosMarketRecord[] {
    let updatedRecords: PosMarketRecord[] = [];

    this.store
      .pipe(select(selectPosRecords))
      .pipe(map((element) => element?.filter((record) => record.id !== request.pointOfSale?.id)))
      .subscribe((records) => (updatedRecords = records ?? []));

    return updatedRecords;
  }

  private doMockedUpdateCall(request: PosMarketUpdateRequest): Observable<any> {
    const resultText = JSON.stringify(this.getMockedUpdateResult(request));
    const response = {
      success: {
        result: resultText
      }
    };
    return of(response);
  }

  private getMockedUpdateResult(request: PosMarketUpdateRequest): PosCommonResponse {
    if (!request.pointOfSale?.organization) {
      throw new Error('POS organization missing.');
    }

    return {
      version: request.version,
      status: {
        state: 'success'
      },
      pointOfSale: {
        organization: request.pointOfSale.organization,
        name: request.pointOfSale.name,
        description: request.pointOfSale.description,
        version: 1,
        include: request.pointOfSale.include,
        exclude: request.pointOfSale.exclude
      }
    };
  }

  private handleHttpPosMarketError(error: any): Observable<PosMarketRecord[]> {
    return of([createErrorRecord(error)]);
  }
}
