<h1>{{ titlesMap[currentViewMode] }}</h1>

<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="isLoading" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<ama-upp-notification
  [mainMessages]="mainMessages"
  [notification]="(notification$ | async)!"
  (closeNotificationEvent)="closeNotification()"
  (openDisplayEvent)="displayAirFamily()"
></ama-upp-notification>

<form class="styled-form" [formGroup]="airFamilyForm">
  @if(!isDisplayViewMode) {
  <section class="row">
    <div class="col text-end mb-2">
      <button
        i18n="@@upp.global.button.clear"
        type="button"
        [disabled]="isLoading"
        class="btn btn-outline-primary btn-sm"
        (click)="clearForm()"
        >Clear
      </button>
    </div>
  </section>
  }

  <section class="col">
    <div class="row">
      <div class="col">
        <label for="name" class="form-label">
          <ng-container i18n="@@upp.families.air.nameLabel">Name</ng-container>
          <span class="mandatory">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          data-test="air-family-name"
          [formControlName]="FormElements.NAME"
          [ngClass]="{ 'is-invalid': nameControl?.invalid && nameControl?.touched }"
          placeholder="Air family name"
          i18n-placeholder="@@upp.families.air.namePlaceholder"
          ngbTooltip="Enter the Air Family name with letters, numbers or spaces (up to 30 characters)"
          i18n-ngbTooltip="@@upp.families.air.nameTooltip"
          oninput="let nameTextCursorPosition=this.selectionStart;
            this.value=this.value.toUpperCase();
            this.setSelectionRange(nameTextCursorPosition, nameTextCursorPosition);"
        />
        <ama-ng-upp-validation-error-message
          [control]="nameControl ?? undefined"
          [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES"
        >
        </ama-ng-upp-validation-error-message>
      </div>

      <div class="col">
        <label i18n="@@upp.families.air.descriptionLabel" for="description" class="form-label">Description</label>
        <input
          type="text"
          class="form-control"
          id="description"
          data-test="air-family-description"
          [formControlName]="FormElements.DESCRIPTION"
          [ngClass]="{ 'is-invalid': descriptionControl?.invalid && descriptionControl?.touched }"
          placeholder="Air family description"
          i18n-placeholder="@@upp.families.air.descriptionPlaceholder"
          ngbTooltip="Describe the Family (up to 128 characters)"
          i18n-ngbTooltip="@@upp.families.air.descriptionTooltip"
        />
        <ama-ng-upp-validation-error-message
          [control]="descriptionControl ?? undefined"
          [messageFunctions]="MAX_LENGTH_STRING_VALIDATION_MESSAGES"
        >
        </ama-ng-upp-validation-error-message>
      </div>
    </div>
  </section>

  <h3 class="mt-8 include" i18n="@@upp.common.include">Include</h3>
  <label class="form-label" [for]="includedCarriers">
    <ng-container i18n="@@upp.global.criteria.carrierCode.label">Carrier code</ng-container>
    <span class="mandatory">*</span>
  </label>
  <ama-ng-upp-lookup-select
    [formControlName]="includedCarriers"
    [multiple]="true"
    [id]="includedCarriers"
    [addTag]="true"
    notFoundText="No airlines found"
    i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText"
    ngbTooltip="Entering YY as carrier will add all carriers to the rule."
    i18n-ngbTooltip="@@upp.common.carrierCodeTooltip"
    data-test="includedCarriersSelect"
  >
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message
    [control]="airFamilyForm.get(includedCarriers) ?? undefined"
    [messageFunctions]="carrierCodesIncludeValidationMessages"
  >
  </ama-ng-upp-validation-error-message>

  <h3 class="mt-7 exclude" i18n="@@upp.common.exclude">Exclude</h3>
  <label class="form-label" [for]="excludedCarriers" i18n="@@upp.global.criteria.carrierCode.label">Carrier code</label>
  <ama-ng-upp-lookup-select
    [formControlName]="excludedCarriers"
    [multiple]="true"
    [id]="excludedCarriers"
    [addTag]="true"
    notFoundText="No airlines found"
    i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText"
    data-test="excludedCarriersSelect"
  >
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message
    [control]="airFamilyForm.get(excludedCarriers) ?? undefined"
    [messageFunctions]="carrierCodesExcludeValidationMessages"
  >
  </ama-ng-upp-validation-error-message>

  <section class="d-flex justify-content-end mt-8">
    @if (currentViewMode === ViewMode.MODIFY) {
    <button
      (click)="cancelAirFamilyModification()"
      i18n="@@upp.global.button.cancel"
      class="btn btn-outline-primary mb-2 mb-md-0 me-2"
    >
      Cancel
    </button>
    } @if (currentViewMode === ViewMode.CREATE || currentViewMode === ViewMode.MODIFY) {
    <button (click)="saveAirFamily()" i18n="@@upp.global.button.save" class="btn btn-primary mb-2 mb-md-0">
      Save
    </button>
    } @if (currentViewMode === ViewMode.DISPLAY) {
    <button (click)="openModify()" i18n="@@upp.global.button.modify" class="btn btn-primary mb-2 mb-md-0">
      Modify
    </button>
    }
  </section>
</form>
