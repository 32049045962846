import { Time } from '../flights/model';

export const convertTimeFormatToMinutes = (time: Time): number => time.hour * 60 + time.minute;

// Type guard function to check if an object is of type Time
export const isTimeFormat = (object: any): boolean => (
    object !== null &&
    typeof object === 'object' &&
    'hour' in object &&
    typeof object.hour === 'number' &&
    'minute' in object &&
    typeof object.minute === 'number' &&
    'second' in object &&
    typeof object.second === 'number'
  );
