import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HotelsModifyComponent } from '../../hotels/hotels-modify/hotels-modify.component';
import { GuardService } from '../../service/guard.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateHotelModifyGuard {
  constructor(private readonly guardService: GuardService) {}
  canDeactivate(component: HotelsModifyComponent): Observable<boolean> | boolean {
    return this.guardService.canDeactivate(component);
  }
}
