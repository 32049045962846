import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { SetCreateFamilyValueAction, SetFamilyUpdateSuccessValuesAction } from '../store/families/families-action';
import { ReefRESTService } from '@seco/core';
import {
  buildCodesFamilyFromCreateOrUpdateResponse,
  buildHotelPropertyCodesFamilyListFromSearchResponse,
  buildHotelPropertyCodesFamilyFromDeleteResponse
} from './handler/family-hotel-property-codes-response-handler';
import {
  HotelPropertyCodesCreateRequest,
  HotelPropertyCodesSearchRequest,
  HotelPropertyCodesDeleteRequest,
  HotelPropertyCodesUpdateRequest
} from '../families/model/family-request';
import { HotelPropertyCodesFamilyCreateUpdateResponse } from '../families/model/family-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FamilyUi, initialFamilyUi } from '../families/model/family-ui';
import { Router } from '@angular/router';
import { TYPE_SUCCESS } from './model/pos';
import { RouteURL } from '../model/route-url';

@Injectable({
  providedIn: 'root'
})
export class FamilyHotelPropertyCodesService {
  constructor(
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly reefRestService: ReefRESTService
  ) {}

  createHotelPropertyCodesFamily(request: HotelPropertyCodesCreateRequest): Observable<FamilyUi> {
    return this.reefRestService
      .post('/family/hotel/propertyCodes', request)
      .pipe(map((response) => this.buildResultForHotelPropertyCodesFamilyCreation(response)));
  }

  searchHotelPropertyCodesFamilies(request: HotelPropertyCodesSearchRequest): Observable<FamilyUi[]> {
    return this.reefRestService
      .post('/family/hotel/propertyCodes/list', request)
      .pipe(map((response) => buildHotelPropertyCodesFamilyListFromSearchResponse(response)));
  }

  deleteHotelPropertyCodesFamily(request: HotelPropertyCodesDeleteRequest): Observable<FamilyUi> {
    const familyId = request.hotelPropertyCodesFamily.id;
    const requestForDelete = this.buildBackendRequestForDelete(request);
    return this.reefRestService
      .delete(`/family/hotel/propertyCodes/${familyId}`, requestForDelete)
      .pipe(map((response) => buildHotelPropertyCodesFamilyFromDeleteResponse(response, request)));
  }

  updateHotelPropertyCodesFamily(request: HotelPropertyCodesUpdateRequest): Observable<FamilyUi> {
    const familyId = request.hotelPropertyCodesFamily.id;
    return this.reefRestService
      .put(`/family/hotel/propertyCodes/${familyId}`, request)
      .pipe(map((response) => this.buildResultForHotelPropertyCodesFamilyUpdate(response)));
  }

  buildBackendRequestForDelete(request: HotelPropertyCodesDeleteRequest): HotelPropertyCodesDeleteRequest {
    return {
      version: request.version,
      hotelPropertyCodesFamily: {
        version: request.hotelPropertyCodesFamily.version
      }
    };
  }

  buildResultForHotelPropertyCodesFamilyCreation(response: HotelPropertyCodesFamilyCreateUpdateResponse): FamilyUi {
    const family = buildCodesFamilyFromCreateOrUpdateResponse(response);
    if (family.statusType === TYPE_SUCCESS) {
      this.store.dispatch(new SetCreateFamilyValueAction({ familyUi: initialFamilyUi }));
    }
    return family;
  }

  private buildResultForHotelPropertyCodesFamilyUpdate(result: HotelPropertyCodesFamilyCreateUpdateResponse): FamilyUi {
    const family = buildCodesFamilyFromCreateOrUpdateResponse(result);
    if (family.statusType === TYPE_SUCCESS) {
      this.triggerSuccessActionForHotelPropertyCodesFamilyModify(family);
    }
    return family;
  }

  private triggerSuccessActionForHotelPropertyCodesFamilyModify(familyHotelCodeUi: FamilyUi) {
    this.store.dispatch(new SetFamilyUpdateSuccessValuesAction({ familyUi: familyHotelCodeUi }));
    this.router.navigate([RouteURL.familyHotelDisplay]);
  }
}
