import { createReducer, on } from '@ngrx/store';
import {
  airFamilyDeleted,
  clearAirFamilyNamesSelection,
  saveAirFamilies,
  setSelectedAirFamilyNames,
  setAllAvailableAirFamilies,
  saveAirFamily,
  airFamilyNotificationClosed,
  saveLastActiveAirFamilyView,
  airFamilyCreated,
  airFamilyModified,
  partitionChanged,
  setNotificationData
} from './air-families-action';
import { AirFamiliesState } from './air-families-state';
import { AirFamily } from '../../../families/air/model/air-family';
import { UppViewNames, ViewMode } from '../../../service/model';

const initialAirFamiliesState: AirFamiliesState = {
  airFamilies: [],
  availableAirFamilies: [],
  selectedAirFamilyNames: new Set<string>(),
  selectedAirFamily: {
    [UppViewNames.CREATE]: {} as AirFamily,
    [UppViewNames.MODIFY]: {} as AirFamily,
    [UppViewNames.DISPLAY]: {} as AirFamily,
    [UppViewNames.SEARCH]: {} as AirFamily
  },
  notifications: {
    [UppViewNames.CREATE]: {},
    [UppViewNames.MODIFY]: {},
    [UppViewNames.DISPLAY]: {},
    [UppViewNames.SEARCH]: {}
  },
  lastActiveView: null
};

export const airFamiliesReducer = createReducer(
  initialAirFamiliesState,

  on(saveAirFamilies, (state, { airFamilies }) => ({
    ...state,
    airFamilies
  })),

  on(setSelectedAirFamilyNames, (state, { selectedNames }) => ({
    ...state,
    selectedAirFamilyNames: selectedNames
  })),

  on(clearAirFamilyNamesSelection, (state) => ({
    ...state,
    selectedAirFamilyNames: new Set<string>()
  })),

  on(setAllAvailableAirFamilies, (state, { airFamilies }) => ({
    ...state,
    availableAirFamilies: airFamilies
  })),

  on(setNotificationData, (state, { notification, viewMode }) => ({
    ...state,
    notifications: {
      ...state?.notifications,
      [viewMode]: notification
    }
  })),

  on(airFamilyDeleted, (state, { id }) => {
    let lastActiveView: ViewMode | null = state.lastActiveView;
    let selectedAirFamily = { ...state.selectedAirFamily };

    if (state.lastActiveView !== null && state.selectedAirFamily[state.lastActiveView]?.id === id) {
      lastActiveView = null;
      selectedAirFamily = initialAirFamiliesState.selectedAirFamily;
    }

    if (state.selectedAirFamily[UppViewNames.CREATE]?.id === id) {
      selectedAirFamily = {
        ...selectedAirFamily,
        [UppViewNames.CREATE]: {} as AirFamily
      };
    }

    return {
      ...state,
      lastActiveView,
      selectedAirFamily,
      availableAirFamilies: state.availableAirFamilies?.filter((airFamily) => airFamily.id !== id),
      airFamilies: state.airFamilies?.filter((airFamily) => airFamily.id !== id)
    };
  }),

  on(saveAirFamily, (state, { selectedAirFamily, viewMode }) => {
    const airFamily = { ...state.selectedAirFamily };
    airFamily[viewMode] = selectedAirFamily;

    return {
      ...state,
      selectedAirFamily: { ...airFamily }
    };
  }),

  on(airFamilyNotificationClosed, (state, { viewMode }) => {
    const airFamily = structuredClone(state.selectedAirFamily);
    airFamily[viewMode].statusNotification = undefined;
    return {
      ...state,
      notifications: { ...state.notifications, [viewMode]: undefined }
    };
  }),

  on(saveLastActiveAirFamilyView, (state, { lastActiveView }) => ({
    ...state,
    lastActiveView
  })),

  on(airFamilyCreated, (state, { airFamily }) => ({
    ...state,
    availableAirFamilies: [...(state?.availableAirFamilies ?? []), airFamily]
  })),

  on(airFamilyModified, (state, { airFamily }) => {
    const selectedAirFamily = { ...state.selectedAirFamily };
    if (selectedAirFamily[UppViewNames.CREATE]?.id === airFamily.id) {
      selectedAirFamily[UppViewNames.CREATE] = {
        ...airFamily,
        statusNotification: selectedAirFamily[UppViewNames.CREATE]?.statusNotification
      };
    }

    return {
      ...state,
      selectedAirFamily,
      airFamilies: state.airFamilies.length
        ? state.airFamilies.map((af) => (af.id === airFamily.id ? airFamily : af))
        : []
    };
  }),

  on(partitionChanged, () => initialAirFamiliesState)
);
