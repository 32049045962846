import {
  HotelChainsFamilySearchResponse,
  HotelChainsFamilyCreateUpdateResponse
} from '../../families/model/family-response';
import { TYPE_ERROR, TYPE_SUCCESS, TYPE_WARNING } from '../model';
import { UppNotification, DEFAULT_SUCCESS } from '../../model/notification';
import {
  FamilyUi,
  convertHotelChainsFamilyToFamilyUi,
  HotelChainsDeleteRequest,
  HOTEL_CHAINS_TYPE,
  HotelChainsFamily
} from '../../families/model';
import { SUCCESS_STATUS, MISSING_RESULT_STATUS, UNKNOWN_RESULT_STATUS, buildErrorOrWarning } from './response-handler';
import { BaseResponse } from '../../base/model/base-response';

export const HOTEL_CHAIN_ERROR = 'hotelChainFamilyError';
export const HOTEL_CHAIN_WARN = 'hotelChainFamilyWarning';

export const successDeleteHotelChainUi: FamilyUi = {
  statusType: TYPE_SUCCESS,
  statusNotification: { success: [DEFAULT_SUCCESS] },
  id: '',
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_CHAINS_TYPE
};

export const warningHotelChainUi: FamilyUi = {
  statusType: TYPE_WARNING,
  statusNotification: { warning: [UNKNOWN_RESULT_STATUS] },
  id: HOTEL_CHAIN_WARN,
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_CHAINS_TYPE
};

export const errorHotelChainUi: FamilyUi = {
  statusType: TYPE_ERROR,
  statusNotification: { error: [MISSING_RESULT_STATUS] },
  id: HOTEL_CHAIN_ERROR,
  organization: '',
  name: '',
  hotelFamilyType: HOTEL_CHAINS_TYPE
};

export const buildChainsFamilyFromCreateOrUpdateResponse = (
  response: HotelChainsFamilyCreateUpdateResponse
): FamilyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return createSuccessForCreate(response.hotelChainsFamily, true);
  }
  return buildFailedResponse(response);
};

export const buildHotelChainsFamilyListFromSearchResponse = (response: HotelChainsFamilySearchResponse): FamilyUi[] => {
  if (response.status.state === SUCCESS_STATUS) {
    return createFamilyList(response);
  }
  return [buildFailedResponse(response)];
};

export const buildHotelChainsFamilyFromDeleteResponse = (
  response: BaseResponse,
  request: HotelChainsDeleteRequest
): FamilyUi => {
  if (response.status.state === SUCCESS_STATUS) {
    return {
      ...successDeleteHotelChainUi,
      id: request.hotelChainsFamily.id
    };
  }
  return buildFailedResponse(response);
};

const buildFailedResponse = (response: BaseResponse): FamilyUi =>
  buildErrorOrWarning(warningHotelChainUi, errorHotelChainUi, response) as FamilyUi;

const createFamilyList = (response: HotelChainsFamilySearchResponse): FamilyUi[] => {
  const families: FamilyUi[] = [];
  response.hotelChainsFamilies.forEach((hotelChain) => families.push(convertHotelChainsFamilyToFamilyUi(hotelChain)));
  return families;
};

const createSuccessForCreate = (hotelChainsFamily: HotelChainsFamily, addLinkData: boolean): FamilyUi => {
  const notification: UppNotification = {
    success: [DEFAULT_SUCCESS]
  };

  if (!hotelChainsFamily.id) {
    throw new Error('id is null or undefined.');
  }

  if (addLinkData) {
    notification.links = [
      {
        id: hotelChainsFamily.id,
        name: hotelChainsFamily.name
      }
    ];
  }

  const family = convertHotelChainsFamilyToFamilyUi(hotelChainsFamily);
  family.statusType = TYPE_SUCCESS;
  family.statusNotification = notification;
  return family;
};
