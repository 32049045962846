export interface Notification {
  search?: UppNotification;
  display?: UppNotification;
  create?: UppNotification;
  modify?: UppNotification;
  lookup?: UppNotification;
}

export interface UppNotification {
  success?: string[];
  warning?: string[];
  error?: string[];
  links?: [
    {
      id: string;
      name: string;
    }
  ];
}

export enum UppNotificationStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

export const DEFAULT_SUCCESS = 'OK';
