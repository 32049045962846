import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UppComponent, UppViewNames } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import {
  HotelRuleCreateRequest,
  HotelRuleUi,
  INITIAL_ACTION_VALUE,
  convertToHotelRule,
  initialCreateHotelValue,
  initialHotelRuleUi,
  initialHotelRuleV2Ui,
  isHotelRuleUiEquals
} from '../model';
import {
  CreateHotelRuleAction,
  SetCreateHotelRuleValueAction,
  DeleteHotelRuleCreateNotificationAction,
  ActivateCreatedHotelRuleDisplayAction
} from '../../store/hotel/hotels-action';
import {
  selectHotelRuleCreateValue,
  selectHotelRuleCreateNotification,
  selectHotelRuleCreateSending
} from '../../store/hotel/hotels-selector';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';
import { FormComponent } from '../../model/FormComponent';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-hotel-create',
  templateUrl: './hotels-create.component.html'
})
export class HotelsCreateComponent implements OnInit, OnDestroy, FormComponent {
  notification$: Observable<UppNotification | undefined>;
  mainMessages: NotificationMessages;

  hotelRule: HotelRuleUi = initialHotelRuleUi;
  hotelForm?: UntypedFormGroup;
  readonly = false;
  sendingStatus = false;

  uppComponent = UppComponent.HOTELS_CREATE;
  currentViewMode = UppViewNames.CREATE;

  hotelsV2: boolean | null = null;

  subscription: Subscription = new Subscription();

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    this.notification$ = this.store.pipe(select(selectHotelRuleCreateNotification));
    this.subscription?.add(
      this.store.pipe(select(selectHotelRuleCreateSending)).subscribe((sending) => (this.sendingStatus = sending))
    );
    this.subscription?.add(
      this.configurationService.getParameter$(FeatureFlags.hotelsV2).subscribe((hotelsV2) => {
        this.hotelsV2 = hotelsV2 ?? false;
        this.hotelRule = hotelsV2 ? initialHotelRuleV2Ui : initialHotelRuleUi;
      })
    );
    this.mainMessages = {
      error: $localize`:@@upp.hotels.create.mainErrorText:Creation of hotel rule failed due to the following errors:`,
      warning: $localize`:@@upp.hotels.create.mainWarningText:Warnings were generated during the creation process of hotel rule:`,
      success: $localize`:@@upp.hotels.create.mainSuccessText:Well done! You get this message about your successful Hotel Rule creation`,
      linkSuccessText: $localize`:@@upp.hotels.create.linkSuccessText:Display new Hotel Rule: `
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.hotels.create.navigationTitle:Hotel Rule Creation`);
    this.createAndLoadForm();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  createAndLoadForm() {
    this.hotelForm = this.formBuilder.group({});
    this.store.pipe(select(selectHotelRuleCreateValue)).subscribe((rule) => {
      if (rule) {
        // Check if the rule is equal to the initial values in case the hotelsV2 is enabled
        // There are two checks needed because the initial selectHotelRuleCreateValue is not compliant with the hotelsV2 model
        // The selectHotelRuleCreateValue should be updated with the new model when the hotelV2 param is activated
        const isRuleEqualToInitial =
          isHotelRuleUiEquals(rule, initialCreateHotelValue) || isHotelRuleUiEquals(rule, initialHotelRuleUi);
        const hotelV2 = isRuleEqualToInitial ? { ...rule, active: false, action: INITIAL_ACTION_VALUE } : rule;

        this.hotelRule = this.hotelsV2 ? hotelV2 : rule;
        this.hotelForm?.reset();
      }
    });
  }

  saveData() {
    if (this.hotelsV2 && this.hotelForm?.invalid) {
      this.hotelForm?.markAllAsTouched();
      return;
    }
    const request = this.createRequest();
    request.rule = this.userDetailsService.assignEntity(request.rule);
    this.storeUnsavedChanges();
    this.store.dispatch(new CreateHotelRuleAction({ request }));
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateHotelRuleValueAction({ value: this.hotelForm?.value }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteHotelRuleCreateNotificationAction({}));
  }

  showHotelRuleInDisplay() {
    this.store.dispatch(new ActivateCreatedHotelRuleDisplayAction({}));
    this.router.navigate(['hotels/display']);
  }

  saveButtonEnabled(): boolean {
    if (!(this.hotelForm && this.hotelForm.get('name'))) {
      return false;
    }
    const nameValue: string = this.hotelForm.get('name')?.value;
    if (nameValue && nameValue.length > 0) {
      return this.hotelForm.valid;
    }
    return false;
  }

  createRequest(): HotelRuleCreateRequest {
    return {
      version: '1.0',
      rule: convertToHotelRule(this.hotelForm?.value, this.hotelsV2)
    };
  }

  hasUnsavedChanges(): boolean {
    return !isHotelRuleUiEquals(initialHotelRuleUi, this.hotelForm?.value as HotelRuleUi);
  }
}
