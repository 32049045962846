import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FaresSearchComponent } from '../../fares/fares-search/fares-search.component';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateFareSearchGuard {
  canDeactivate(component: FaresSearchComponent): Observable<boolean> | boolean {
    component.storeUnsavedFareSearch();
    return true;
  }
}
