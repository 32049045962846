import { ValidationProvider } from '../../upp-validator.service';
import { CORPORATE_CODES, COUNTRY_CODES, DEFAULT_ERROR, OFFICEIDS, THREE_LETTERS } from '../common/validation-messages';
import { AIRPORTS, CITIES, CORPORATE_CODES_LIST, COUNTRY, DESCRIPTION, NAME, OFFICE_IDS } from '../common/validators';

export class PosMarketServiceElements {
  static readonly ORGANIZATION = 'organization';
  static readonly NAME = 'name';
  static readonly ID = 'id';
  static readonly VERSION = 'version';
  static readonly DESCRIPTION = 'description';
  static readonly INCLUDE = 'include';
  static readonly EXCLUDE = 'exclude';
  static readonly LOCATION = 'location';
  static readonly LEVEL = 'level';
  static readonly WORLD = 'world';
  static readonly REGIONS = 'regions';
  static readonly COUNTRIES = 'countries';
  static readonly CITIES = 'cities';
  static readonly AIRPORTS = 'airports';
  static readonly OFFICE_IDS = 'officeIds';
  static readonly CORPORATE_CODES = 'corporateCodesAndQualifiers';
}

export const VALIDATORS = new Map<string, ValidationProvider>([
  [PosMarketServiceElements.NAME, { validator: NAME, massages: DEFAULT_ERROR }],
  [PosMarketServiceElements.DESCRIPTION, { validator: DESCRIPTION, massages: DEFAULT_ERROR }],
  [PosMarketServiceElements.COUNTRIES, { validator: COUNTRY, massages: COUNTRY_CODES }],
  [PosMarketServiceElements.CITIES, { validator: CITIES, massages: THREE_LETTERS }],
  [PosMarketServiceElements.AIRPORTS, { validator: AIRPORTS, massages: THREE_LETTERS }],
  [PosMarketServiceElements.OFFICE_IDS, { validator: OFFICE_IDS, massages: OFFICEIDS }],
  [PosMarketServiceElements.CORPORATE_CODES, { validator: CORPORATE_CODES_LIST, massages: CORPORATE_CODES }]
]);
