<div class="container">
  <div class="mb-3 w-80" *ngFor="let rowIndex of rowsIndices">
    <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-6">
      <div class="col" *ngFor="let card of cards | slice : rowIndex * itemsPerRow : (rowIndex + 1) * itemsPerRow">
        <div class="card" [attr.data-test]="card.testId + '-card'">
          <app-card [card]="card" [percentageOfDeck]="percentageOfDeck"> </app-card>
        </div>
      </div>
    </div>
    <br *ngIf="rowIndex < rowsIndices.length - 1" />
  </div>
</div>
