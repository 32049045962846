import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  COMMISSIONABLE_YES,
  EXCLUDE_ACTION,
  HotelFormElements,
  HotelRuleUi,
  INCLUDE_ACTION,
  INITIAL_ACTION_VALUE,
  INITIAL_EMPTY_VALUE,
  initialHotelApplicability,
  initialHotelRuleUi,
  LIGHT_EXCLUDE_ACTION,
  ruleContentButtonGroupItems,
  RuleContentButtonNames,
  SOURCE_TYPE_AGGREGATOR,
  SOURCE_TYPE_ALL_LABEL,
  SOURCE_TYPE_GDS
} from '../../model';
import { UppComponentNames, UppViewNames } from '../../../service/model';
import { LookupOptions } from '../../../core/util/lookup-options';
import { UserDetailsService } from '../../../service/user-details.service';
import { NavigationService } from '../../../service/core/navigation.service';
import { select, Store } from '@ngrx/store';
import { selectNavigation } from '../../../store/navigation/navigation-selector';
import { selectAllAvailablePosRecords } from '../../../store/pos/pos-selector';
import {
  PERMISSION_VIEW_POS,
  PERMISSION_VIEW_MARKET,
  PERMISSION_VIEW_FAMILY
} from '../../../service/user-permissions.service';
import {
  selectAvailableFamiliesHotelChains,
  selectAvailableFamiliesHotelPropertyCodes
} from '../../../store/families/families-selector';
import { selectAllAvailableMarketsRecords } from '../../../store/markets/markets-selector';
import { Subscription } from 'rxjs';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../model/button-group-item';
import {
  EIGHT_SYMBOLS,
  NAME_WITH_SPACES_VALIDATION_MESSAGES,
  REQUIRED_MSG,
  SECTION_VALIDATION_MESSAGES,
  THREE_SYMBOLS,
  TWO_SYMBOLS_REQUIRED
} from '../../../service/model/common/validation-messages';
import {
  eightAlphaNumericRegex,
  threeAlphaNumericRegex,
  twoAlphaNumericRegex
} from '../../../service/model/common/validators';

const NAME_REGEX = '^[A-Z0-9 ]{0,30}$';

@Component({
  selector: 'app-hotel-details-v2',
  templateUrl: './hotel-details-v2.component.html',
  styleUrl: './hotel-details-v2.component.scss'
})
export class HotelDetailsV2Component implements OnChanges, OnInit, OnDestroy {
  @Input() hotelDetailsForm!: UntypedFormGroup;
  @Input() hotelDetails: HotelRuleUi = {
    ...initialHotelRuleUi,
    action: INITIAL_ACTION_VALUE,
    hotelApplicability: initialHotelApplicability
  };

  @Input() readonly = false;
  @Input() viewMode!: UppViewNames;

  readonly TWO_SYMBOLS_REQUIRED = TWO_SYMBOLS_REQUIRED;
  readonly THREE_SYMBOLS = THREE_SYMBOLS;
  readonly NAME_WITH_SPACES_VALIDATION_MESSAGES = NAME_WITH_SPACES_VALIDATION_MESSAGES;
  readonly SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;
  readonly EIGHT_SYMBOLS = EIGHT_SYMBOLS;
  readonly REQUIRED_MSG = REQUIRED_MSG;

  readonly HOTEL_FORM_ELEMENTS = HotelFormElements;

  ruleContentButtonGroupItems = ruleContentButtonGroupItems;
  ruleContentButtonGroupMap: { [key: string]: ButtonGroupItem } = {};

  propertiesScopeButtonGroupItems: ButtonGroupItem[] = [];
  propertiesScopeButtonGroupMap: { [key: string]: ButtonGroupItem } = {};

  ratesScopeButtonGroupItems: ButtonGroupItem[] = [];
  ratesScopeButtonGroupMap: { [key: string]: ButtonGroupItem } = {};

  isExludeActive = false;
  isIncludeActive = false;
  isLightExcludeActive = false;

  initialHotelApplicability = { ...initialHotelApplicability, active: false };

  lookupOptions?: LookupOptions;
  posLookupOptions: LookupOptions = {};
  marketLookupOptions: LookupOptions = {};
  familyLookupOptions: LookupOptions = {};

  availablePosNames: string[] = [];
  availableMarketsNames: string[] = [];
  availableHotelChainsFamilies: string[] = [];
  availableHotelPropertyCodesFamilies: string[] = [];

  subscription: Subscription = new Subscription();

  get showWarningMessage() {
    return (
      !this.readonly &&
      (this.ruleContentButtonGroupMap?.properties?.active || this.ruleContentButtonGroupMap.rates?.active)
    );
  }

  get nameControl(): AbstractControl | null {
    return this.hotelDetailsForm?.get(HotelFormElements.NAME);
  }

  get active(): AbstractControl | null {
    return this.hotelDetailsForm?.get(HotelFormElements.ACTIVE);
  }

  get hasPropertiesSelected(): AbstractControl | null {
    return this.applicability?.get(HotelFormElements.HAS_PROPERTIES_SELECTED);
  }

  get isPropertiesChecked(): AbstractControl | null {
    return this.applicability?.get(HotelFormElements.IS_PROPERTIES_CHECKED);
  }

  get isRatesChecked(): AbstractControl | null {
    return this.applicability?.get(HotelFormElements.IS_RATES_CHECKED);
  }

  get hasRatesSelected(): AbstractControl | null {
    return this.applicability?.get(HotelFormElements.HAS_RATES_SELECTED);
  }

  get applicability(): FormGroup {
    return this.hotelDetailsForm?.get(HotelFormElements.HOTEL_APPLICABILITY) as FormGroup;
  }

  get hasRuleContent(): AbstractControl | null {
    return this.hotelDetailsForm?.get(HotelFormElements.HAS_RULE_CONTENT);
  }

  get isModifyViewMode(): boolean {
    return this.viewMode === UppViewNames.MODIFY;
  }

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly store: Store,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.canLookupPos()) {
      this.posLookupOptions = {
        destinationComponent: UppComponentNames.POS,
        sourceComponent: UppComponentNames.HOTELS,
        sourceView: this.viewMode
      };

      this.subscription.add(
        this.store.pipe(select(selectAllAvailablePosRecords)).subscribe((result) => {
          if (result != null) {
            this.availablePosNames = result
              .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
              .filter((name) => name !== undefined);
          }
        })
      );
    }

    if (this.canLookupMarket()) {
      this.marketLookupOptions = {
        destinationComponent: UppComponentNames.MARKETS,
        sourceComponent: UppComponentNames.HOTELS,
        sourceView: this.viewMode
      };

      this.subscription.add(
        this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe((result) => {
          if (result != null) {
            this.availableMarketsNames = result
              .map((posMarketRecord) => posMarketRecord.posMarketDetail?.name)
              .filter((name) => name !== undefined);
          }
        })
      );
    }

    if (this.canLookupFamily()) {
      this.familyLookupOptions = {
        destinationComponent: `${UppComponentNames.FAMILIES}/hotel`,
        sourceComponent: UppComponentNames.HOTELS,
        sourceView: this.viewMode
      };

      this.subscription.add(
        this.store.pipe(select(selectAvailableFamiliesHotelChains)).subscribe((result) => {
          if (result != null) {
            this.availableHotelChainsFamilies = result
              .map((familyHotelChain) => familyHotelChain.name)
              .filter((name) => name !== undefined);
          }
        })
      );

      this.subscription.add(
        this.store.pipe(select(selectAvailableFamiliesHotelPropertyCodes)).subscribe((result) => {
          if (result != null) {
            this.availableHotelPropertyCodesFamilies = result
              .map((familyHotelPropertyCode) => familyHotelPropertyCode.name)
              .filter((name) => name !== undefined);
          }
        })
      );
    }
    this.navigationService.enableNavigation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hotelDetails) {
      this.hotelDetails = JSON.parse(JSON.stringify(changes.hotelDetails.currentValue));
    }

    this.store.pipe(select(selectNavigation)).subscribe((lookupOptions) => {
      this.lookupOptions = lookupOptions;
    });

    if (this.hotelDetails?.id) {
      this.posLookupOptions.sourceView = UppViewNames.MODIFY;
      this.marketLookupOptions.sourceView = UppViewNames.MODIFY;
      this.familyLookupOptions.sourceView = UppViewNames.MODIFY;
    }

    this.ruleContentButtonGroupItems.forEach((item) => (item.disabled = this.readonly));
    this.ruleContentButtonGroupMap = this.ruleContentButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.propertiesScopeButtonGroupItems = [
      {
        name: HotelFormElements.MASTER_CHAINS,
        active: false,
        title: $localize`:@@upp.global.criteria.hotelMasterChains.label:Master chain`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.CHAINS,
        active: false,
        title: $localize`:@@upp.global.criteria.hotelChains.label:Chain`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.HOTEL_CHAINS_FAMILIES,
        active: false,
        title: $localize`:@@upp.global.criteria.hotel.chains.label:Chain family`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.PROPERTY_CODES,
        active: false,
        title: $localize`:@@upp.global.criteria.hotelPropertyCodes.label:Property code`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.HOTEL_PROPERTY_CODES_FAMILIES,
        active: false,
        title: $localize`:@@upp.global.criteria.hotelPropertyCodesFamily.label:Property code family`,
        disabled: this.readonly
      }
    ];

    this.propertiesScopeButtonGroupMap = this.propertiesScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.ratesScopeButtonGroupItems = [
      {
        name: HotelFormElements.RATE_FAMILIES,
        active: false,
        title: $localize`:@@upp.global.criteria.rateFamilies.label:Rate family code`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.RATE_CODES,
        active: false,
        title: $localize`:@@upp.global.criteria.rateCodes.label:Rate code`,
        disabled: this.readonly
      },
      {
        name: HotelFormElements.COMMISSIONABLE,
        active: false,
        title: $localize`:@@upp.global.criteria.hotel.commissionable.label:Commissionable`,
        disabled: this.readonly
      }
    ];

    this.ratesScopeButtonGroupMap = this.ratesScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.initScreen();

    this.hotelDetailsForm.addControl(
      HotelFormElements.HAS_RULE_CONTENT,
      this.formBuilder.control(
        this.ruleContentButtonGroupMap?.rates?.active || this.ruleContentButtonGroupMap?.properties?.active,
        Validators.requiredTrue
      )
    );
    this.applicability?.get(HotelFormElements.SOURCE_TYPE)?.setValue(this.getSourceTypeValue());

    this.initRuleSection();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  initRuleSection() {
    this.ruleContentButtonGroupMap.properties.active =
      this.hotelDetails?.hotelApplicability?.isPropertiesChecked ?? false;
    if (this.hotelDetails?.hotelApplicability?.isPropertiesChecked) {
      this.applicability?.setControl(
        HotelFormElements.HAS_PROPERTIES_SELECTED,
        this.formBuilder.control(
          this.hotelDetails?.hotelApplicability?.hasPropertiesSelected ?? false,
          Validators.requiredTrue
        )
      );
    } else {
      this.propertiesScopeButtonGroupItems.forEach((item) => (item.active = false));
      this.applicability?.removeControl(HotelFormElements.HAS_PROPERTIES_SELECTED);
    }

    this.ruleContentButtonGroupMap.rates.active = this.hotelDetails?.hotelApplicability?.isRatesChecked ?? false;
    if (this.hotelDetails?.hotelApplicability?.isRatesChecked) {
      this.applicability?.setControl(
        HotelFormElements.HAS_RATES_SELECTED,
        this.formBuilder.control(
          this.hotelDetails?.hotelApplicability?.hasRatesSelected ?? false,
          Validators.requiredTrue
        )
      );
    } else {
      this.ratesScopeButtonGroupItems.forEach((item) => (item.active = false));
      this.applicability?.removeControl(HotelFormElements.HAS_RATES_SELECTED);
    }

    const properties = [
      { name: this.HOTEL_FORM_ELEMENTS.MASTER_CHAINS, value: this.hotelDetails?.hotelApplicability?.masterChains },
      { name: this.HOTEL_FORM_ELEMENTS.CHAINS, value: this.hotelDetails?.hotelApplicability?.chains },
      {
        name: this.HOTEL_FORM_ELEMENTS.HOTEL_CHAINS_FAMILIES,
        value: this.hotelDetails?.hotelApplicability?.hotelChainsFamilies
      },
      { name: this.HOTEL_FORM_ELEMENTS.PROPERTY_CODES, value: this.hotelDetails?.hotelApplicability?.propertyCodes },
      {
        name: this.HOTEL_FORM_ELEMENTS.HOTEL_PROPERTY_CODES_FAMILIES,
        value: this.hotelDetails?.hotelApplicability?.hotelPropertyCodesFamilies
      }
    ];

    const rates = [
      { name: this.HOTEL_FORM_ELEMENTS.RATE_CODES, value: this.hotelDetails?.hotelApplicability?.rateCodes },
      { name: this.HOTEL_FORM_ELEMENTS.RATE_FAMILIES, value: this.hotelDetails?.hotelApplicability?.rateFamilies },
      {
        name: this.HOTEL_FORM_ELEMENTS.COMMISSIONABLE,
        value: this.hotelDetails?.hotelApplicability?.commissionable ?? COMMISSIONABLE_YES
      }
    ];

    // Mark as active and set values for properties and rates for filled inputs
    properties.forEach((element) => {
      if (this.applicability?.get(element.name)?.value) {
        this.applicability?.setControl(
          HotelFormElements.HAS_PROPERTIES_SELECTED,
          this.formBuilder.control(hasActiveButtonItem(this.propertiesScopeButtonGroupItems), Validators.requiredTrue)
        );
        this.ruleContentButtonGroupMap.properties.active = true;
        this.propertiesScopeButtonGroupMap[element.name].active = true;
        this.onPropertiesScopeToggle(this.propertiesScopeButtonGroupMap[element.name], element.value);
      }
    });

    rates.forEach((element) => {
      if (this.applicability?.get(element.name)?.value) {
        this.applicability?.setControl(
          HotelFormElements.HAS_RATES_SELECTED,
          this.formBuilder.control(hasActiveButtonItem(this.ratesScopeButtonGroupItems), Validators.requiredTrue)
        );
        this.ruleContentButtonGroupMap.rates.active = true;
        this.ratesScopeButtonGroupMap[element.name].active = true;
        this.onRatesScopeToggle(this.ratesScopeButtonGroupMap[element.name], element.value);
      }
    });
    this.hotelDetailsForm.setControl(
      HotelFormElements.HAS_RULE_CONTENT,
      this.formBuilder.control(
        this.ruleContentButtonGroupMap?.rates?.active || this.ruleContentButtonGroupMap?.properties?.active,
        Validators.requiredTrue
      )
    );
  }

  initScreen() {
    this.createForm();
    this.hotelDetailsForm?.patchValue(this.hotelDetails);
  }

  createForm() {
    if (this.hotelDetailsForm) {
      this.hotelDetailsForm.addControl(
        HotelFormElements.ORGANIZATION,
        this.formBuilder.control(this.userDetailsService.getLssOrganization())
      );
      this.hotelDetailsForm.addControl(HotelFormElements.ID, this.formBuilder.control(this.hotelDetails?.id));
      this.hotelDetailsForm.addControl(HotelFormElements.VERSION, this.formBuilder.control(this.hotelDetails?.version));
      this.hotelDetailsForm.addControl(
        HotelFormElements.ACTIVE,
        this.formBuilder.control({ value: this.hotelDetails?.active, disabled: this.readonly })
      );
      this.hotelDetailsForm.addControl(
        HotelFormElements.ACTION,
        this.formBuilder.control({ value: this.getAction(), disabled: this.readonly }, [Validators.required])
      );
      this.hotelDetailsForm.addControl(
        HotelFormElements.NAME,
        this.formBuilder.control({ value: this.hotelDetails?.name, disabled: this.readonly || this.isModifyViewMode }, [
          Validators.required,
          Validators.maxLength(30),
          Validators.pattern(NAME_REGEX)
        ])
      );
      this.hotelDetailsForm.addControl(
        HotelFormElements.HAS_RULE_CONTENT,
        this.formBuilder.control(
          this.ruleContentButtonGroupMap?.rates?.active || this.ruleContentButtonGroupMap?.properties?.active,
          Validators.requiredTrue
        )
      );
      this.hotelDetailsForm.addControl(
        HotelFormElements.DESCRIPTION,
        this.formBuilder.control({ value: this.hotelDetails?.description, disabled: this.readonly }, [
          Validators.maxLength(128)
        ])
      );
      this.hotelDetailsForm.addControl(
        HotelFormElements.HOTEL_APPLICABILITY,
        this.formBuilder.group({
          isPropertiesChecked: [{ value: false, disabled: this.readonly }],
          isRatesChecked: [{ value: false, disabled: this.readonly }],
          pointOfSaleNames: [{ value: undefined, disabled: this.readonly }, [Validators.required]],
          destinationNames: [
            { value: this.hotelDetails?.hotelApplicability?.destinationNames, disabled: this.readonly },
            [Validators.required]
          ],
          masterChains: [{ value: this.hotelDetails?.hotelApplicability?.masterChains, disabled: this.readonly }],
          chains: [{ value: this.hotelDetails?.hotelApplicability?.chains, disabled: this.readonly }],
          hotelChainsFamilies: [
            { value: this.hotelDetails?.hotelApplicability?.hotelChainsFamilies, disabled: this.readonly }
          ],
          propertyCodes: [{ value: this.hotelDetails?.hotelApplicability?.propertyCodes, disabled: this.readonly }],
          hotelPropertyCodesFamilies: [
            { value: this.hotelDetails?.hotelApplicability?.hotelPropertyCodesFamilies, disabled: this.readonly }
          ],
          rateCodes: [{ value: this.hotelDetails?.hotelApplicability?.rateCodes, disabled: this.readonly }],
          rateFamilies: [{ value: this.hotelDetails?.hotelApplicability?.rateFamilies, disabled: this.readonly }],
          commissionable: [{ value: this.hotelDetails?.hotelApplicability?.commissionable, disabled: this.readonly }],
          sourceType: [{ value: this.getSourceTypeValue(), disabled: this.readonly }, [Validators.required]],
          sourceTypesGds: this.formBuilder.control({
            value: this.hotelDetails?.hotelApplicability?.sourceTypesGds ?? true,
            disabled: this.readonly
          }),
          sourceTypesAggregator: this.formBuilder.control({
            value: this.hotelDetails?.hotelApplicability?.sourceTypesAggregator ?? true,
            disabled: this.readonly
          })
        })
      );
    }
  }

  getSourceTypeValue() {
    const aggregatorValue = this.hotelDetails?.hotelApplicability?.sourceTypesAggregator;
    const gdsValue = this.hotelDetails?.hotelApplicability?.sourceTypesGds;

    if (aggregatorValue && gdsValue) {
      return SOURCE_TYPE_ALL_LABEL;
    } else if (aggregatorValue) {
      return SOURCE_TYPE_AGGREGATOR;
    } else if (gdsValue) {
      return SOURCE_TYPE_GDS;
    }
    return SOURCE_TYPE_ALL_LABEL;
  }

  getAction(): string {
    this.isIncludeActive = false;
    this.isExludeActive = false;
    this.isLightExcludeActive = false;

    switch (this.hotelDetails.action) {
      case LIGHT_EXCLUDE_ACTION:
        this.isLightExcludeActive = true;
        break;
      case EXCLUDE_ACTION:
        this.isExludeActive = true;
        break;
      case INCLUDE_ACTION:
        this.isIncludeActive = true;
        break;
    }

    return this.hotelDetails.action;
  }

  clearScreen() {
    this.hotelDetails = JSON.parse(
      JSON.stringify({
        ...initialHotelRuleUi,
        action: INITIAL_ACTION_VALUE,
        id: this.isModifyViewMode ? this.hotelDetails.id : undefined,
        organization: this.isModifyViewMode ? this.hotelDetails.organization : undefined,
        version: this.isModifyViewMode ? this.hotelDetails.version : undefined,
        name: this.isModifyViewMode ? this.hotelDetails.name : undefined,
        hotelApplicability: {
          ...initialHotelApplicability
        },
        active: false
      })
    );

    this.ruleContentButtonGroupMap.rates.active = false;
    this.ruleContentButtonGroupMap.properties.active = false;
    this.toggleRates(false);
    this.toggleProperties(false);
    this.hotelDetailsForm?.reset(this.hotelDetails);

    this.initScreen();

    this.applicability?.get(HotelFormElements.SOURCE_TYPE_GDS)?.setValue(true);
    this.applicability?.get(HotelFormElements.SOURCE_TYPE_AGGREGATOR)?.setValue(true);
    this.applicability?.get(HotelFormElements.SOURCE_TYPE)?.setValue(SOURCE_TYPE_ALL_LABEL);
  }

  onPropertiesScopeToggle(item: ButtonGroupItem, value?: string[] | string): void {
    if (item.active) {
      const validators = [Validators.required];
      let initialValue: string | string[] = INITIAL_EMPTY_VALUE;

      if (item.name === this.HOTEL_FORM_ELEMENTS.MASTER_CHAINS || item.name === this.HOTEL_FORM_ELEMENTS.CHAINS) {
        validators.push(Validators.pattern(twoAlphaNumericRegex));
      }
      if (item.name === this.HOTEL_FORM_ELEMENTS.PROPERTY_CODES) {
        validators.push(Validators.pattern(eightAlphaNumericRegex));
      }
      if (
        item.name === this.HOTEL_FORM_ELEMENTS.HOTEL_CHAINS_FAMILIES ||
        item.name === this.HOTEL_FORM_ELEMENTS.HOTEL_PROPERTY_CODES_FAMILIES
      ) {
        initialValue = [] as string[];
      }

      // Set control for active property fields
      this.applicability?.setControl(
        item.name,
        this.formBuilder.control({ value: value ?? initialValue, disabled: this.readonly }, validators)
      );
    } else {
      this.applicability.removeControl(item.name);
    }

    this.hasPropertiesSelected?.setValue(hasActiveButtonItem(this.propertiesScopeButtonGroupItems));
    this.hasPropertiesSelected?.markAsTouched();
    this.changeDetectorRef.detectChanges();
  }

  onRatesScopeToggle(item: ButtonGroupItem, value?: string[] | string): void {
    if (item.active) {
      const validators = [Validators.required];

      if (item.name === this.HOTEL_FORM_ELEMENTS.RATE_CODES || item.name === this.HOTEL_FORM_ELEMENTS.RATE_FAMILIES) {
        validators.push(Validators.pattern(threeAlphaNumericRegex));
      }
      if (item.name === this.HOTEL_FORM_ELEMENTS.COMMISSIONABLE && value === undefined) {
        value = COMMISSIONABLE_YES;
      }

      // Set control for active rate fields
      this.applicability.setControl(
        item.name,
        this.formBuilder.control({ value: value ?? INITIAL_EMPTY_VALUE, disabled: this.readonly }, validators)
      );
    } else {
      this.applicability.removeControl(item.name);
    }
    this.hasRatesSelected?.setValue(hasActiveButtonItem(this.ratesScopeButtonGroupItems));
    this.hasRatesSelected?.markAsTouched();
    this.changeDetectorRef.detectChanges();
  }

  onSourceTypeChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    let value: string;

    switch (target.value) {
      case SOURCE_TYPE_ALL_LABEL:
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_GDS)?.setValue(true);
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_AGGREGATOR)?.setValue(true);
        value = SOURCE_TYPE_ALL_LABEL;
        break;
      case SOURCE_TYPE_GDS:
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_GDS)?.setValue(true);
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_AGGREGATOR)?.setValue(false);
        value = SOURCE_TYPE_GDS;
        break;
      case SOURCE_TYPE_AGGREGATOR:
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_AGGREGATOR)?.setValue(true);
        this.applicability?.get(HotelFormElements.SOURCE_TYPE_GDS)?.setValue(false);
        value = SOURCE_TYPE_AGGREGATOR;
        break;
      default:
        value = SOURCE_TYPE_ALL_LABEL;
    }
    this.applicability?.get(HotelFormElements.SOURCE_TYPE)?.setValue(value);
  }

  onRuleContentToggle(buttonGroupItem: ButtonGroupItem): void {
    if (buttonGroupItem.name === RuleContentButtonNames.PROPERTIES) {
      this.toggleProperties(buttonGroupItem.active);
    } else if (buttonGroupItem.name === RuleContentButtonNames.RATES) {
      this.toggleRates(buttonGroupItem.active);
    }
    this.hasRuleContent?.setValue(hasActiveButtonItem(this.ruleContentButtonGroupItems), Validators.requiredTrue);
    this.hasRuleContent?.markAsTouched();
  }

  onActivationToggleChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.active?.setValue(input.checked);
    this.hotelDetails.active = input.checked;
  }

  setExcludeSelected() {
    if (this.isExludeActive) {
      this.updateActionState(false, false, false, null);
    } else {
      this.updateActionState(true, false, false, EXCLUDE_ACTION);
    }
  }

  setIncludeSelected() {
    if (this.isIncludeActive) {
      this.updateActionState(false, false, false, null);
    } else {
      this.updateActionState(false, true, false, INCLUDE_ACTION);
    }
  }

  setLightExcludeSelected() {
    if (this.isLightExcludeActive) {
      this.updateActionState(false, false, false, null);
    } else {
      this.updateActionState(false, false, true, LIGHT_EXCLUDE_ACTION);
    }
  }

  isExcludeSelected(): boolean {
    return this.isExludeActive;
  }

  isIncludeSelected(): boolean {
    return this.isIncludeActive;
  }

  isLightExcludeSelected(): boolean {
    return this.isLightExcludeActive;
  }

  canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }

  canLookupFamily(): boolean {
    return this.canLookup(PERMISSION_VIEW_FAMILY);
  }

  canLookup(permissionName: string): boolean {
    if (this.readonly) {
      return false;
    }
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    return permissions?.some((p) => p === permissionName) ?? false;
  }

  private updateActionState(exclude: boolean, include: boolean, lightExclude: boolean, actionValue: string | null) {
    this.isExludeActive = exclude;
    this.isIncludeActive = include;
    this.isLightExcludeActive = lightExclude;
    this.hotelDetailsForm?.get(HotelFormElements.ACTION)?.setValue(actionValue);
    this.hotelDetailsForm?.get(HotelFormElements.ACTION)?.markAsTouched();
  }

  private toggleProperties(active: boolean): void {
    this.applicability?.get(HotelFormElements.IS_PROPERTIES_CHECKED)?.setValue(active);
    if (active) {
      this.applicability.setControl(
        HotelFormElements.HAS_PROPERTIES_SELECTED,
        this.formBuilder.control(false, Validators.requiredTrue)
      );
      this.applicability.setControl(HotelFormElements.IS_PROPERTIES_CHECKED, this.formBuilder.control(true));
    } else {
      this.propertiesScopeButtonGroupItems.forEach((item) => {
        item.active = active;
        this.onPropertiesScopeToggle(this.propertiesScopeButtonGroupMap[item.name]);
      });
      this.applicability.removeControl(HotelFormElements.HAS_PROPERTIES_SELECTED);
    }
  }

  private toggleRates(active: boolean): void {
    this.applicability?.get(HotelFormElements.IS_RATES_CHECKED)?.setValue(active);

    if (active) {
      this.applicability.setControl(
        HotelFormElements.HAS_RATES_SELECTED,
        this.formBuilder.control(false, Validators.requiredTrue)
      );
      this.applicability.setControl(HotelFormElements.IS_RATES_CHECKED, this.formBuilder.control(true));
    } else {
      this.ratesScopeButtonGroupItems.forEach((item) => {
        item.active = active;
        this.onRatesScopeToggle(this.ratesScopeButtonGroupMap[item.name]);
      });
      this.applicability.removeControl(HotelFormElements.HAS_RATES_SELECTED);
    }
  }
}
