import {
  JourneyUi,
  ALL_ACTION,
  RULE_STATUS_ALL,
  RULE_STATUS_ACTIVE,
  JourneyUiCarrierScope,
  JourneyUiSubRule,
  CodeshareInformationsUi,
  CarrierDataUi,
  JourneyUiRouteScope
} from './flight-rule-ui';
import { JourneySearchCriteria, JourneySearchCriteriaUi } from './flight-rule-search-criteria';
import {
  CarrierData,
  CodeshareInformation,
  FlightRange,
  JourneyCarrier,
  JourneySubRule,
  JourneyScope,
  FareType,
  ContentType,
  Journey,
  JourneyRoute
} from './journey';
import { flightRangesRegexPattern } from '../../service/model/common/validators';
import { getPropertyByKey } from '../../util/utils';
import { SimplifiedAirFamily } from '../../families/air/model/air-family';

const FLIGHT_RANGES_REGEXP = new RegExp(flightRangesRegexPattern, 'g');

const JOURNEY_CONTENT_SCOPE_MAP = {
  carrier: JourneyScope.Carrier,
  route: JourneyScope.Route,
  flightsInformation: JourneyScope.Journey,
  fare: JourneyScope.Fare
};

export const convertToJourney = (journey: JourneyUi): Journey => {
  // Clone the model because it is added in the store later and we don't want to modify it.
  const journeyUi: JourneyUi = JSON.parse(JSON.stringify(journey));

  const journeyData: Journey = {
    rule: {
      ...journeyUi.rule,
      description: journeyUi.rule.description ?? undefined
    },
    applicability: {},
    content: {} as any
  };

  journeyData.applicability.pointOfSales = stringToArray(journeyUi.applicability, 'pointOfSaleName')?.map(
    (posName: any) => ({
      name: posName
    })
  );

  journeyData.applicability.marketPairs = journeyUi.applicability.marketPairs?.map((marketPair) => ({
    firstMarket: { name: marketPair.firstMarketName },
    secondMarket: { name: marketPair.secondMarketName }
  }));

  journeyData.applicability.contentTypes = journeyUi.applicability.contentTypes?.length
    ? journeyUi.applicability.contentTypes
    : [ContentType.EDIFACT];

  journeyData.applicability.products = journeyUi.applicability.products;

  const subRules = convertJourneyUiSubrulesToJourneySubRules(journeyUi.content?.subRules);

  journeyData.content = {
    scope: getJourneyScope(subRules),
    subRules
  };

  return journeyData;
};

const convertJourneyUiSubrulesToJourneySubRules = (subRulesUi?: JourneyUiSubRule[]): JourneySubRule[] =>
  subRulesUi?.map((subRuleUi) => {
    const subRule: JourneySubRule = {
      applicability: {},
      content: {
        action: subRuleUi.content?.action ?? undefined,
        value: subRuleUi.content?.value ?? undefined
      }
    };

    const carrier = subRuleUi.applicability.carrier;
    const route = subRuleUi.applicability.route;
    const journey = subRuleUi.applicability.journey;
    const fare = subRuleUi.applicability.fare;

    if (carrier) {
      subRule.applicability.carrier = convertJourneyUiCarrierToJourneyCarrier(carrier);
    }

    if (route) {
      subRule.applicability.route = convertJourneyUiRouteToJourneyRoute(route);
    }

    if (journey) {
      subRule.applicability.flightsInformation = {
        flightCategories: subRuleUi.applicability.journey?.flightCategories ?? []
      };
    }

    if (fare) {
      subRule.applicability.fare = fare;
      if (subRule.applicability.fare?.fareTypeNames) {
        delete subRule.applicability.fare.fareTypeNames;
      }
    }

    return subRule;
  }) ?? [];

export const convertToJourneyUi = (journeyData: Journey): JourneyUi => {
  const subRules = journeyData.content?.subRules?.map((subRule) => {
    const subRuleUi: JourneyUiSubRule = {
      applicability: {},
      content: subRule.content
    };

    const carrier = subRule.applicability.carrier;
    const route = subRule.applicability.route;
    const journey = subRule.applicability.flightsInformation;

    if (carrier) {
      subRuleUi.applicability.carrier = convertJourneyCarrierToJourneyUiCarrier(carrier);
    }

    if (route) {
      subRuleUi.applicability.route = convertJourneyRouteToJourneyUiRoute(route);
    }

    if (journey) {
      subRuleUi.applicability.journey = journey;
    }

    const fare = subRule.applicability.fare;
    subRuleUi.applicability.fare = fare;
    if (fare) {
      const fareTypeNames: string[] = [];
      if (fare.fareTypes) {
        fareTypeNames.push(...fare.fareTypes.filter((fareType) => fareType !== FareType.RU));
      }
      if (fare.subFareTypes) {
        fareTypeNames.push(...fare.subFareTypes);
      }

      subRuleUi.applicability.fare = { ...subRuleUi.applicability.fare!, fareTypeNames };
    }

    return subRuleUi;
  });

  return {
    rule: journeyData.rule,
    applicability: {
      pointOfSaleName: journeyData.applicability.pointOfSales?.[0]?.name,
      marketPairs: journeyData.applicability.marketPairs?.map((marketPair) => ({
        firstMarketName: marketPair.firstMarket?.name,
        secondMarketName: marketPair.secondMarket?.name
      })),
      contentTypes: journeyData.applicability.contentTypes,
      products: journeyData.applicability.products
    },
    content: {
      subRules
    }
  };
};

export const convertToJourneySearchCriteria = (searchCriteriaUi: JourneySearchCriteriaUi): JourneySearchCriteria => {
  const searchCriteria: JourneySearchCriteria = {
    rule: {
      organization: searchCriteriaUi.rule.organization
    }
  };

  if (searchCriteriaUi.rule.name !== undefined && searchCriteriaUi.rule.name.length > 0) {
    searchCriteria.rule.name = searchCriteriaUi.rule.name;
  }

  if (searchCriteriaUi.rule.ruleStatus !== undefined && searchCriteriaUi.rule.ruleStatus !== RULE_STATUS_ALL) {
    searchCriteria.rule.active = searchCriteriaUi.rule.ruleStatus === RULE_STATUS_ACTIVE;
  }

  if (searchCriteriaUi.rule.actionType !== undefined && searchCriteriaUi.rule.actionType !== ALL_ACTION) {
    searchCriteria.rule.action = searchCriteriaUi.rule.actionType;
  }

  if (searchCriteriaUi.applicability !== undefined) {
    searchCriteria.applicability = {
      pointOfSale: {
        names: searchCriteriaUi.applicability.pointOfSaleNames
      },
      marketPairs: {
        firstMarket: {
          names: searchCriteriaUi.applicability.firstMarketNames
        },
        secondMarket: {
          names: searchCriteriaUi.applicability.secondMarketNames
        }
      },
      contentTypes: searchCriteriaUi.applicability.contentTypes
    };
  }

  return searchCriteria;
};

const convertJourneyUiCarrierToJourneyCarrier = (carrierUi: JourneyUiCarrierScope): JourneyCarrier => {
  const carrier: JourneyCarrier = {};
  if (carrierUi.carrierInformations?.carrierCodes) {
    carrier.carrierInformations = (carrierUi.carrierInformations?.carrierCodes as string[]).map((carrierCode) => ({
      carrierCode
    }));
  } else if (carrierUi.carrierInformations?.alliances) {
    carrier.carrierInformations = (carrierUi.carrierInformations?.alliances as string[]).map((alliance) => ({
      alliance
    }));
  } else if (carrierUi.carrierInformations?.carriersFamily) {
    const airlineFamilies = carrierUi.carrierInformations?.availableAirlineFamilies ?? [];
    carrier.carrierInformations = (carrierUi.carrierInformations?.carriersFamily as string[]).map(
      (innerFamilyName) => ({
        carriersFamily: { name: innerFamilyName, id: airlineFamilies.find((a) => a.name === innerFamilyName)?.id ?? '' }
      })
    );
  }
  if (carrierUi.codeshareInformations) {
    carrier.codeshareInformations = transformCodeshareInformation(carrierUi.codeshareInformations);
  }

  if (carrierUi.segmentPositions) {
    carrier.segmentPositions = carrierUi.segmentPositions.map((segmentPosition) => parseInt(segmentPosition, 10));
  }

  carrier.connectionType = carrierUi.connectionType;

  if (carrierUi.flightRanges) {
    carrier.flightRanges = carrierUi.flightRanges.map(convertFlightRangesStringToObject);
  }

  return carrier;
};

const transformCodeshareInformation = (codeshareInformationUi: CodeshareInformationsUi): CodeshareInformation[] => {
  const codeshareInformations: CodeshareInformation[] = [];
  for (const keyName in codeshareInformationUi) {
    if (Object.prototype.hasOwnProperty.call(codeshareInformationUi, keyName)) {
      let codeshareInformation: CodeshareInformation = {};
      const codeshareCarrierData = getPropertyByKey(codeshareInformationUi, keyName);
      if (codeshareCarrierData.carrierCodes?.length > 0) {
        codeshareInformation = {
          [keyName]: { carrierCode: codeshareCarrierData.carrierCodes }
        };
      } else if (codeshareCarrierData.alliances?.length > 0) {
        codeshareInformation = {
          [keyName]: { alliance: codeshareCarrierData.alliances }
        };
      } else if (codeshareCarrierData.carriersFamily?.length > 0) {
        const availableAirFamilies = codeshareCarrierData?.availableAirlineFamilies ?? [];
        const id =
          availableAirFamilies.find((a: SimplifiedAirFamily) => a.name === codeshareCarrierData.carriersFamily)?.id ??
          '';
        codeshareInformation = {
          [keyName]: {
            carriersFamily: {
              name: codeshareCarrierData.carriersFamily,
              id
            }
          }
        };
      }

      if (Object.keys(codeshareInformation).length > 0) {
        codeshareInformations.push(codeshareInformation);
      }
    }
  }
  return codeshareInformations;
};

const convertJourneyUiRouteToJourneyRoute = (route: JourneyUiRouteScope): JourneyRoute => ({
  ...(route?.markets?.length
    ? {
        routeMarkets: route.markets?.map((x) => ({
          market: { name: x.name! },
          marketPosition: x.position ? Number(x.position) : undefined
        }))
      }
    : {}),
  ...(route?.rangeConnectingTime
    ? {
        rangeConnectingTime: {
          minCT: route.rangeConnectingTime.minCT.hour * 60 + route.rangeConnectingTime.minCT.minute,
          maxCT: route.rangeConnectingTime.maxCT.hour * 60 + route.rangeConnectingTime.maxCT.minute
        }
      }
    : {})
});

const convertFlightRangesStringToObject = (flightRangeString: string): FlightRange => {
  const match = [...flightRangeString.matchAll(FLIGHT_RANGES_REGEXP)][0];
  const flightRange = {
    carrierCode: match[1],
    startNumber: parseInt(match[2], 10)
  } as any;
  flightRange.endNumber = match[4] ? parseInt(match[4], 10) : flightRange.startNumber;
  if (match[5]) {
    flightRange.operationalSuffix = match[5];
  }
  return flightRange;
};

const convertFlightRangesObjectToString = (flightRange: FlightRange): string => {
  let flightRangeString = flightRange.carrierCode + String(flightRange.startNumber).padStart(4, '0');
  if (flightRange.endNumber) {
    flightRangeString += '-' + String(flightRange.endNumber).padStart(4, '0');
  }
  if (flightRange.operationalSuffix) {
    flightRangeString += flightRange.operationalSuffix;
  }
  return flightRangeString;
};

const convertJourneyCarrierToJourneyUiCarrier = (carrier: JourneyCarrier): JourneyUiCarrierScope => {
  const carrierUi: JourneyUiCarrierScope = {};

  if (carrier.carrierInformations?.length) {
    carrierUi.carrierInformations = {};
    if (carrier.carrierInformations?.[0].carrierCode) {
      carrierUi.carrierInformations.carrierCodes = carrier.carrierInformations
        .map((carrierInformation) => carrierInformation.carrierCode)
        .filter((x) => x !== undefined);
    } else if (carrier.carrierInformations?.[0].alliance) {
      carrierUi.carrierInformations.alliances = carrier.carrierInformations
        .map((carrierInformation) => carrierInformation.alliance)
        .filter((x) => x !== undefined);
    } else {
      carrierUi.carrierInformations.carriersFamily = carrier.carrierInformations
        ?.map((carrierInformation) => carrierInformation.carriersFamily?.name)
        .filter((x) => x !== undefined);
    }
  }

  if ((carrier.codeshareInformations?.length ?? 0) > 0) {
    carrierUi.codeshareInformations = convertCodeshareInformationsToUi(carrier.codeshareInformations!);
  }

  if (carrier.flightRanges) {
    carrierUi.flightRanges = carrier.flightRanges.map(convertFlightRangesObjectToString);
  }

  if (carrier.connectionType) {
    carrierUi.connectionType = carrier.connectionType;
  }

  if (carrier.segmentPositions) {
    // Convert to strings since multi select badges works only with strings
    carrierUi.segmentPositions = carrier.segmentPositions.map((segmentPosition) => segmentPosition + '');
  }

  return carrierUi;
};

const convertJourneyRouteToJourneyUiRoute = (route: JourneyRoute): JourneyUiRouteScope => ({
  ...(route.routeMarkets
    ? {
        markets: route.routeMarkets.map((x) => ({
          name: x.market.name,
          position: x.marketPosition
        }))
      }
    : {}),
  ...(route?.rangeConnectingTime
    ? {
        rangeConnectingTime: {
          minCT: {
            hour: Math.floor((route.rangeConnectingTime?.minCT ?? 0) / 60),
            minute: (route.rangeConnectingTime?.minCT ?? 0) % 60,
            second: 0
          },
          maxCT: {
            hour: Math.floor((route.rangeConnectingTime?.maxCT ?? 0) / 60),
            minute: (route.rangeConnectingTime?.maxCT ?? 0) % 60,
            second: 0
          }
        }
      }
    : {})
});

const convertCodeshareInformationsToUi = (codeshareInformations: CodeshareInformation[]): CodeshareInformationsUi => {
  const codeshareInformationsUi: CodeshareInformationsUi = {};
  for (const codeshareInformation of codeshareInformations) {
    if (codeshareInformation.primeData) {
      codeshareInformationsUi.primeData = codeshareInformationsUi.primeData || {};
      convertCarrierDataToUi(codeshareInformation.primeData, codeshareInformationsUi.primeData);
    }

    if (codeshareInformation.operatingCarrierData) {
      codeshareInformationsUi.operatingCarrierData = codeshareInformationsUi.operatingCarrierData || {};
      convertCarrierDataToUi(codeshareInformation.operatingCarrierData, codeshareInformationsUi.operatingCarrierData);
    }

    if (codeshareInformation.marketingCarrierData) {
      codeshareInformationsUi.marketingCarrierData = codeshareInformationsUi.marketingCarrierData || {};
      convertCarrierDataToUi(codeshareInformation.marketingCarrierData, codeshareInformationsUi.marketingCarrierData);
    }
  }
  return codeshareInformationsUi;
};

const convertCarrierDataToUi = (carrierData: CarrierData, carrierDataUi: CarrierDataUi): void => {
  carrierDataUi.carrierCodes = carrierData.carrierCode;
  carrierDataUi.alliances = carrierData.alliance;
  carrierDataUi.carriersFamily = carrierData.carriersFamily?.name;
};

const stringToArray = (obj: any, propertyName: string): any => {
  if (typeof obj?.[propertyName] === 'string') {
    obj[propertyName] = [obj[propertyName]];
  }
  return obj?.[propertyName];
};

const getJourneyScope = (subRules: JourneySubRule[]): JourneyScope => {
  let contentScope = JourneyScope.Custom;

  const contentScopes: Set<string> = subRules?.reduce((acc: Set<string>, subRule) => {
    Object.keys(subRule.applicability).forEach((cscope) => acc.add(cscope));
    return acc;
  }, new Set<string>());

  if (contentScopes?.size === 1) {
    contentScope = getPropertyByKey(JOURNEY_CONTENT_SCOPE_MAP, contentScopes.values().next().value);
  }

  return contentScope;
};
