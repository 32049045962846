import { Component, OnDestroy, OnInit } from '@angular/core';
import { mergeMap, Observable, of, Subscription } from 'rxjs';
import { AirFamiliesService } from '../../../service/air-families.service';
import { Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMessages } from '../../../components/upp-notification/upp-notification.component';
import { FormElements, FormKey } from '../../../model/common-elements';
import {
  MAX_LENGTH_STRING_VALIDATION_MESSAGES,
  NAME_WITH_SPACES_VALIDATION_MESSAGES,
  TWO_SYMBOLS_REQUIRED
} from '../../../service/model/common/validation-messages';
import { alphanumericSpaceMax30, twoAlphaNumericRegex } from '../../../service/model/common/validators';
import { UppViewNames, ViewMode } from '../../../service/model';
import { carrierCodeValidator } from '../../../flights/flights-details/validators/carrier-code-validator';
import { includeTwoCarriersValidator } from '../../../components/form-validators/include-two-carriers-validator';
import { valuesNotAllowedValidator } from '../../../components/form-validators/values-not-allowed-validator';
import { canExcludeCarriersValidator } from '../../../components/form-validators/can-exclude-carriers-validator';
import { UserDetailsService } from '../../../service/user-details.service';
import { AirFamily } from '../model/air-family';
import { AirFamilyResponse } from '../model/air-family-response';
import { DEFAULT_SUCCESS, UppNotification, UppNotificationStatus } from '../../../model/notification';
import {
  airFamilyCreated,
  airFamilyModified,
  airFamilyNotificationClosed,
  saveAirFamily,
  saveLastActiveAirFamilyView,
  setNotificationData
} from '../../../store/families/air/air-families-action';
import { selectAirFamilyNotification, selectAirFamilyState } from '../../../store/families/air/air-families-selector';
import { saveKeyValue } from '../../../store/common/common-actions';
import { selectKeyValuePairs } from '../../../store/common/common-selectors';
import { RouteURL } from '../../../model/route-url';
import { NotificationService } from '../../../service/notification.service';

@Component({
  selector: 'app-air-family-form',
  templateUrl: './air-family-form.component.html'
})
export class AirFamilyFormComponent implements OnDestroy, OnInit {
  NAME_WITH_SPACES_VALIDATION_MESSAGES = NAME_WITH_SPACES_VALIDATION_MESSAGES;
  MAX_LENGTH_STRING_VALIDATION_MESSAGES = MAX_LENGTH_STRING_VALIDATION_MESSAGES;
  TWO_SYMBOLS_REQUIRED = TWO_SYMBOLS_REQUIRED;
  FormElements = FormElements;
  includedCarriers = `includedCarriers`;
  excludedCarriers = `excludedCarriers`;

  titlesMap = {
    [UppViewNames.CREATE]: $localize`:@@upp.airFamilyForm.createTitle:Create Air Family`,
    [UppViewNames.DISPLAY]: $localize`:@@upp.airFamilyForm.displayTitle:Display Air Family`,
    [UppViewNames.MODIFY]: $localize`:@@upp.airFamilyForm.modifyTitle:Modify Air Family`,
    [UppViewNames.SEARCH]: $localize`:@@upp.airFamilyForm.searchTitle:Search Air Family`
  };

  carrierCodesIncludeValidationMessages = {
    ...TWO_SYMBOLS_REQUIRED,
    carrierCodeValidator: () =>
      $localize`:@@upp.validation.yyValueInCarrierCode:YY covers all airlines and cannot be combined with other airline codes`,
    includeTwoOrMoreCarriers: () =>
      $localize`:@@upp.validation.includeTwoOrMoreCarriers:Enter 2 or more carriers in Include section`
  };

  carrierCodesExcludeValidationMessages = {
    ...TWO_SYMBOLS_REQUIRED,
    valuesNotAllowed: (valuesNotAllowed: string[]) =>
      $localize`:@@upp.validation.valuesNotAllowed:${valuesNotAllowed[0]} carrier is not allowed in Exclude section`,
    canExcludeCarriers: () =>
      $localize`:@@upp.validation.canExcludeCarriers:To exclude carriers, enter YY code in Include section`,
    excludeYYValidator: () =>
      $localize`:@@upp.validation.excludeYYValidator:Enter one or more carriers in Exclude section`
  };

  airFamilyForm!: FormGroup;
  currentViewMode!: ViewMode;
  ViewMode = UppViewNames;
  isLoading!: boolean;
  mainMessages!: NotificationMessages;
  notification$!: Observable<UppNotification | undefined>;

  private readonly subscription = new Subscription();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly airFamiliesService: AirFamiliesService,
    private readonly userDetailsService: UserDetailsService,
    private readonly notificationService: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  get nameControl(): AbstractControl | null {
    return this.airFamilyForm.get(FormElements.NAME);
  }

  get descriptionControl(): AbstractControl | null {
    return this.airFamilyForm.get(FormElements.DESCRIPTION);
  }

  get carrierCodesIncludeControl(): AbstractControl | null {
    return this.airFamilyForm.get(this.includedCarriers);
  }

  get carrierCodesExcludeControl(): AbstractControl | null {
    return this.airFamilyForm.get(this.excludedCarriers);
  }

  get isDisplayViewMode(): boolean {
    return this.currentViewMode === this.ViewMode.DISPLAY;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.activatedRoute.data
        .pipe(
          mergeMap((routeData) => {
            this.currentViewMode = routeData.viewMode;
            this.createNotificationMessages();
            this.saveLastActiveViewMode();
            this.notification$ = this.store.select(selectAirFamilyNotification(this.currentViewMode));
            return this.store.select(selectAirFamilyState(this.currentViewMode));
          })
        )
        .subscribe((airFamily) => {
          this.createForm(airFamily);
          this.patchForm();
        })
    );
  }

  ngOnDestroy() {
    const value = this.getFormValue();
    this.store.dispatch(
      saveKeyValue({
        value,
        key: `${FormKey.AirFamilyForm}-${this.currentViewMode}`
      })
    );
    this.subscription.unsubscribe();
  }

  saveAirFamily() {
    this.airFamilyForm.markAllAsTouched();
    if (this.airFamilyForm.invalid) {
      return;
    }

    this.currentViewMode === UppViewNames.CREATE ? this.saveNewAirFamily() : this.saveModifiedAirFamily();
  }

  clearForm() {
    const name = this.nameControl?.value;
    this.airFamilyForm.reset();
    if (this.isModifyViewMode()) {
      this.airFamilyForm.controls[FormElements.NAME].setValue(name);
    }
  }

  closeNotification() {
    this.store.dispatch(airFamilyNotificationClosed({ viewMode: this.currentViewMode }));
  }

  displayAirFamily() {
    this.subscription.add(
      this.store.select(selectAirFamilyState(UppViewNames.CREATE)).subscribe((airFamily) => {
        const airFamilyCopy = { ...airFamily };
        delete airFamilyCopy.statusNotification;
        this.store.dispatch(
          saveAirFamily({
            selectedAirFamily: airFamilyCopy,
            viewMode: UppViewNames.DISPLAY
          })
        );

        this.store.dispatch(setNotificationData({ notification: {}, viewMode: UppViewNames.DISPLAY }));
        this.resetFormState(UppViewNames.DISPLAY);
        this.router.navigate([RouteURL.familyAirDisplay]);
      })
    );
  }

  openModify() {
    this.subscription.add(
      this.store.select(selectAirFamilyState(UppViewNames.DISPLAY)).subscribe((airFamily) => {
        this.store.dispatch(
          saveAirFamily({
            selectedAirFamily: { ...airFamily },
            viewMode: UppViewNames.MODIFY
          })
        );
        this.router.navigate([RouteURL.familyAirModify]);
      })
    );
  }

  cancelAirFamilyModification() {
    this.airFamilyForm.reset();
    this.router.navigate([RouteURL.familyAirDisplay]);
  }

  isModifyViewMode() {
    return this.currentViewMode === this.ViewMode.MODIFY;
  }

  private addNotificationData(airFamilyResponse: AirFamilyResponse): void {
    this.notificationService.handleResponse(airFamilyResponse, this.currentViewMode);
    if (airFamilyResponse.status.state === UppNotificationStatus.SUCCESS) {
      if (this.currentViewMode === UppViewNames.CREATE) {
        airFamilyResponse.carriersFamily.statusType = airFamilyResponse.status.state;
        airFamilyResponse.carriersFamily.statusNotification = {
          success: [DEFAULT_SUCCESS]
        };
        airFamilyResponse.carriersFamily.statusNotification.links = [
          {
            id: airFamilyResponse.carriersFamily.id ?? '',
            name: airFamilyResponse.carriersFamily.name
          }
        ];
        this.notification$ = of(airFamilyResponse.carriersFamily.statusNotification);
        this.store.dispatch(
          setNotificationData({
            notification: airFamilyResponse.carriersFamily.statusNotification,
            viewMode: this.currentViewMode
          })
        );
      }

      if (this.isModifyViewMode()) {
        airFamilyResponse.carriersFamily.statusNotification = {
          success: [DEFAULT_SUCCESS]
        };
        this.notification$ = of(airFamilyResponse.carriersFamily.statusNotification);
        this.store.dispatch(
          setNotificationData({
            notification: airFamilyResponse.carriersFamily.statusNotification,
            viewMode: UppViewNames.DISPLAY
          })
        );
      }
    }
  }

  private createForm(airFamily: AirFamily) {
    if (this.currentViewMode === UppViewNames.CREATE) {
      airFamily = {} as AirFamily;
    }

    this.airFamilyForm = this.formBuilder.group({
      [FormElements.ID]: [airFamily.id],
      [FormElements.NAME]: [
        { value: airFamily.name, disabled: this.currentViewMode !== UppViewNames.CREATE },
        [Validators.required, Validators.maxLength(30), Validators.pattern(alphanumericSpaceMax30)]
      ],
      [FormElements.DESCRIPTION]: [airFamily.description, Validators.maxLength(128)],
      [this.includedCarriers]: [
        airFamily.elements?.includedCarriers,
        [
          Validators.required,
          Validators.pattern(twoAlphaNumericRegex),
          carrierCodeValidator,
          includeTwoCarriersValidator
        ]
      ],
      [this.excludedCarriers]: [
        airFamily.elements?.excludedCarriers,
        [Validators.pattern(twoAlphaNumericRegex), valuesNotAllowedValidator(['YY'])]
      ]
    });

    if (this.carrierCodesIncludeControl) {
      this.carrierCodesExcludeControl?.addValidators(canExcludeCarriersValidator(this.carrierCodesIncludeControl));
    }

    this.subscription.add(
      this.carrierCodesIncludeControl?.statusChanges.subscribe(() => {
        this.carrierCodesExcludeControl?.markAllAsTouched();
        this.carrierCodesExcludeControl?.updateValueAndValidity();
      })
    );

    if (this.isDisplayViewMode) {
      this.airFamilyForm.disable();
    }
  }

  private createNotificationMessages() {
    this.mainMessages =
      this.currentViewMode === UppViewNames.CREATE
        ? {
            error: $localize`:@@upp.families.air.create.mainErrorText:Creation of Air family failed due to the following errors:`,
            warning: $localize`:@@upp.families.air.create.mainWarningText:Warnings were generated during the creation process of Air family:`,
            success: $localize`:@@upp.families.air.create.mainSuccessText:Well done! You get this message about your successful Air Family creation`,
            linkSuccessText: $localize`:@@upp.families.air.create.linkSuccessText:Display new Air Family: `
          }
        : {
            error: $localize`:@@upp.families.air.mainErrorText:The following errors for Air family appeared:`,
            warning: $localize`:@@upp.families.air.mainWarningText:The following warning for Air family appeared:`,
            success: $localize`:@@upp.families.air.mainSuccessText:The Air family was stored successfully.`
          };
  }

  private createAirFamilyRequest() {
    const formValue = this.airFamilyForm.getRawValue();
    const request = {
      version: '1.0',
      carriersFamily: {
        id: formValue.id,
        name: formValue.name,
        description: formValue.description,
        elements: {
          includedCarriers: formValue.includedCarriers,
          excludedCarriers: formValue.excludedCarriers
        }
      } as AirFamily
    };
    request.carriersFamily = this.userDetailsService.assignEntity(request.carriersFamily);
    return request;
  }

  private patchForm() {
    this.subscription.add(
      this.store.select(selectKeyValuePairs).subscribe((kvp) => {
        const savedForm = kvp[`${FormKey.AirFamilyForm}-${this.currentViewMode}`];
        if (savedForm) {
          this.airFamilyForm.patchValue(savedForm);
          this.airFamilyForm.markAllAsTouched();
        }
      })
    );
  }

  private getFormValue() {
    return Object.values(this.airFamilyForm.getRawValue()).every((value) => !value)
      ? null
      : this.airFamilyForm.getRawValue();
  }

  private saveNewAirFamily() {
    this.subscription.add(
      this.airFamiliesService.save(this.createAirFamilyRequest()).subscribe((airFamilyResponse) => {
        this.addNotificationData(airFamilyResponse);

        if (airFamilyResponse.carriersFamily) {
          this.store.dispatch(
            saveAirFamily({
              selectedAirFamily: airFamilyResponse.carriersFamily,
              viewMode: UppViewNames.CREATE
            })
          );
          this.store.dispatch(airFamilyCreated({ airFamily: airFamilyResponse.carriersFamily }));
          this.resetFormState(UppViewNames.DISPLAY);
          this.resetFormState(UppViewNames.MODIFY);
          this.airFamilyForm.reset();
        }
      })
    );
  }

  private saveModifiedAirFamily() {
    this.subscription.add(
      this.airFamiliesService.modify(this.createAirFamilyRequest()).subscribe((airFamilyResponse) => {
        this.addNotificationData(airFamilyResponse);
        this.store.dispatch(
          saveAirFamily({
            selectedAirFamily: airFamilyResponse.carriersFamily,
            viewMode: UppViewNames.DISPLAY
          })
        );
        this.store.dispatch(airFamilyModified({ airFamily: airFamilyResponse.carriersFamily }));
        this.resetFormState(UppViewNames.DISPLAY);
        this.resetFormState(UppViewNames.MODIFY);
        this.airFamilyForm.reset();
        this.router.navigate([RouteURL.familyAirDisplay]);
      })
    );
  }

  private resetFormState(viewMode: ViewMode) {
    this.store.dispatch(
      saveKeyValue({
        value: null,
        key: `${FormKey.AirFamilyForm}-${viewMode}`
      })
    );
  }

  private saveLastActiveViewMode() {
    // Only 'modify' and 'display' activate the display navigation menu
    if (this.currentViewMode !== UppViewNames.CREATE) {
      this.store.dispatch(saveLastActiveAirFamilyView({ lastActiveView: this.currentViewMode }));
    }
  }
}
