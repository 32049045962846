import { FareSearchCriteriaUi, initialFareRuleSearchCriteria } from '../../fares/model/fare-rule-search-criteria';
import { FareRuleUi, initialFareRuleUi } from '../../fares/model/fare-rule-ui';
import { Notification } from '../../model/notification';

export const FARES_FEATURE = 'fareUppUi';

export interface FaresState {
  fareRules: FareRuleUi[];
  fareRule: {
    create: FareRuleStatus;
    modify: FareRuleStatus;
    display: FareRuleStatus;
    search: {
      sending: boolean;
      value: FareSearchCriteriaUi;
    };
    delete: {
      sending: boolean;
    };
  };
  notification?: Notification;
}

export interface FareRuleStatus {
  sending: boolean;
  active?: boolean;
  value: FareRuleUi;
  updatedRule?: FareRuleUi;
}

export const initialFareRuleStatus: FareRuleStatus = {
  sending: false,
  active: false,
  value: initialFareRuleUi
};

export const initialFaresState: FaresState = {
  fareRules: [],
  fareRule: {
    create: initialFareRuleStatus,
    modify: initialFareRuleStatus,
    display: initialFareRuleStatus,
    search: {
      sending: false,
      value: initialFareRuleSearchCriteria
    },
    delete: {
      sending: false
    }
  },
  notification: {}
};
