import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AirFamiliesState } from './air-families-state';
import { ViewMode } from '../../../service/model';

export const AIR_FAMILIES_FEATURE = 'airFamilies';
export const airFamiliesSelector = createFeatureSelector<AirFamiliesState>(AIR_FAMILIES_FEATURE);

export const selectAirFamilies = createSelector(airFamiliesSelector, (state: AirFamiliesState) => state.airFamilies);

export const selectAirFamilyState = (viewMode: ViewMode) =>
  createSelector(airFamiliesSelector, (state: AirFamiliesState) => state.selectedAirFamily[viewMode]);

export const selectAirFamily = (id: string) =>
  createSelector(selectAirFamilies, (airFamilies) => airFamilies.find((airFamily) => airFamily.id === id));

export const selectAirFamilyNotification = (viewMode: ViewMode) =>
  createSelector(airFamiliesSelector, (state: AirFamiliesState) => state.notifications[viewMode]);

export const selectLastActiveAirFamilyView = createSelector(
  airFamiliesSelector,
  (state: AirFamiliesState) => state.lastActiveView
);

export const selectSelectedAirFamilies = createSelector(
  airFamiliesSelector,
  (state: AirFamiliesState) => state.selectedAirFamilyNames
);

export const selectAvailableAirFamilies = createSelector(
  airFamiliesSelector,
  (state: AirFamiliesState) => state.availableAirFamilies
);
