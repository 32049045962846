import { initialHotelState, HotelsState, initialHotelRuleStatus, HotelRuleStatus } from './hotels-state';
import { HotelsActions, HOTELS_ACTION } from './hotels-action';
import { HotelRuleUi, initialHotelRuleUi } from '../../hotels/model';
import { shouldNotificationCleared } from '../helpers/reducer-helper';
import { UppNotification } from '../../model/notification';
import { CORE_ACTION } from '../core/actions';

export const hotelsReducer = (state = initialHotelState, action: HotelsActions): HotelsState => {
  if (HotelReducerMethods.has(action.type)) {
    const reducerMethod = HotelReducerMethods.get(action.type);

    if (reducerMethod) {
      return reducerMethod(state, action);
    }
  }
  return state;
};

const createRule = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    create: {
      sending: true,
      value: state.hotelRule.create.value
    }
  },
  notification: {
    ...state.notification,
    create: {}
  }
});

const setCreateValue = (state = initialHotelState, action: HotelsActions): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    create: {
      ...state.hotelRule.create,
      value: action.payload.value
    }
  }
});

const finishCreation = (state = initialHotelState, action: HotelsActions): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    create: {
      sending: false,
      value: state.hotelRule.create.value,
      updatedRule: action.payload.hotelRule
    }
  },
  notification: {
    ...state.notification,
    create: action.payload.hotelRule.statusNotification
  }
});

const deleteCreateNotification = (state = initialHotelState): HotelsState => ({
  ...state,
  notification: {
    ...state.notification,
    create: {}
  }
});

const activateDisply = (state = initialHotelState, action: HotelsActions): HotelsState => {
  let displayValue = state.hotelRule.display.value;
  if (action.payload.id) {
    const ruleToDisplay = findRuleWithId(state.hotelRules, action.payload.id);
    if (ruleToDisplay) {
      displayValue = ruleToDisplay;
    }
  }
  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      display: {
        ...state.hotelRule.display,
        value: displayValue,
        active: true
      }
    }
  };
};

const deleteDisplayNotification = (state = initialHotelState): HotelsState => ({
  ...state,
  notification: {
    ...state.notification,
    display: {}
  }
});

const activateCreatedDisplay = (state = initialHotelState): HotelsState => {
  let rule = state.hotelRule.display.value;
  if (state.hotelRule.create.updatedRule) {
    rule = state.hotelRule.create.updatedRule;
  }
  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      display: {
        sending: false,
        value: rule,
        active: true
      }
    },
    notification: {
      ...state.notification,
      display: {}
    }
  };
};

const search = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    search: {
      ...state.hotelRule.search,
      sending: true
    }
  }
});

const setList = (state = initialHotelState, action: HotelsActions): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    search: {
      ...state.hotelRule.search,
      sending: false
    }
  },
  hotelRules: action.payload.hotelRules,
  notification: {
    ...state.notification,
    search: action.payload.hotelRules[0].statusNotification
  }
});

const clearList = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    search: {
      ...state.hotelRule.search,
      sending: false
    },
    display: initialHotelRuleStatus
  },
  hotelRules: []
});

const deleteSearchNotification = (state = initialHotelState): HotelsState => ({
  ...state,
  notification: {
    ...state.notification,
    search: {}
  }
});

const setSearchValue = (state = initialHotelState, action: HotelsActions): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    search: {
      sending: state.hotelRule.search.sending,
      value: action.payload.hotelSearchCriteria
    }
  }
});

const deleteRule = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    delete: {
      ...state.hotelRule.delete,
      sending: true
    }
  }
});

const deleteClearStore = (state = initialHotelState, action: HotelsActions): HotelsState => {
  const clearHotelRuleId = action.payload.rule.id;

  const currentValueOfDeletedRuleDisplay = getHotelRuleValueAfterDelete(
    clearHotelRuleId,
    state.hotelRule.display.value
  );
  const currentValueOfDeletedRuleModify = getHotelRuleValueAfterDelete(clearHotelRuleId, state.hotelRule.modify.value);
  const updatedValueOfDeletedRule = getUpdatedHotelRuleAfterDelete(
    clearHotelRuleId,
    state.hotelRule.create.updatedRule
  );

  if (!currentValueOfDeletedRuleDisplay) {
    throw new Error('currentValueOfDeletedRecordDisplay is null or undefined');
  }

  if (!currentValueOfDeletedRuleModify) {
    throw new Error('currentValueOfDeletedRecordModify is null or undefined');
  }

  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      display: {
        ...state.hotelRule.display,
        value: currentValueOfDeletedRuleDisplay,
        active: checkHotelRuleIdActivation(clearHotelRuleId, state.hotelRule.display)
      },
      create: {
        ...state.hotelRule.create,
        updatedRule: updatedValueOfDeletedRule
      },
      modify: {
        ...state.hotelRule.modify,
        value: currentValueOfDeletedRuleModify,
        active: checkHotelRuleIdActivation(clearHotelRuleId, state.hotelRule.modify)
      }
    },
    notification: {
      ...state.notification,
      create: shouldNotificationCleared(state, clearHotelRuleId) ? {} : state.notification?.create
    }
  };
};

const deleteFromList = (state = initialHotelState, action: HotelsActions): HotelsState => {
  let rules = state.hotelRules;
  let statusNotification: UppNotification = action.payload.deletedRule.statusNotification;
  if (statusNotification.success) {
    rules = deleteRuleFromList(action.payload.deletedRule.id, state.hotelRules);
    statusNotification = {};
  }

  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      delete: {
        ...state.hotelRule.delete,
        sending: false
      }
    },
    hotelRules: rules,
    notification: {
      ...state.notification,
      search: statusNotification
    }
  };
};

const setModifyValue = (state = initialHotelState, action: HotelsActions): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    modify: {
      ...state.hotelRule.modify,
      value: action.payload.value
    }
  }
});

const startModification = (state = initialHotelState, action: HotelsActions): HotelsState => {
  const modificationValue = getHotelModifyValue(state, action.payload.id);
  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      modify: {
        ...state.hotelRule.modify,
        value: modificationValue,
        active: true
      },
      display: {
        ...state.hotelRule.display,
        active: true
      }
    },
    notification: {
      ...state.notification,
      modify: {}
    }
  };
};

const update = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    modify: {
      sending: true,
      value: state.hotelRule.modify.value,
      active: state.hotelRule.modify.active
    }
  },
  notification: {
    ...state.notification,
    modify: {}
  }
});

const cancelModification = (state = initialHotelState): HotelsState => ({
  ...state,
  hotelRule: {
    ...state.hotelRule,
    modify: {
      sending: state.hotelRule.modify.sending,
      value: {
        organization: '',
        name: '',
        active: false,
        action: 'exclude',
        hotelApplicability: {}
      },
      active: false
    }
  },
  notification: {
    ...state.notification,
    modify: {},
    display: {}
  }
});

const setUpdateSuccessValues = (state = initialHotelState, action: HotelsActions): HotelsState => {
  let updatedDisplayValue = state.hotelRule.display.value;
  if (action.payload.updatedHotelRule) {
    updatedDisplayValue = action.payload.updatedHotelRule;
  }
  const updatedHotelRules = updateUpdatedHotelRules(state.hotelRules, action.payload.updatedHotelRule);
  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      display: {
        ...state.hotelRule.display,
        value: updatedDisplayValue
      },
      modify: {
        sending: state.hotelRule.modify.sending,
        value: {
          organization: '',
          name: '',
          active: false,
          action: 'exclude',
          hotelApplicability: {}
        },
        active: false
      }
    },
    hotelRules: updatedHotelRules
  };
};

const finishUpdate = (state = initialHotelState, action: HotelsActions): HotelsState => {
  const updatedHotelRule = getUpdatedHotelRule(state.hotelRule.create.updatedRule, action.payload.updatedHotelRuleUi);

  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      modify: {
        ...state.hotelRule.modify,
        sending: false
      },
      create: {
        ...state.hotelRule.create,
        updatedRule: updatedHotelRule
      }
    },
    notification: {
      ...state.notification,
      modify: {
        warning: action.payload.updatedHotelRuleUi.statusNotification.warning,
        error: action.payload.updatedHotelRuleUi.statusNotification.error
      },
      display: {
        success: action.payload.updatedHotelRuleUi.statusNotification.success
      }
    }
  };
};

const deleteModifyNotifications = (state = initialHotelState): HotelsState => ({
  ...state,
  notification: {
    ...state.notification,
    modify: {}
  }
});

const getHotelDetailValue = (state: HotelsState, hotelRuleId: string): HotelRuleUi | null => {
  const hotelDetailValue = findRuleWithId(state.hotelRules, hotelRuleId);

  if (hotelDetailValue) {
    return hotelDetailValue;
  }

  if (state.hotelRule.display.value?.id === hotelRuleId) {
    return state.hotelRule.display.value;
  }

  return null;
};

const getInitialHotelState = (state = initialHotelState): HotelsState => initialHotelState;

export const findRuleWithId = (rules: HotelRuleUi[], ruleId: string): HotelRuleUi | undefined => {
  if (!rules || rules.length === 0) {
    return undefined;
  }
  return rules.find((rule) => rule.id === ruleId);
};

const getHotelModifyValue = (state: HotelsState, hotelRuleId: string): HotelRuleUi => {
  let modificationValue = state.hotelRule.modify.value;
  if (hotelRuleId) {
    const recordToModify = findRuleWithId(state.hotelRules, hotelRuleId);
    if (recordToModify) {
      modificationValue = recordToModify;
    }
  }
  return modificationValue;
};

const getUpdatedHotelRule = (createHotelRule: HotelRuleUi | undefined, lastHotelRule: HotelRuleUi) => {
  if (createHotelRule && lastHotelRule && createHotelRule.id === lastHotelRule.id) {
    return lastHotelRule;
  } else {
    return createHotelRule;
  }
};

export const updateUpdatedHotelRules = (hotelRules: HotelRuleUi[], hotelRuleToUpdate: HotelRuleUi): HotelRuleUi[] => {
  if (!hotelRules || hotelRules.length === 0) {
    return [hotelRuleToUpdate];
  }

  return hotelRules.map((obj) => [hotelRuleToUpdate].find((record) => record.id === obj.id) || obj);
};

const getHotelRuleValueAfterDelete = (id: string, hotelRule: HotelRuleUi): HotelRuleUi | undefined => {
  if (!hotelRule) {
    return;
  }
  if (hotelRule.id === id) {
    return initialHotelRuleUi;
  }
  return hotelRule;
};

const checkHotelRuleIdActivation = (id: string, hotelRuleStatus: HotelRuleStatus): boolean => {
  if (!hotelRuleStatus) {
    return false;
  }

  if (hotelRuleStatus.active && hotelRuleStatus.value && hotelRuleStatus.value.id === id) {
    return false;
  } else {
    return hotelRuleStatus.active ?? false;
  }
};

const getUpdatedHotelRuleAfterDelete = (id: string, hotelRule?: HotelRuleUi): HotelRuleUi | undefined => {
  if (!hotelRule) {
    return;
  }
  if (hotelRule.id === id) {
    return undefined;
  }

  return hotelRule;
};

const deleteRuleFromList = (id: string, hotelRules: HotelRuleUi[]): HotelRuleUi[] => {
  if (!id || !hotelRules) {
    return [];
  }
  return hotelRules.filter((rule) => rule.id !== id);
};

const copy = (state: HotelsState, action: HotelsActions): HotelsState => {
  const hotelToCopy = getHotelDetailValue(state, action.payload.id);

  if (!hotelToCopy) {
    throw new Error('hotel rule is null or undefined.');
  }
  return {
    ...state,
    hotelRule: {
      ...state.hotelRule,
      create: {
        ...state.hotelRule.create,
        value: {
          ...hotelToCopy,
          id: undefined,
          version: undefined,
          name: '',
          organization: '',
          description: `Copy of ${hotelToCopy.name}`,
          hasRuleContent: hotelToCopy.hasRuleContent
        }
      }
    },
    notification: {
      ...state.notification
    }
  };
};

export const HotelReducerMethods: Map<string, (state: HotelsState, action: HotelsActions) => HotelsState> = new Map<
  string,
  (state: HotelsState, action: HotelsActions) => HotelsState
>([
  [HOTELS_ACTION.INIT_HOTEL_RULE_STATE, getInitialHotelState],
  [CORE_ACTION.UPDATE_SELECTED_PARTITION, getInitialHotelState],
  [HOTELS_ACTION.CREATE_HOTEL_RULE, setCreateValue],
  [HOTELS_ACTION.SET_CREATE_HOTEL_RULE_VALUE, setCreateValue],
  [HOTELS_ACTION.FINISH_HOTEL_RULE_CREATION, finishCreation],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_CREATE_NOTIFICATION, deleteCreateNotification],
  [HOTELS_ACTION.ACTIVATE_HOTEL_RULE_DISPLAY, activateDisply],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_DISPLAY_NOTIFICATION, deleteDisplayNotification],
  [HOTELS_ACTION.ACTIVATE_CREATED_HOTEL_RULE_DISPLAY, activateCreatedDisplay],
  [HOTELS_ACTION.SEARCH_HOTEL_RULE, search],
  [HOTELS_ACTION.SET_HOTEL_RULE_LIST, setList],
  [HOTELS_ACTION.CLEAR_HOTEL_RULE_LIST, clearList],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_SEARCH_NOTIFICATION, deleteSearchNotification],
  [HOTELS_ACTION.SET_HOTEL_RULE_SEARCH_VALUE, setSearchValue],
  [HOTELS_ACTION.DELETE_HOTEL_RULE, deleteRule],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_CLEAR_STORE, deleteClearStore],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_FROM_LIST, deleteFromList],
  [HOTELS_ACTION.SET_HOTEL_MODIFY_VALUE, setModifyValue],
  [HOTELS_ACTION.START_HOTEL_MODIFICATION, startModification],
  [HOTELS_ACTION.UPDATE_HOTEL, update],
  [HOTELS_ACTION.CANCEL_HOTEL_MODIFICATION, cancelModification],
  [HOTELS_ACTION.SET_HOTEL_UPDATE_SUCCESS_VALUES, setUpdateSuccessValues],
  [HOTELS_ACTION.FINISH_HOTEL_UPDATE, finishUpdate],
  [HOTELS_ACTION.DELETE_HOTEL_RULE_MODIFY_NOTIFICATION, deleteModifyNotifications],
  [HOTELS_ACTION.COPY_HOTEL_RULE, copy]
]);
