import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AirFamilyDeleteRequest,
  AirFamilyFormRequest,
  AirFamilySearchRequest
} from '../families/air/model/air-family-request';
import { AirFamilyResponse, AirFamilySearchResponse } from '../families/air/model/air-family-response';
import { ReefRESTService } from '@seco/core';
import { BaseResponse } from '../base/model/base-response';

@Injectable({
  providedIn: 'root'
})
export class AirFamiliesService {
  private readonly baseUrl = 'family/journey/carriers';
  private readonly listUrl = `${this.baseUrl}/list`;

  constructor(private readonly reefRestService: ReefRESTService) {}

  search(searchRequest: AirFamilySearchRequest): Observable<AirFamilySearchResponse> {
    return this.reefRestService.post(this.listUrl, searchRequest);
  }

  delete(deleteRequest: AirFamilyDeleteRequest): Observable<BaseResponse> {
    const familyId = deleteRequest.airFamily.id;
    const carriersFamily = {
      version: deleteRequest.version,
      carriersFamily: deleteRequest.airFamily
    };
    return this.reefRestService.delete(`${this.baseUrl}/${familyId}`, carriersFamily);
  }

  save(request: AirFamilyFormRequest): Observable<AirFamilyResponse> {
    return this.reefRestService.post(this.baseUrl, request);
  }

  modify(request: AirFamilyFormRequest): Observable<AirFamilyResponse> {
    const airFamilyId = request.carriersFamily.id;
    return this.reefRestService.put(`${this.baseUrl}/${airFamilyId}`, request);
  }
}
